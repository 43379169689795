import React, { useContext } from 'react'

// material-ui
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// project imports
import UserAssistantContext from '../../../../context/userassistant/UserAssistantContext'
import BookingsContext from '../../../../context/booking/BookingsContext'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import MainTablePagination from '../../../../ui-components/extended/table/MainTablePagination'
import BookingStatusChip from '../../../../ui-components/extended/chip/BookingStatusChip'
import BookingConfirmIconButton from '../../../CompanyLayout/Bookings/BookingConfirmIconButton/BookingConfirmIconButton'
import BookingCancelIconButton from '../../../CompanyLayout/Bookings/BookingCancelIconButton/BookingCancelIconButton'
import { UserAssistantRole } from '../../../../types/UserAssistantRole'
import { locale } from '../../../../utils/locale'
import { getDepositText, getNullablePriceString } from '../../../../utils/typography'

// ========================|| BOOKING TABLE ||======================== //

export default function BookingTable() {
  // hooks
  const { i18n, t } = useTranslation()
  const { userAssistant } = useContext(UserAssistantContext)
  const { bookings, params, setParams } = useContext(BookingsContext)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Branch')}</TableCell>
              <TableCell>{t('Service')}</TableCell>
              <TableCell>{t('Client')}</TableCell>
              <TableCell>{t('Date')}</TableCell>
              <TableCell align='right'>{t('Price')}</TableCell>
              <TableCell align='right'>{t('Deposit')}</TableCell>
              <TableCell align='center'>{t('Status')}</TableCell>
              {userAssistant.role === UserAssistantRole.EDITOR && (
                <TableCell align='center' width={180}>
                  {t('Actions')}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.content.map(booking => (
              <TableRow key={booking.id}>
                <TableCell>
                  <Typography variant='subtitle1'>{booking.branch.name}</Typography>
                  <Typography variant='caption'>{booking.branch.address.primary}</Typography>
                </TableCell>
                <TableCell>{booking.service.name}</TableCell>
                <TableCell>{booking.client.user?.name || booking.client.name}</TableCell>
                <TableCell>{dayjs(booking.date).locale(locale(i18n.language)).format('DD MMM, HH:mm')}</TableCell>
                <TableCell align='right'>
                  {t(getNullablePriceString(booking.price, booking.currency, i18n.language))}
                </TableCell>
                <TableCell align='right'>
                  {t(getDepositText(booking.price, booking.currency, booking.depositAmount, i18n.language))}
                </TableCell>
                <TableCell align='center'>
                  <BookingStatusChip status={booking.status} size='small' />
                </TableCell>
                {userAssistant.role === UserAssistantRole.EDITOR && (
                  <TableCell align='center'>
                    <BookingConfirmIconButton booking={booking} />
                    <BookingCancelIconButton booking={booking} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <MainTablePagination count={bookings.page.totalElements} pageable={params} setPageable={setParams} />
    </>
  )
}
