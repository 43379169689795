import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import AssistantUserEditDialog from '../AssistantUserEditDialog/AssistantUserEditDialog'
import { AssistantUser } from '../../../../types/AssistantUser'

// ========================|| ASSISTANT USER - EDIT BUTTON ||======================== //

interface Props {
  assistantUser: AssistantUser
}

export default function AssistantUserEditButton({ assistantUser }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={t('Edit User')}>
        <IconButton color='secondary' size='large' onClick={handleOpen}>
          <EditIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <AssistantUserEditDialog open={open} assistantUser={assistantUser} onClose={handleClose} />
    </>
  )
}
