import { ReactNode } from 'react'

// project imports
import BillingContext from './BillingContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useBilling from '../../hooks/billing/useBilling'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - BILLING ||======================== //

export default function BillingProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { loading, error, billing, setBilling } = useBilling(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error && error.status !== 404) {
    return <ApiErrorAlert error={error} />
  }

  return <BillingContext.Provider value={{ billing, setBilling }}>{children}</BillingContext.Provider>
}