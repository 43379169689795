// project imports
import { axiosInstance } from '../../axios'
import { Service, ServiceDetails, ServiceParams } from '../../types/Service'
import { ServiceCreateDto } from './dto/ServiceCreateDto'

// ========================|| API - SERVICE ||======================== //

export default class CompanyServiceApi {
  findAll(token: string, companyId: string, params: ServiceParams): Promise<ServiceDetails[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/services`, config)
  }

  create(token: string, companyId: string, service: ServiceCreateDto): Promise<Service> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/services`, service, config)
  }
}
