import { useEffect, useState } from 'react'

// project imports
import BranchUserApi from '../../api/userbranch/BranchUserApi'
import useAuth from '../../context/auth/useAuth'
import { DEFAULT_PAGE, DEFAULT_PARAMS, Page } from '../../types/Page'
import { BranchUser } from '../../types/BranchUser'
import { UserBranchParams } from '../../types/UserBranch'
import { ApiError } from '../../types/ApiError'

// apis
const branchUserApi = new BranchUserApi()

// ========================|| CONTEXT - BRANCH USER ||======================== //

const useBranchUsers = (branchId: string) => {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [branchUsers, setBranchUsers] = useState<Page<BranchUser>>(DEFAULT_PAGE)
  const [params, setParams] = useState<UserBranchParams>(DEFAULT_PARAMS)

  const searchBranchUsers = () => {
    if (branchId) {
      setLoading(true)
      auth!.getIdToken().then(token => {
        branchUserApi
          .findAll(token, branchId, params)
          .then(data => setBranchUsers(data))
          .catch(error => setError(error))
          .finally(() => {
            setFirstLoading(false)
            setLoading(false)
          })
      })
    } else {
      setFirstLoading(false)
      setLoading(false)
    }
  }

  useEffect(searchBranchUsers, [branchId])

  return { firstLoading, loading, error, branchUsers, params, setBranchUsers, setParams }
}

export default useBranchUsers
