// material-ui
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Dialog, { DialogProps } from '@mui/material/Dialog'

// ==============================|| DIALOG ||============================== //

export default function SideDialog(props: DialogProps) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      key='lateral-dialog'
      {...props}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          margin: 0,
          maxHeight: '100%',
          height: '100%',
          [theme.breakpoints.up('md')]: {
            width: '500px',
            borderRadius: 0,
          },
        },
      }}
      sx={{
        height: '100%',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      }}
    >
      {props.children}
    </Dialog>
  )
}
