// project imports
import { axiosInstance } from '../../axios'
import { BusinessHour } from '../../types/BusinessHour'
import { BusinessHoursUpdateDto } from './dto/BusinessHoursUpdateDto'

// ========================|| API - BUSINESS HOUR - ASSISTANT ||======================== //

export default class AssistantBusinessHoursApi {
  findByAssistantId(token: string, assistantId: string): Promise<BusinessHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/assistants/${assistantId}/business-hours`, config)
  }

  update(token: string, assistantId: string, businessHours: BusinessHoursUpdateDto): Promise<BusinessHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/assistants/${assistantId}/business-hours`, businessHours, config)
  }
}
