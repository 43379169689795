import React, { useContext, useState } from 'react'

// material-ui
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import BookingApi from '../../../../api/booking/BookingApi'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Booking } from '../../../../types/Booking'
import { ApiError } from '../../../../types/ApiError'

// apis
const bookingApi = new BookingApi()

// ========================|| BOOKING - CONFIRM DIALOG ||======================== //

interface Props extends DialogProps {
  booking: Booking
  onClose: any
}

export default function BookingConfirmDialog({ booking, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setBookings } = useContext(BookingsContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (confirmed: Booking) => {
    setBookings(bookings => ({
      ...bookings,
      content: bookings.content.map(booking => (booking.id === confirmed.id ? confirmed : booking))
    }))

    dispatch(
      setSnackbar({
        message: t('Booking confirmed successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.status === 403) {
      dispatch(
        setSnackbar({
          message: t('Unauthorized'),
          severity: 'error',
          open: true,
        }),
      )

      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while confirming booking'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleConfirm = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      bookingApi.confirm(token, booking.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          onClose()
          setLoading(false)
        })
    })
  }

  return (
    <Dialog
      aria-labelledby='confirm-booking-dialog'
      onClose={onClose}
      PaperProps={{ sx: { p: '12px 0px' } }}
      {...dialogProps}
    >
      <DialogTitle id='confirm-booking-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='confirm-booking-dialog-description'>
          {t('Do you want to confirm the booking?')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='contained' color='primary' disableElevation onClick={() => onClose()}>
          {t('Not now')}
        </Button>
        <LoadingButton variant='outlined' color='primary' loading={loading} disableElevation onClick={handleConfirm}>
          {t('Yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
