// project imports
import ClientsProvider from '../../../context/client/ClientsProvider'
import ClientCard from './ClientCard/ClientCard'

// ========================|| CLIENT ||======================== //

export default function Clients() {
  return (
    <ClientsProvider>
      <ClientCard />
    </ClientsProvider>
  )
}
