import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import ServiceApi from '../../api/service/CompanyServiceApi'
import { ServiceDetails, ServiceParams } from '../../types/Service'
import { ApiError } from '../../types/ApiError'

// apis
const serviceApi = new ServiceApi()

// ========================|| HOOK - SERVICES ||======================== //

export default function useServices(companyId: string, serviceParams: ServiceParams = {}) {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [params, setParams] = useState<ServiceParams>(serviceParams)
  const [services, setServices] = useState<ServiceDetails[]>([])

  useEffect(() => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      serviceApi
        .findAll(token, companyId, params)
        .then(data => setServices(data))
        .catch(error => setError(error))
        .finally(() => {
          setFirstLoading(false)
          setLoading(false)
        })
    })
  }, [companyId, params])

  return { firstLoading, loading, error, services, params, setServices, setParams }
}
