import { useContext } from 'react'

// material-ui
import { Grid } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import SubscriptionFreeTrialAlert from '../SubscriptionFreeTrialAlert/SubscriptionFreeTrialAlert'
import SubscriptionStatusChip from '../SubscriptionStatusChip/SubscriptionStatusChip'
import SubscriptionCreateForm from '../SubscriptionCreateForm/SubscriptionCreateForm'

// ========================|| SUBSCRIPTION - FREE TRIAL CARD ||======================== //

export default function SubscriptionFreeTrialCard() {
  const { t } = useTranslation()
  const { subscription } = useContext(SubscriptionContext)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SubscriptionFreeTrialAlert freeTrialEndDate={subscription.freeTrialEndDate!} />
      </Grid>
      <Grid item xs={12}>
        <SubCard
          title={t('Subscription')}
          secondary={<SubscriptionStatusChip status={subscription.status} />}
          headerSX={{ '& .MuiCardHeader-action': { mr: 0 } }}
        >
          <SubscriptionCreateForm />
        </SubCard>
      </Grid>
    </Grid>
  )
}
