import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserStatsApi from '../../api/userstats/UserStatsApi'
import { ApiError } from '../../types/ApiError'
import { UserStats } from '../../types/UserStats'

// apis
const statsApi = new UserStatsApi()

// ========================|| HOOK - USER STATS ||======================== //

const useUserStats = () => {
  const { auth } = useAuth()
  const [stats, setStats] = useState<UserStats>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchUserStats = () => {
    auth!.getIdToken().then(token => {
      statsApi.findById(token)
        .then(data => setStats(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchUserStats, [auth])

  return { loading, error, stats, setStats }
}

export default useUserStats
