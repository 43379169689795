// material-ui
import { ButtonProps, IconButton, Tooltip } from '@mui/material'
import CopyAllTwoToneIcon from '@mui/icons-material/CopyAllTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// ========================|| BUSINESS HOURS - COPY BUTTON ||======================== //

export default function BusinessHourCopyButton(props: ButtonProps) {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('Copy time to all')} placement='top' enterDelay={400} enterNextDelay={400}>
      <span>
        <IconButton {...props} aria-label='delete' color='secondary' size='medium'>
          <CopyAllTwoToneIcon fontSize='small' />
        </IconButton>
      </span>
    </Tooltip>
  )
}
