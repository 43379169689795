// material-ui
import { Autocomplete, Box, FormControl, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import useServiceAssistants from '../../../../../hooks/serviceassistant/useServiceAssistants'
import { resolveCurrency, resolvePrice } from '../../../../../utils/serviceAssistant'
import { ServiceAssistant } from '../../../../../types/ServiceAssistant'
import { Service } from '../../../../../types/Service'
import { getNullablePriceString } from '../../../../../utils/typography'

// ========================|| BOOKING FORM - SELECT ASSISTANT ||======================== //

export default function SelectAssistantStep() {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { i18n, t } = useTranslation()
  const { control, watch, resetField } = useFormContext()
  const service: Service = watch('service')
  const serviceId = watch('service.id')
  const branchId = watch('branch.id')

  const getPrice = (serviceAssistant: ServiceAssistant) => {
    const price = resolvePrice(service, serviceAssistant)
    const currency = resolveCurrency(service, serviceAssistant)
    return getNullablePriceString(price, currency, i18n.language)
  }

  // state
  const { loading: loadingAssistants, serviceAssistants } = useServiceAssistants(serviceId, { branchId })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Controller
          name='assistant'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <Autocomplete
                options={serviceAssistants}
                value={value || null}
                loading={loadingAssistants}
                loadingText={t('Loading...')}
                onChange={(e, assistant) => {
                  resetField('date')
                  resetField('time')
                  onChange(assistant)
                }}
                disableClearable
                isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                getOptionLabel={assistant => assistant.name}
                renderOption={(props, serviceAssistant: ServiceAssistant) => (
                  <Box component='li' {...props}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                      <Box>
                        <Typography>{serviceAssistant.name}</Typography>
                      </Box>
                      <Box>
                        <Typography variant='caption'>
                          {t(getPrice(serviceAssistant))}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                renderInput={params =>
                  matchesXS ? (
                    <TextField {...params} placeholder={t('Assistant')} error={!!error} helperText={error ? t(error.message as string) : null} />
                  ) : (
                    <TextField {...params} label={t('Assistant')} error={!!error} helperText={error ? t(error.message as string) : null} />
                  )
                }
              />
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  )
}
