import { useContext } from 'react'

// material-ui
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// third-party
import { useTranslation } from 'react-i18next'

// project-imports
import BookingsContext from '../../../../../context/booking/BookingsContext'
import useCompanyId from '../../../../../hooks/company/useCompanyId'
import useAssistants from '../../../../../hooks/assistant/useAssistants'
import { Assistant } from '../../../../../types/Assistant'

// ========================|| ASSISTANT FILTER ||======================== //

export default function BookingAssistantFilter() {
  // hooks
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { params, setParams } = useContext(BookingsContext)
  const { loading, assistants } = useAssistants(companyId)

  // state
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />

  const handleChange = (assistants: Assistant[]) => {
    setParams(filter => ({ ...filter, assistants }))
  }

  return (
    <Autocomplete
      id='assistants'
      multiple
      limitTags={5}
      value={params.assistants}
      fullWidth
      options={assistants}
      loading={loading}
      disableCloseOnSelect
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
      renderOption={(props, asssitant, { selected }) => (
        <li {...props}>
          <Checkbox id={asssitant.name} icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {asssitant.name}
        </li>
      )}
      onChange={(e, assistant) => handleChange(assistant)}
      renderInput={params => <TextField {...params} label={t('Assistants')} />}
    />
  )
}
