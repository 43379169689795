// material-ui
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// ========================|| MAINTENANCE - NOT FOUND ||======================== //

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
      <Card variant='outlined' sx={{ border: 'none' }}>
        <CardContent sx={{ p: 3 }}>
          <Box maxWidth='350px' mx='auto' textAlign='center'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h1'>{t('Not found')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component='p' variant='body2'>
                  {t('The page you are looking for does not exist or you do not have permission to view it.')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant='contained' href='/' disableElevation>
                  {t('Go Home')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
