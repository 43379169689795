import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserBranchContext from '../../context/userbranch/UserBranchContext'

// ========================|| HOOK - USER BRANCH BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { userBranch } = useContext(UserBranchContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const branchesUrl = { title: t('Branches'), url: '/users/me/branches' }
  const branchUrl = { title: userBranch.name }
  const bookings = { title: t('Bookings') }

  switch (pathname.split('/').at(-1)) {
    case 'bookings': {
      return [branchesUrl, branchUrl, bookings]
    }
    default: {
      return []
    }
  }
}

export default useBreadcrumbs
