import { ReactNode, useContext } from 'react'

// project imports
import PlanContext from './PlanContext'
import BillingContext from '../billing/BillingContext'
import UserCompanyContext from '../usercompany/UserCompanyContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useBilling from '../../hooks/billing/useBilling'
import usePlan from '../../hooks/plan/usePlan'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - PLAN ||======================== //

export default function PlanBillingProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { userCompany } = useContext(UserCompanyContext)
  const { loading: loadingPlan, error: errorPlan, plan } = usePlan(userCompany.country)
  const { loading: loadingBilling, error: errorBilling, billing, setBilling } = useBilling(companyId)

  const loading = loadingPlan || loadingBilling;

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (errorPlan) {
    return <ApiErrorAlert error={errorPlan} />
  }

  if (errorBilling && errorBilling.status !== 404) {
    return <ApiErrorAlert error={errorBilling} />
  }

  if (!plan) {
    return <ApiErrorAlert />
  }

  return (
    <PlanContext.Provider value={{ plan }}>
      <BillingContext.Provider value={{ billing, setBilling }}>
        {children}
      </BillingContext.Provider>
    </PlanContext.Provider>
  )
}