// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import PaymentMethodUpdateForm from '../PaymentMethodUpdateForm/PaymentMethodUpdateForm'

// ========================|| PAYMENT - CARD ||======================== //

export default function PaymentMethodCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Payment method')}>
      <PaymentMethodUpdateForm />
    </SubCard>
  )
}
