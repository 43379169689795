// material-ui
import { Autocomplete, Box, TextField } from '@mui/material'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'

// project imports
import { timeSlot } from '../../../../utils/timeSlot'

// ========================|| BUSINESS HOURS - TIME RANGE - FORM CONTROL ||======================== //

interface Props {
  businessHourIndex: number
  timeRangeIndex: number
  formControlName?: string
}

export default function TimeRangeFormControl({ formControlName = 'businessHours', businessHourIndex, timeRangeIndex }: Props) {
  // hooks
  const { control, watch, setValue, getValues, clearErrors } = useFormContext()

  // state
  const openingTime = watch(`${formControlName}.${businessHourIndex}.timeRanges.${timeRangeIndex}.openingTime`)
  const closingTime = watch(`${formControlName}.${businessHourIndex}.timeRanges.${timeRangeIndex - 1}.closingTime`)

  const add15Minutes = (time: string) => dayjs(time, 'HH:mm').add(15, 'minutes').format('HH:mm')

  const openingTimeSlots = () => {
    if (timeRangeIndex === 0) {
      return timeSlot('00:00', '00:00', 15)
    }

    const startTime = add15Minutes(closingTime)

    if (startTime === '00:00') return []

    return timeSlot(startTime, '00:00', 15)
  }

  const closingTimeSlots = () => {
    if (openingTime === '23:45') return []

    const startTime = add15Minutes(openingTime)

    return timeSlot(startTime, '00:00', 15)
  }

  const resetValues = () => {
    for (let i = timeRangeIndex + 1; i <= 2; i++) {
      if (getValues(`${formControlName}.${businessHourIndex}.timeRanges.${i}.openingTime`)) {
        setValue(`${formControlName}.${businessHourIndex}.timeRanges.${i}.openingTime`, null)
      }

      if (getValues(`${formControlName}.${businessHourIndex}.timeRanges.${i}.closingTime`)) {
        setValue(`${formControlName}.${businessHourIndex}.timeRanges.${i}.closingTime`, null)
      }
    }
  }

  return (
    <>
      <Controller
        name={`${formControlName}.${businessHourIndex}.timeRanges.${timeRangeIndex}.openingTime`}
        control={control}
        rules={{ required: 'This field is required.' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <span>
            <Autocomplete
              options={openingTimeSlots()}
              value={value}
              onChange={(e, value) => {
                clearErrors(`${formControlName}.${businessHourIndex}.timeRanges.${timeRangeIndex}.openingTime`)
                setValue(`${formControlName}.${businessHourIndex}.timeRanges.${timeRangeIndex}.closingTime`, null)
                resetValues()
                return onChange(value)
              }}
              size='small'
              selectOnFocus
              disableClearable
              renderInput={params => <TextField {...params} error={!!error} sx={{ minWidth: '100px' }} />}
            />
          </span>
        )}
      />
      <Box
        component='span'
        sx={{ m: 0, width: '45px', lineHeight: '37px', textAlign: 'center' }}
      >
        {' – '}
      </Box>
      <Controller
        name={`${formControlName}.${businessHourIndex}.timeRanges.${timeRangeIndex}.closingTime`}
        control={control}
        rules={{ required: 'This field is required.' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <span>
            <Autocomplete
              options={closingTimeSlots()}
              value={value}
              onChange={(e, value) => {
                clearErrors(`${formControlName}.${businessHourIndex}.timeRanges.${timeRangeIndex}.openingTime`)
                resetValues()
                return onChange(value)
              }}
              size='small'
              selectOnFocus
              disableClearable
              renderInput={params => <TextField {...params} error={!!error} sx={{ minWidth: '100px' }} />}
            />
          </span>
        )}
      />
    </>
  )
}
