import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserAssistantApi from '../../api/userassistant/UserAssistantApi'
import { UserAssistant, UserAssistantParams } from '../../types/UserAssistant'
import { Page, DEFAULT_PAGE, DEFAULT_PARAMS } from '../../types/Page'
import { ApiError } from '../../types/ApiError'

// apis
const userAssistantApi = new UserAssistantApi()

  // ========================|| HOOK - USER ASSISTANTS ||======================== //

export default function useUserAssistants() {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [userAssistants, setUserAssistants] = useState<Page<UserAssistant>>(DEFAULT_PAGE)
  const [params, setParams] = useState<UserAssistantParams>(DEFAULT_PARAMS)

  const searchUserAssistants = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      userAssistantApi
        .findAll(token, params)
        .then(data => setUserAssistants(data))
        .catch(error => setError(error))
        .finally(() => {
          setLoading(false)
          setFirstLoading(false)
        })
    })
  }

  useEffect(searchUserAssistants, [params])

  return { firstLoading, loading, error, userAssistants, params, setUserAssistants, setParams }
}