// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import BillingHistoryTable from '../BillingHistoryTable/BillingHistoryTable'

// ========================|| BILLING - CARD ||======================== //

export default function BillingHistoryCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Billing history')} content={false}>
      <BillingHistoryTable />
    </SubCard>
  )
}
