// material-ui
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import { Branch } from '../../../../types/Branch'

// ========================|| BRANCH - EDIT BUTTON ||======================== //

interface Props {
  branch: Branch
}

export default function BranchEditIconButton({ branch }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${branch.id}`)
  }

  return (
    <Tooltip title={t('Edit Branch')}>
      <IconButton aria-label='Edit branch' color='secondary' size='large' onClick={handleClick}>
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
