// ========================|| UTILS - ANTICIPATION ||======================== //

export interface Time {
  id: number
  label: string
}

export const findMinTime = (minutes: number): Time => {
  return minTimes.find(time => time.id === minutes) || defaultTime
}

export const findMaxTime = (minutes: number): Time => {
  return maxTimes.find(time => time.id === minutes) || defaultTime
}

export const getMinTimeOptions = (to: number): Time[] => {
  return minTimes.filter(time => time.id < to)
}

export const getMaxTimeOptions = (from: number): Time[] => {
  return maxTimes.filter(time => time.id > from)
}

export const findRefundAnticipationTime = (id: number): Time | undefined => {
  return refundAnticipationTimes.find(time => time.id === id)
}

const defaultTime = {
  id: 0,
  label: '0 minutes'
}

export const minTimes: Time[] = [
  {
    id: 15,
    label: '15 minutes',
  },
  {
    id: 30,
    label: '30 minutes',
  },
  {
    id: 60,
    label: '1 hour',
  },
  {
    id: 120,
    label: '2 hours',
  },
  {
    id: 180,
    label: '3 hours',
  },
  {
    id: 240,
    label: '4 hours',
  },
  {
    id: 480,
    label: '8 hours',
  },
  {
    id: 1440,
    label: '1 day',
  },
  {
    id: 2880,
    label: '2 days',
  },
  {
    id: 4320,
    label: '3 days',
  },
  {
    id: 5760,
    label: '4 days',
  },
  {
    id: 7200,
    label: '5 days',
  },
  {
    id: 10080,
    label: '1 week',
  },
  {
    id: 20160,
    label: '2 weeks',
  },
  {
    id: 43800,
    label: '1 month',
  },
]

export const maxTimes: Time[] = [
  {
    id: 1440,
    label: '1 day',
  },
  {
    id: 2880,
    label: '2 days',
  },
  {
    id: 4320,
    label: '3 days',
  },
  {
    id: 5760,
    label: '4 days',
  },
  {
    id: 7200,
    label: '5 days',
  },
  {
    id: 10080,
    label: '1 week',
  },
  {
    id: 20160,
    label: '2 weeks',
  },
  {
    id: 30240,
    label: '3 weeks',
  },
  {
    id: 43800,
    label: '1 month',
  },
  {
    id: 87600,
    label: '2 months',
  },
  {
    id: 131400,
    label: '3 months',
  },
]

export const refundAnticipationTimes: Time[] = [
  {
    id: 60,
    label: '1 hour',
  },
  {
    id: 120,
    label: '2 hours',
  },
  {
    id: 180,
    label: '3 hours',
  },
  {
    id: 240,
    label: '4 hours',
  },
  {
    id: 480,
    label: '8 hours',
  },
  {
    id: 720,
    label: '12 hours',
  },
  {
    id: 1440,
    label: '1 day',
  },
  {
    id: 2880,
    label: '2 days',
  },
  {
    id: 4320,
    label: '3 days',
  },
  {
    id: 7200,
    label: '5 days',
  },
  {
    id: 10080,
    label: '1 week',
  },
]