// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import BusinessHoursAdjustedForm from '../BusinessHoursAdjustedForm/BusinessHoursAdjustedForm'

// ========================|| COMPANY TABS - BUSINESS HOURS ADJUSTED - CARD ||======================== //

export default function BusinessHoursAdjustedCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Business hours adjusted')} contentSX={{ overflowX: 'scroll' }}>
      <BusinessHoursAdjustedForm />
    </SubCard>
  )
}