// material-ui
import { Grid } from '@mui/material'

// project imports
import BusinessHoursCard from './BusinessHoursCard/BusinessHoursCard'
import BusinessHoursAdjustedCard from './BusinessHoursAdjustedCard/BusinessHoursAdjustedCard'
import CompanyBusinessHoursProvider from '../../../context/businesshour/CompanyBusinessHoursProvider'

// ========================|| BUSINESS HOURS ||======================== //

export default function BusinessHours() {
  return (
    <CompanyBusinessHoursProvider>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BusinessHoursCard />
        </Grid>
        <Grid item xs={12}>
          <BusinessHoursAdjustedCard />
        </Grid>
      </Grid>
    </CompanyBusinessHoursProvider>
  )
}
