import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanyBranchApi from '../../api/branch/CompanyBranchApi'
import { Branch, BranchParams } from '../../types/Branch'
import { ApiError } from '../../types/ApiError'

// apis
const branchApi = new CompanyBranchApi()

// ========================|| HOOK - COMPANY BRANCHES ||======================== //

export default function useBranches(companyId: string) {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [branches, setBranches] = useState<Branch[]>([])
  const [params, setParams] = useState<BranchParams>({})

  useEffect(() => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      branchApi
        .findAll(token, companyId, params)
        .then(data => setBranches(data))
        .catch(error => setError(error))
        .finally(() => {
          setFirstLoading(false)
          setLoading(false)
        })
    })
  }, [companyId, params])

  return { firstLoading, loading, error, branches, setBranches, params, setParams }
}
