import { useState } from 'react'

// material-ui
import { IconButton, Tooltip } from '@mui/material'
import DownloadForOfflineTwoToneIcon from '@mui/icons-material/DownloadForOfflineTwoTone'

// third-party
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { InvoiceStatus, Payment } from '../../../../types/Payment'

// project imports
import PaymentApi from '../../../../api/payment/PaymentApi'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'

// ========================|| BILLING - INVOICE DOWNLOAD BUTTON ||======================== //

const paymentApi = new PaymentApi()

interface Props {
  payment: Payment
}

export default function InvoiceDownloadButton({ payment }: Props) {
  // hooks
  const { auth } = useAuth()
  const { t } = useTranslation()
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      paymentApi.findById(token, companyId, payment.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  const handleSuccess = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `invoice_${payment.id}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while downloading the invoice') as string,
        severity: 'error',
        open: true,
      }),
    )
  }

  const isDisabled = payment.invoiceStatus !== InvoiceStatus.UPLOADED || loading

  return (
    <Tooltip title={t('Download invoice')}>
      <span>
        <IconButton aria-label='Edit branch' color='primary' size='large' onClick={handleClick} disabled={isDisabled}>
          <DownloadForOfflineTwoToneIcon fontSize='small' />
        </IconButton>
      </span>
    </Tooltip>
  )
}
