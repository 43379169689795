import { useContext } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Button, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import BookingFilterForm from '../BookingFilterForm/BookingFilterForm'

// ========================|| BOOKING - FILTER DIALOG ||======================== //

interface Props extends DialogProps {
  onClose: () => void
}

export default function BookingFilterDialog({ onClose, ...props }: Props) {
  const { t } = useTranslation()
  const { setParams } = useContext(BookingsContext)
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  const handleClearFilters = () => {
    setParams(filter => ({ ...filter, status: [], assistants: [] }))
    onClose()
  }

  return (
    <SideDialog {...props} onClose={onClose}>
      <DialogTitle sx={{ p: '24px' }}>{t('Filter Bookings')}</DialogTitle>
      <DialogContent>
        <BookingFilterForm />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        {matchesXS && (
          <Button variant='outlined' size='large' fullWidth onClick={onClose}>
            {t('Apply')}
          </Button>
        )}
        <Button variant='contained' size='large' color='error' fullWidth disableElevation onClick={handleClearFilters}>
          {t('Clear all')}
        </Button>
      </DialogActions>
    </SideDialog>
  )
}
