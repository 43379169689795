import React, { useContext } from 'react'

// material-ui
import { TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import AssistantsContext from '../../../../context/assistant/AssistantsContext'
import usePagination from '../../../../hooks/pagination/usePagination'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import AssistantEditIconButton from '../AssistantEditIconButton/AssistantEditIconButton'
import AssistantDeleteIconButton from '../AssistantDeleteIconButton/AssistantDeleteIconButton'

// ========================|| ASSISTANT - TABLE ||======================== //

export default function AssistantTable() {
  // hooks
  const { t } = useTranslation()
  const { assistants } = useContext(AssistantsContext)
  const { rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(assistants)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Name')}</TableCell>
              <TableCell>{t('Branch')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(assistant => (
              <TableRow key={assistant.id}>
                <TableCell>{assistant.name}</TableCell>
                <TableCell>{assistant.branch.name}</TableCell>
                <TableCell align='center'>
                  <AssistantEditIconButton assistant={assistant} />
                  <AssistantDeleteIconButton assistant={assistant} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <TablePagination
        component='div'
        count={assistants.length}
        rowsPerPageOptions={[5, 10, 20]}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        slotProps={{ select: { inputProps: { 'aria-label': 'rows per page' }, native: true } }}
        labelRowsPerPage={t('Rows per page:')}
      />
    </>
  )
}
