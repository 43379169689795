// project imports
import BookingsContext from '../../../context/booking/BookingsContext'
import useBranchId from '../../../hooks/branch/useBranchId'
import useBranchBookings from '../../../hooks/booking/useBranchBookings'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import BookingCard from './BookingCard/BookingCard'
import { Layout } from '../../../types/Layout'

// ========================|| BOOKINGS ||======================== //

export default function Bookings() {
  const branchId = useBranchId()
  const state = useBranchBookings(branchId)

  if (state.firstLoading) {
    return <Loader />
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <BookingsContext.Provider value={{ ...state, layout: Layout.BRANCH }}>
      <BookingCard />
    </BookingsContext.Provider>
  )
}
