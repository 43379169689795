import { useContext, useEffect } from 'react'

// material-ui
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import ToggleOnTwoToneIcon from '@mui/icons-material/ToggleOnTwoTone'
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone'
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone'
import ExtensionTwoToneIcon from '@mui/icons-material/ExtensionTwoTone'

// third-party
import { useDispatch } from 'react-redux'

// project imports
import UserCompanyContext from '../../../context/usercompany/UserCompanyContext'
import useCompanyId from '../../../hooks/company/useCompanyId'
import Tabs from '../../../ui-components/extended/tabs/Tabs'
import { Tab } from '../../../ui-components/extended/tabs/Tab'
import { UserCompanyRole } from '../../../types/UserCompanyRole'
import { setIsOpen } from '../../../store/menu/reducer'

// ========================|| COMPANY - TABS ||======================== //

export default function CompanyTabs() {
  // hooks
  const companyId = useCompanyId()
  const dispatch = useDispatch()
  const { userCompany } = useContext(UserCompanyContext)

  const URL_COMPANY = `/companies/${companyId}`
  const URL_BUSINESS_HOURS = `/companies/${companyId}/business-hours`
  const URL_SETTINGS = `/companies/${companyId}/settings`
  const URL_SUBSCRIPTION = `/companies/${companyId}/subscription`
  const URL_BILLING = `/companies/${companyId}/billing`
  const URL_INTEGRATIONS = `/companies/${companyId}/integrations`

  useEffect(() => {
    dispatch(setIsOpen(`/companies/${companyId}`))
  }, [])

  const tabs: Tab[] = [
    {
      url: URL_COMPANY,
      title: 'Profile',
      icon: <AccountCircleTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_BUSINESS_HOURS,
      title: 'Business Hours',
      icon: <AccessTimeTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_SETTINGS,
      title: 'Settings',
      icon: <ToggleOnTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_BILLING,
      title: 'Billing',
      icon: <DescriptionTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: userCompany.role === UserCompanyRole.OWNER,
    },
    {
      url: URL_SUBSCRIPTION,
      title: 'Subscription',
      icon: <CreditCardTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: userCompany.role === UserCompanyRole.OWNER,
    },
    {
      url: URL_INTEGRATIONS,
      title: 'Integrations',
      icon: <ExtensionTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: userCompany.role === UserCompanyRole.OWNER,
    },
  ]

  return <Tabs tabs={tabs} />
}
