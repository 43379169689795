import * as React from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

// third-party
import { IconPhone, IconCreditCard, IconLogin, IconRocket } from '@tabler/icons-react'

export default function MobileDrawer() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open)
  }

  const list = (
    <Box sx={{ width: 'auto' }} role='presentation' onClick={toggleDrawer(false)}>
      <List>
        <ListItem disablePadding>
          <ListItemButton href='/#features' sx={{ p: '10px 16px', mb: 0 }}>
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <IconRocket color={theme.palette.grey[700]} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body1' color='text.dark'>
                Características
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href='/#pricing' sx={{ p: '10px 16px', mb: 0 }}>
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <IconCreditCard color={theme.palette.grey[700]} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body1' color='text.dark'>
                Precio
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href='/#contact' sx={{ p: '10px 16px', mb: 0 }}>
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <IconPhone color={theme.palette.grey[700]} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body1' color='text.dark'>
                Contacto
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href='/signin' sx={{ p: '10px 16px', mb: 0 }}>
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <IconLogin color={theme.palette.grey[700]} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body1' color='text.dark'>
                Ingresar
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <>
      <IconButton size='large' onClick={toggleDrawer(true)} disableFocusRipple disableRipple sx={{ color: theme.palette.grey[900] }}>
        <MenuIcon focusable={false} aria-hidden='true' />
      </IconButton>
      <Drawer anchor='top' open={open} onClose={toggleDrawer(false)}>
        {list}
      </Drawer>
    </>
  )
}
