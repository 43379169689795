import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Branch } from '../../types/Branch'

// ========================|| CONTEXT - BRANCH ||======================== //

interface BranchContextType {
  branch: Branch
  setBranch: Dispatch<SetStateAction<Branch | undefined>>
}

const BranchContext = createContext<BranchContextType>({} as BranchContextType)

export default BranchContext