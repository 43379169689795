import { Client } from '../types/Client'
import { Service } from '../types/Service'
import { ServiceAssistant } from '../types/ServiceAssistant'
import { Company } from '../types/Company'

export const resolvePrice = (service: Service, assistant: ServiceAssistant): number | null => {
  return assistant.price !== null ? assistant.price : service.price;
}

export const resolveCurrency = (service: Service, assistant: ServiceAssistant): string | null => {
  return assistant.currency || service.currency
}

export const getDepositPercentage = (service: Service, company: Company) => {
  return service.customDepositPercentage ? service.depositPercentage : company.settings.depositPercentage
}

export const isDepositRequired = (client: Client | undefined, service: Service, assistant: ServiceAssistant): boolean => {
  const price = resolvePrice(service, assistant)
  return service.depositEnabled && price !== null && price > 0 && (client ? client.depositEnabled : true)
}