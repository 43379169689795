import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { BusinessHour } from '../../types/BusinessHour'

// ========================|| CONTEXT - BUSINESS HOURS ||======================== //

interface BusinessHoursContextType {
  businessHours: BusinessHour[]
  setBusinessHours: Dispatch<SetStateAction<BusinessHour[]>>
}

const BusinessHoursContext = createContext<BusinessHoursContextType>({} as BusinessHoursContextType)

export default BusinessHoursContext