export const getNullablePriceString = (price: number | null, currency: string | null, language: string): string => {
  if (price == null || currency == null) {
    // The booking price is not defined
    return 'To be defined'
  }

  if (price === 0) {
    return 'Free'
  }

  return price.toLocaleString(language, { style: 'currency', currency })
}

export const getPriceString = (price: number, currency: string, language: string, minimumFractionDigits?: number): string => {
  return price.toLocaleString(language, { style: 'currency', currency, minimumFractionDigits })
}

export const getDepositText = (price: number | null, currency: string | null, depositAmount: number, language: string): string => {
  if (price == null || currency == null) {
    // The booking price is not defined, so it should not have depsit
    return 'Not required'
  }

  if (depositAmount === 0) {
    return 'Not required'
  }

  return depositAmount.toLocaleString(language, { style: 'currency', currency })
}