import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import ServicesContext from '../../../../context/service/ServicesContext'
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'
import ServiceApi from '../../../../api/service/CompanyServiceApi'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import ServiceForm from '../ServiceForm/ServiceForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { PriceType, Service, ServiveForm } from '../../../../types/Service'
import { ApiError } from '../../../../types/ApiError'
import { EXCEPTION_SERVICE_NAME_ALREADY_EXIST, EXCEPTION_SUBSCRIPTION_REQUIRED } from '../../../../api/exceptions/exceptions'

// apis
const serviceApi = new ServiceApi()

// ========================|| SERVICE - CREATE DIALOG ||======================== //

interface Props extends DialogProps {
  onClose: () => void
}

export default function ServiceCreateDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(CompanyStatsContext)
  const { setServices } = useContext(ServicesContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<ServiveForm>({
    defaultValues: {
      name: '',
      description: '',
      price: '',
      priceType: PriceType.FIXED,
      currency: 'ARS',
      customDepositPercentage: false,
      depositPercentage: '0',
      duration: '30',
      buffer: '0',
      frequency: '30',
      assistants: [],
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (service: Service) => {
    setServices(services => services.concat(service))
    setStats({ ...stats, services: stats.services + 1 })

    dispatch(
      setSnackbar({
        message: t('Service created successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_SERVICE_NAME_ALREADY_EXIST) {
      methods.setError('name', { message: EXCEPTION_SERVICE_NAME_ALREADY_EXIST })
      return
    }

    if (error.message === EXCEPTION_SUBSCRIPTION_REQUIRED) {
      dispatch(setSnackbar({ message: t(EXCEPTION_SUBSCRIPTION_REQUIRED), severity: 'error', open: true }))
    }

    else {
      dispatch(setSnackbar({ message: t('An unexpected error occurred while creating service'), severity: 'error', open: true }))
    }

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<ServiveForm> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      serviceApi.create(token, companyId, {
        name: form.name,
        description: form.description,
        price: form.priceType === PriceType.FIXED && form.price !== null ? parseFloat(form.price) : null,
        currency: form.priceType === PriceType.FIXED && form.currency ? form.currency : null,
        customDepositPercentage: form.priceType === PriceType.FIXED ? form.customDepositPercentage : false,
        depositPercentage: form.priceType === PriceType.FIXED ? parseInt(form.depositPercentage) / 100 : 0,
        duration: parseInt(form.duration),
        buffer: parseInt(form.buffer),
        frequency: parseInt(form.frequency),
        assistantsIds: form.assistants.map(assistant => assistant.id)
      })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Create Service')}</DialogTitle>
          <DialogContent sx={{ pt: '5px' }}>
            <ServiceForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  size='large'
                  loading={loading}
                  fullWidth
                  disableElevation
                >
                  {t('Create')}
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
