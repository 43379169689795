// material-ui
import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material'

// project imports
import useBreadcrumbs from '../../../../hooks/company/useBreadcrumbs'
import useTooltip from '../../../../hooks/company/useTooltip'
import MainCard from '../../../../ui-components/extended/card/MainCard'
import MainContent from '../../../../ui-components/extended/table/MainContent'
import Breadcrumbs from '../../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import BranchSearchBar from '../BranchSearchBar/BranchSearchBar'
import BranchCreateButton from '../BranchCreateButton/BranchCreateButton'
import BranchTable from '../BranchTable/BranchTable'
import BranchList from '../BranchList/BranchList'

// ========================|| BRANCH - CARD ||======================== //

export default function BranchCard() {
  const theme = useTheme()
  const items = useBreadcrumbs()
  const tooltip = useTooltip()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} content={false} border={false}>
      <MainContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={true}>
            <BranchSearchBar />
          </Grid>
          <Grid item textAlign='center'>
            <BranchCreateButton />
          </Grid>
        </Grid>
      </MainContent>
      <Divider />
      {matchesXS ? <BranchList /> : <BranchTable />}
    </MainCard>
  )
}
