export enum FrequencyType {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
}

export interface Plan {
  price: number
  additional: number
  taxes: number
  currency: string
  frequency: number
  frequencyType: FrequencyType
  country: string
}