import React, { useContext } from 'react'

// material-ui
import { TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import CompanyUsersContext from '../../../../context/usercompany/CompanyUsersContext'
import usePagination from '../../../../hooks/pagination/usePagination'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import CompanyUserDeleteIconButton from '../CompanyUserDeleteIconButton/CompanyUserDeleteIconButton'
import CompanyUserRoleChip from '../CompanyUserRoleChip/CompanyUserRoleChip'

// ========================|| COMPANY USER - TABLE ||======================== //

export default function CompanyUserTable() {
  // hooks
  const { t } = useTranslation()
  const { companyUsers } = useContext(CompanyUsersContext)
  const { rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(companyUsers)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('User')}</TableCell>
              <TableCell align='center'>{t('Role')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(companyUser => (
              <TableRow key={companyUser.id}>
                <TableCell>
                  <Typography variant='subtitle1'>{companyUser.name}</Typography>
                  <Typography variant='caption'>{companyUser.email}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <CompanyUserRoleChip role={companyUser.role} />
                </TableCell>
                <TableCell align='center'>
                  <CompanyUserDeleteIconButton companyUser={companyUser} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <TablePagination
        component='div'
        count={companyUsers.length}
        rowsPerPageOptions={[5, 10, 20]}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        slotProps={{ select: { inputProps: { 'aria-label': 'rows per page' }, native: true } }}
        labelRowsPerPage={t('Rows per page:')}
      />
    </>
  )
}
