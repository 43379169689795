import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { UserCompany } from '../../types/UserCompany'

// ========================|| CONTEXT - USER COMPANY ||======================== //

interface UserCompanyContextType {
  userCompany: UserCompany
  setUserCompany: Dispatch<SetStateAction<UserCompany | undefined>>
}

const UserCompanyContext = createContext<UserCompanyContextType>({} as UserCompanyContextType)

export default UserCompanyContext
