// project imports
import BillingPaymentsProvider from '../../../context/billing/BillingPaymentsProvider'
import BillingContainer from './BillingContainer/BillingContainer'

// ========================|| BILLING ||======================== //

export default function Billing() {
  return (
    <BillingPaymentsProvider>
      <BillingContainer />
    </BillingPaymentsProvider>
  )
}
