// material-ui
import { Box, Grid, Typography } from '@mui/material'

// project imports
import CompanyUserDeleteButton from '../CompanyUserDeleteButton/CompanyUserDeleteButton'
import CompanyUserRoleChip from '../CompanyUserRoleChip/CompanyUserRoleChip'
import { CompanyUser } from '../../../../types/CompanyUser'

// ========================|| COMPANY USER - LIST ITEM ||======================== //

interface Props {
  companyUser: CompanyUser
}

export default function CompanyUserListItem({ companyUser }: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Typography variant='subtitle1'>{companyUser.name}</Typography>
          <Typography variant='caption'>{companyUser.email}</Typography>
        </Box>
        <CompanyUserRoleChip role={companyUser.role} />
      </Grid>
      <Grid item xs={12}>
        <CompanyUserDeleteButton companyUser={companyUser} />
      </Grid>
    </Grid>
  )
}
