import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { User } from '../../types/User'
import { ApiError } from '../../types/ApiError'

// ========================|| CONTEXT - USER ||======================== //

interface UserContextType {
  loading: boolean
  error?: ApiError
  user: User
  setUser: Dispatch<SetStateAction<User | undefined>>
}

const UserContext = createContext<UserContextType>({} as UserContextType)

export default UserContext