import { useEffect, useState } from 'react'

// project imports
import AvailabilityApi from '../../api/availability/AvailabilityApi'
import useAuth from '../../context/auth/useAuth'
import { ApiError } from '../../types/ApiError'

// apis
const availabilityApi = new AvailabilityApi()

// ========================|| HOOK - DATES ||======================== //

const useDates = (serviceId: string, assistantId: string) => {
  const { auth } = useAuth()
  const [dates, setDates] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchDateSet = () => {
    auth!.getIdToken().then(token => {
      availabilityApi.getDateSet(token, serviceId, assistantId)
        .then(data => setDates(data.dates))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchDateSet, [assistantId])

  return { loading, error, dates }
}

export default useDates