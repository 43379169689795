// material-ui
import { Box, CssBaseline } from '@mui/material'

// third-party
import { Outlet } from 'react-router-dom'

// project imports
import CompanyLayoutProvider from '../../context/layout/CompanyLayoutProvider'
import PlacesProvider from '../../context/places/PlacesProvider'
import useDrawer from '../../hooks/useDrawer'
import CompanyAppBar from '../../layout/AppBar/CompanyAppBar'
import Sidebar from '../../layout/Sidebar/Sidebar'
import Menu from './Menu/Menu'
import Main from '../../layout/Main/Main'

// ========================|| COMPANY - LAYOUT ||======================== //

export default function CompanyLayout() {
  // hooks
  const { drawerOpened } = useDrawer()

  return (
    <CompanyLayoutProvider>
      <PlacesProvider>
        <Box display='flex'>
          <CssBaseline />

          <CompanyAppBar />

          <Sidebar>
            <Menu />
          </Sidebar>

          <Main open={drawerOpened}>
            <Outlet />
          </Main>
        </Box>
      </PlacesProvider>
    </CompanyLayoutProvider>
  )
}
