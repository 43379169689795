import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { BillingInformation } from '../../types/BillingInformation'

// ========================|| CONTEXT - BILLING ||======================== //

interface BillingContextType {
  billing: BillingInformation | undefined
  setBilling: Dispatch<SetStateAction<BillingInformation | undefined>>
}

const BillingContext = createContext<BillingContextType>({} as BillingContextType)

export default BillingContext
