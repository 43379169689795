// material-ui
import { useMediaQuery, useTheme } from '@mui/material'

// third-party
import { useDispatch, useSelector } from 'react-redux'

// project imports
import { RootState } from '../store/store'
import { setOpened } from '../store/menu/reducer'

const useDrawer = () => {
  // hooks
  const theme = useTheme()
  const drawerOpened = useSelector<RootState, boolean>(state => state.menu.opened)
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()

  const handleDrawerToggle = () => {
    dispatch(setOpened(!drawerOpened))
  }

  return { drawerOpened, handleDrawerToggle, matchUpMd, matchDownMd }
}

export default useDrawer
