// material-ui
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

// project imports
import ElevateAppBar from '../Landing/AppBar/ElevateAppBar'

// ========================|| PRIVACY POLICY ||======================== //

export default function PrivacyPolicy() {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <>
      <ElevateAppBar />
      <Container sx={{ mt: '81px' }}>
        <Box textAlign='justify' p={matchesXS ? '24px 16px' : 3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h2' mb={1}>
                Política de Privacidad
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                Esta Política de Privacidad describe cómo Empreturnos (&quot;nosotros&quot;, &quot;nuestro&quot;, &quot;nos&quot;) recopila, utiliza y
                protege la información personal que recabamos a través de nuestra plataforma de reserva de turnos en conformidad con la legislación
                argentina de protección de datos personales especialmente la ley 25.326 (“LPDP”).
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                1. Definiciones
              </Typography>
              <Typography mb={1}>
                — Datos personales: Información de cualquier tipo referida a personas físicas o de existencia ideal determinadas o determinables.
              </Typography>
              <Typography mb={1}>
                — Datos sensibles: Datos personales que revelan origen racial y étnico, opiniones políticas, convicciones religiosas, filosóficas o
                morales, afiliación sindical e información referente a la salud o a la vida sexual.
              </Typography>
              <Typography mb={1}>
                — Archivo, registro, base o banco de datos: Indistintamente, designan al conjunto organizado de datos personales que sean objeto de
                tratamiento o procesamiento, electrónico o no, cualquiera que fuere la modalidad de su formación, almacenamiento, organización o
                acceso.
              </Typography>
              <Typography mb={1}>
                — Tratamiento de datos: Operaciones y procedimientos sistemáticos, electrónicos o no, que permitan la recolección, conservación,
                ordenación, almacenamiento, modificación, relacionamiento, evaluación, bloqueo, destrucción, y en general el procesamiento de datos
                personales, así como también su cesión a terceros a través de comunicaciones, consultas, interconexiones o transferencias.
              </Typography>
              <Typography mb={1}>
                — Responsable de archivo, registro, base o banco de datos: Persona física o de existencia ideal pública o privada, que es titular de
                un archivo, registro, base o banco de datos.
              </Typography>
              <Typography mb={1}>
                — Datos informatizados: Los datos personales sometidos al tratamiento o procesamiento electrónico o automatizado.
              </Typography>
              <Typography mb={1}>
                — Titular de los datos: Toda persona física o persona de existencia ideal con domicilio legal o delegaciones o sucursales en el país,
                cuyos datos sean objeto del tratamiento al que se refiere la presente ley.
              </Typography>
              <Typography mb={1}>
                — Usuario de datos: Toda persona, pública o privada que realice a su arbitrio el tratamiento de datos, ya sea en archivos, registros o
                bancos de datos propios o a través de conexión con los mismos.
              </Typography>
              <Typography>
                — Disociación de datos: Todo tratamiento de datos personales de manera que la información obtenida no pueda asociarse a persona
                determinada o determinable.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                2. Registro de la base de datos
              </Typography>
              <Typography>
                Informamos que la base de datos se encuentra registrada ante la autoridad de aplicación según lo establecido por los artículos 2 y 21
                de la LPDP.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                3. Información que Recopilamos
              </Typography>
              <Typography mb={1}>
                • Información de Registro: Cuando se crea una cuenta en nuestra plataforma, recopilamos información como su nombre, dirección de
                correo electrónico , número de celular, país de origen.
              </Typography>
              <Typography mb={1}>
                • Información de Reserva de Turnos: Cuando realiza una reserva a través de nuestra plataforma, recopilamos información como su nombre,
                dirección de correo electrónico, número de teléfono y detalles de la reserva.
              </Typography>
              <Typography>
                • Información de Uso: Recopilamos información sobre cómo interactúa con nuestra plataforma, incluidas sus interacciones con el sitio
                web y las características que utiliza.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                4. Uso de la Información
              </Typography>
              <Typography mb={1}>Utilizamos la información recopilada para:</Typography>
              <Typography mb={1}>• Facilitar la reserva de turnos a través de nuestra plataforma.</Typography>
              <Typography mb={1}>• Proporcionar servicios de atención al cliente y soporte técnico.</Typography>
              <Typography mb={1}>• Mejorar y optimizar nuestra plataforma y servicios.</Typography>
              <Typography>• Cumplir con nuestras obligaciones legales y reglamentarias.</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                5. Compartir Información
              </Typography>
              <Typography mb={1}>Podemos compartir su información personal con:</Typography>
              <Typography mb={1}>
                • Empresas asociadas al servicio y proveedores de servicios que nos ayudan a proporcionar nuestros servicios.
              </Typography>
              <Typography>• Autoridades gubernamentales y aplicadores de la ley cuando sea necesario para cumplir con la ley aplicable.</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                6. Cookies y Tecnologías Similares
              </Typography>
              <Typography mb={1}>
                Empreturnos utiliza ciertas tecnologías de monitoreo y seguimiento web, denominadas colectivamente como “Cookies”. Estas tecnologías
                se utilizan para proporcionar, mantener y mejorar nuestros sitios web y plataforma (los “Servicios”), optimizar nuestras ofertas y
                actividades de marketing, y proporcionar a nuestros visitantes y usuarios una mejor experiencia (por ejemplo, para rastrear sus
                preferencias, mejorar la seguridad de nuestros Servicios, identificar problemas técnicos y monitorear y mejorar el rendimiento general
                de nuestros Servicios).
              </Typography>
              <Typography mb={1}>
                Las cookies son archivos de información en formato texto que se instalan en el navegador del usuario al visitar este sitio web o
                interactuar con él. Sirven para obtener información respecto al tráfico web y facilitar las futuras visitas a nuestra web. Con el uso
                de las cookies podremos reconocerlo individualmente y por tanto brindarle un mejor servicio a través de nuestro sitio.
              </Typography>
              <Typography mb={1}>
                Las cookies no dan acceso a información de su ordenador ni de usted, a menos que usted así lo quiera y la proporcione directamente.
                Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente. Usted puede
                cambiar la configuración de su ordenador para deshabilitar las cookies. Sin embargo, deshabilitar las Cookies puede impedir que la
                plataforma le proporcione algunos beneficios como una experiencia de navegación personalizada; y son necesarias para que la mayoría de
                nuestros Servicios funcionen.
              </Typography>
              <Typography mb={1}>
                Con las Cookies recopilamos y usamos información definida bajo la ley de Protección de Datos Personales 25.325 como Datos Personales.
                Esto incluye datos que pueden identificar indirectamente a una persona.
              </Typography>
              <Typography>
                Si quiere más información respecto al funcionamiento de las cookies puede encontrar información útil en el siguiente link:{' '}
                <a href='https://allaboutcookies.org/es'>https://allaboutcookies.org/es</a>.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                7. Seguridad de la Información
              </Typography>
              <Typography>
                Implementamos medidas de seguridad técnicas, administrativas y físicas para proteger la información personal contra el acceso no
                autorizado, la divulgación, la alteración o la destrucción.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                8. Tus Derechos de Privacidad
              </Typography>
              <Typography mb={1}>
                Tienes derecho a acceder, corregir, actualizar o eliminar tu información personal. También puedes optar por no recibir comunicaciones
                de marketing de nuestra parte en cualquier momento.
              </Typography>
              <Typography mb={1}>
                Procederemos a la rectificación, supresión o actualización de los datos personales del usuario, realizando las operaciones necesarias
                a tal fin en el plazo máximo de cinco días hábiles de recibido el reclamo del titular de los datos o advertido el error o falsedad
                (art. 16 LPDP).
              </Typography>
              <Typography mb={1}>
                La supresión no procederá cuando pudiese causar perjuicios a derechos o intereses legítimos de terceros, o cuando existiera una
                obligación legal de conservar los datos.
              </Typography>
              <Typography mb={1}>
                La información sobre datos personales puede ser denegada, cuando de tal modo se pudieran obstaculizar actuaciones judiciales o
                administrativas en curso vinculadas a la investigación sobre el cumplimiento de obligaciones tributarias o previsionales, el
                desarrollo de funciones de control de la salud y del medio ambiente, la investigación de delitos penales y la verificación de
                infracciones administrativas. La resolución que así lo disponga debe ser fundada y notificada al afectado.
              </Typography>
              <Typography>
                Todo proceso de acceso, rectificación o supresión de datos personales se hará de manera gratuita para el usuario.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                9. Cambios en la Política de Privacidad
              </Typography>
              <Typography>
                Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento para cumplimentar con la normativa vigente.
                Te notificaremos sobre cambios significativos en esta Política de Privacidad mediante la publicación de una versión actualizada en
                nuestra plataforma.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                10. Contacto
              </Typography>
              <Typography>
                Si tienes alguna pregunta o inquietud sobre nuestra Política de Privacidad, contáctanos a través de contacto@empreturnos.com.
              </Typography>
              <Typography>
                Al utilizar nuestra plataforma, aceptas nuestra Política de Privacidad y autorizas la recopilación, uso y divulgación de tu
                información personal de acuerdo con esta política.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
