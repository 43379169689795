import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
export interface MenuState {
  isOpen: string
  opened: boolean
}

// Define the initial state using that type
export const initialState: MenuState = {
  isOpen: '/users/me/bookings', // for active default menu
  opened: true,
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setIsOpen: (state: MenuState, action: PayloadAction<string>) => {
      return {
        ...state,
        isOpen: action.payload,
      }
    },
    setOpened: (state: MenuState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        opened: action.payload,
      }
    },
  },
})

export const { setIsOpen, setOpened } = menuSlice.actions

export default menuSlice.reducer
