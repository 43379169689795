import React, { useContext, useState } from 'react'

// material-ui
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import BranchesContext from '../../../../context/branch/BranchesContext'
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'
import BranchApi from '../../../../api/branch/BranchApi'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Branch } from '../../../../types/Branch'

// apis
const branchApi = new BranchApi()

// ========================|| BRANCH - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  branch: Branch
  onClose: () => void
}

export default function BranchDeleteDialog({ branch, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(CompanyStatsContext)
  const { setBranches } = useContext(BranchesContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeBranch = (branchId: string) => {
    setBranches(branches => branches.filter(branch => branch.id !== branchId))
    setStats({ ...stats, branches: stats.branches - 1 })
  }

  const handleSuccess = () => {
    removeBranch(branch.id)

    dispatch(
      setSnackbar({
        message: t('Branch deleted successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while deleting branch'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      branchApi.delete(token, branch.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-branch-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-branch-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to delete the branch') + ' '}
          <strong>{branch.name}</strong>?
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox id='assistants' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('All associated assistants will be deleted')}
            sx={{ cursor: 'text' }}
          />
          <FormControlLabel
            control={<Checkbox id='users' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('All associated users will be deleted')}
            sx={{ cursor: 'text' }}
          />
          <FormControlLabel
            control={<Checkbox id='bookings' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('All pending and confirmed bookings will be canceled')}
            sx={{ cursor: 'text' }}
          />
          <FormHelperText error>{t('This action cannot be undone.')}</FormHelperText>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
