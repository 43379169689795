import React, { Dispatch, SetStateAction } from 'react'

// material-ui
import { Pagination } from '@mui/material'

// project imports
import { Pageable } from '../../../types/Page'

// ========================|| UI COMPONENTS - LIST - PAGINATION ||======================== //

interface Props<T extends Pageable> {
  count: number
  pageable: T
  setPageable: Dispatch<SetStateAction<T>>
}

export default function ListPagination<T extends Pageable>({ count, pageable, setPageable }: Props<T>) {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageable({ ...pageable, page: value - 1 })
  }

  return <Pagination count={count} page={pageable.page + 1} onChange={handleChange} />
}
