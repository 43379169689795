// project imports
import { axiosInstance } from '../../axios'
import { Page } from '../../types/Page'
import { Client, ClientParams } from '../../types/Client'
import { ClientCreateDto } from './dto/ClientCreateDto'

// ========================|| API - COMPANY CLIENT ||======================== //

export default class CompanyClientApi {
  findAll(token: string, companyId: string, params: ClientParams): Promise<Page<Client>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        search: params.search,
        page: params.page,
        size: params.size,
        sort: params.sort,
      },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/clients`, config)
  }

  findMe(token: string, companyId: string): Promise<Client> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/clients/me`, config)
  }

  create(token: string, companyId: string, client: ClientCreateDto) : Promise<Client> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/clients`, client, config)
  }
}
