import { ReactElement, cloneElement } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { AppBar, Box, Button, Container, Link, Toolbar, useScrollTrigger } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import LogoSection from '../../../layout/LogoSection/LogoSection'
import MobileDrawer from './Drawer/MobileDrawer'

// ========================|| PUBLIC APPBAR ||======================== //

interface Props {
  children: ReactElement
}

function ElevationScroll(props: Props) {
  const theme = useTheme()
  const { children } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
    sx: {
      backgroundColor: theme.palette.background.default,
      borderBottom: trigger ? 'none' : `1px solid ${theme.palette.grey[200]}`,
      boxShadow: trigger
        ? 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px'
        : 'none',
    },
  })
}

export default function ElevateAppBar() {
  // hooks
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <>
      <ElevationScroll>
        <AppBar>
          <Container>
            <Toolbar>
              <Box flexGrow={1}>
                <LogoSection />
              </Box>
              <Box>
                <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' } }}>
                  <Link
                    href={'/#features'}
                    variant='subtitle1'
                    sx={{
                      p: '6px 8px',
                      color: theme.palette.grey[900],
                      textDecoration: 'none',
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('Características')}
                  </Link>
                  <Link
                    href={'/#pricing'}
                    variant='subtitle1'
                    sx={{
                      p: '6px 8px',
                      ml: '16px',
                      color: theme.palette.grey[900],
                      textDecoration: 'none',
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('Precio')}
                  </Link>
                  <Link
                    href={'/#contact'}
                    variant='subtitle1'
                    sx={{
                      p: '6px 8px',
                      ml: '16px',
                      color: theme.palette.grey[900],
                      textDecoration: 'none',
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('Contacto')}
                  </Link>
                  <Button variant='contained' color='secondary' href='/signin' disableElevation sx={{ ml: '16px' }}>
                    {t('Ingresar')}
                  </Button>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}>
                  <MobileDrawer />
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </>
  )
}
