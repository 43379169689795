import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanyBusinessHoursApi from '../../api/businesshour/CompanyBusinessHoursApi'
import { ApiError } from '../../types/ApiError'
import { BusinessHour } from '../../types/BusinessHour'

// apis
const businessHoursApi = new CompanyBusinessHoursApi()

// ========================|| HOOK - BUSINESS HOURS - COMPANY ||======================== //

export default function useCompanyBusinessHours(companyId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [businessHours, setBusinessHours] = useState<BusinessHour[]>([])

  useEffect(() => {
    auth!.getIdToken().then(token => {
      businessHoursApi.findByCompanyId(token, companyId)
        .then(data => setBusinessHours(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }, [companyId])

  return { loading, error, businessHours, setBusinessHours }
}
