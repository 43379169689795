import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// project imports
import CompanyClientApi from '../../../../api/client/CompanyClientApi'
import ClientsContext from '../../../../context/client/ClientsContext'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import ClientCreateForm from '../ClientCreateForm/ClientCreateForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Client, ClientCreate } from '../../../../types/Client'
import { ApiError } from '../../../../types/ApiError'
import { CLIENT_ALREADY_EXIST } from '../../../../api/exceptions/exceptions'

// ========================|| CLIENT - CREATE DIALOG ||======================== //

const clientApi = new CompanyClientApi()

interface Props extends DialogProps {
  onClose: () => void
}

export default function ClientCreateDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setClients } = useContext(ClientsContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<ClientCreate>({
    defaultValues: {
      name: '',
      user: null,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (client: Client) => {
    setClients(clients => ({ ...clients, content: clients.content.concat(client) }))

    dispatch(
      setSnackbar({
        message: t('Client created successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleError = (error: ApiError) => {
    if (error.status === 409 && error.message === CLIENT_ALREADY_EXIST) {
      methods.setError('user', { message: 'This user is already linked to another client.' })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while creating client'),
        severity: 'error',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<ClientCreate> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      clientApi
        .create(token, companyId, {
          name: form.name,
          userId: form.user?.id,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Create client')}</DialogTitle>
          <DialogContent>
            <ClientCreateForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LoadingButton type='submit' variant='contained' size='large' loading={loading} fullWidth disableElevation>
                  {t('Create')}
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
