// material-ui
import { useTheme } from '@mui/material/styles'
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { Plan } from '../../../../types/Plan'
import { getSubTotal, getTotal } from '../../../../utils/subscription'
import { getPriceString } from '../../../../utils/typography'

// ========================|| SUBSCRIPTION - PRICING CARD ||======================== //

interface Props {
  plan: Plan
  assistants: number
}

export default function SubscriptionPricingCard({ plan, assistants }: Props) {
  // hooks
  const { i18n, t } = useTranslation()
  const theme = useTheme()

  const discount = 0
  const subTotal = getSubTotal(plan, assistants)
  const total = getTotal(plan, assistants)

  return (
    <Card
      sx={{
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <CardContent sx={{ '&:last-child': { pb: '16px' } }}>
        <Grid container spacing={3} justifyContent='flex-end'>
          <Grid item sm={8} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1' align='right'>
                      {`${assistants} ${assistants === 1 ? t('assistant') : t('assistants')}`}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' align='right'>
                      {getPriceString(subTotal, plan.currency, i18n.language, 0)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1' align='right'>{t('Discount')} (0%):</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' align='right'>
                      {getPriceString(discount, plan.currency, i18n.language, 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ borderColor: 'grey.200' }} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1' align='right' color='primary'>
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1' align='right' color='primary'>
                      {getPriceString(total, plan.currency, i18n.language, 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
