// third-party
import axios from 'axios'
import i18n from 'i18next'
import qs from 'qs'

// project imports
import { ApiError } from './types/ApiError'

// ========================|| CONFIG - AXIOS ||======================== //

const SERVER_ERROR: ApiError = { message: 'Sorry, an unexpected error occurred with your request. Please try again later.', status: 500 }

const UNAUTHORIZED: ApiError = { message: 'Sorry, you are not authorized. Please login to view this page.', status: 401 }

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}`,
  paramsSerializer: (params: any) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
})

axiosInstance.defaults.headers.common['Accept-Language'] = i18n.language;

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (!error.response || !error.response.data || error.response.status === 500) {
      return Promise.reject(SERVER_ERROR)
    }

    if (error.response.status === 401) {
      return Promise.reject(UNAUTHORIZED)
    }

    return Promise.reject(error.response.data)
  },
)
