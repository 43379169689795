import { ReactNode } from 'react'

// project imports
import UserCompanyContext from '../usercompany/UserCompanyContext'
import CompanyStatsContext from '../companystats/CompanyStatsContext'
import IntegrationContext from '../integration/IntegrationContext'
import OptionalSubscriptionContext from '../subscription/OptionalSubscriptionContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useUserCompany from '../../hooks/usercompany/useUserCompany'
import useSubscription from '../../hooks/subscription/useSubscription'
import useIntegration from '../../hooks/integration/useIntegration'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import Error from '../../views/Maintenance/Error/Error'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import useCompanyStats from '../../hooks/companystats/useCompanyStats'

// ========================|| PROVIDER - COMPANY LAYOUT PROVIDER ||======================== //

export default function CompanyLayoutProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { loading: loadingCompany, error: errorCompany, userCompany, setUserCompany } = useUserCompany(companyId)
  const { loading: loadingStats, error: errorStats, stats, setStats } = useCompanyStats(companyId)
  const { loading: loadingSubscription, error: errorSubscription, subscription, setSubscription } = useSubscription(companyId)
  const { loading: loadingIntegration, error: errorIntegration, integration, setIntegration } = useIntegration(companyId)

  const loading = loadingCompany || loadingStats || loadingSubscription || loadingIntegration

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (errorCompany) {
    if (errorCompany.status === 403 || errorCompany.status === 404) {
      return <NotFound />
    }

    return <Error />
  }

  if (errorStats || errorSubscription || errorIntegration) {
    return <Error />
  }

  if (!stats || !userCompany || !integration) {
    return <Error />
  }

  return (
    <UserCompanyContext.Provider value={{ userCompany, setUserCompany }}>
      <CompanyStatsContext.Provider value={{ stats, setStats }}>
        <OptionalSubscriptionContext.Provider value={{ subscription, setSubscription }}>
          <IntegrationContext.Provider value={{ integration, setIntegration }}>{children}</IntegrationContext.Provider>
        </OptionalSubscriptionContext.Provider>
      </CompanyStatsContext.Provider>
    </UserCompanyContext.Provider>
  )
}
