import { useContext, useMemo } from 'react'

// third-party
import { useTranslation } from 'react-i18next'
import debouce from 'lodash.debounce'

// project imports
import CompanyUsersContext from '../../../../context/usercompany/CompanyUsersContext'
import SearchBar from '../../../../ui-components/extended/textfield/SearchBar'

// ========================|| COMPANY USER - SEARCH BAR ||======================== //

export default function CompanyUserSearchBar() {
  // hooks
  const { t } = useTranslation()
  const { setParams } = useContext(CompanyUsersContext)

  const handleSearch = (event: any) => {
    const search = event.target.value || undefined
    setParams(params => ({ ...params, search: search }))
  }

  const debounceSearch = useMemo(() => {
    return debouce(handleSearch, 300)
  }, [])

  return <SearchBar placeholder={t('Search User') as string} onChange={debounceSearch} />
}
