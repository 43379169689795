// third-party
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// project imports
import en from './assets/locales/en/en-US.json'
import es from './assets/locales/es/es-AR.json'

// ========================|| CONFIG - I18N ||======================== //

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en-US', 'es-AR'],
    fallbackLng: {
      en: ['en-US'],
      es: ['es-AR'],
      default: ['es-AR'],
    },
    resources: {
      'en-US': en,
      'es-AR': es,
    },
    returnNull: false,
  })