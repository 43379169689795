import { ReactNode } from 'react'

// material-ui
import { Grid, Typography } from '@mui/material'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'

// ========================|| BOOKING - FILTER TAG ||======================== //

interface Props {
  title: string
  children: ReactNode
}

export default function BookingFilterTag({ title, children }: Props) {
  return (
    <SubCard content={false} sx={{ p: '12px' }}>
      <Grid container spacing={1}>
        <Grid item display='flex' alignItems='center'>
          <Typography variant='subtitle1'>{title}</Typography>
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </SubCard>
  )
}