import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserCompanyContext from '../../context/usercompany/UserCompanyContext'
import BranchContext from '../../context/branch/BranchContext'

// ========================|| HOOK - BRANCH BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { userCompany } = useContext(UserCompanyContext)
  const { branch } = useContext(BranchContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const branchesUrl = { title: t('Branches'), url: `/companies/${userCompany.id}/branches` }
  const branchUrl = { title: branch.name, url: `/companies/${userCompany.id}/branches/${branch.id}` }

  const businessHours = { title: t('Business Hours') }
  const users = { title: t('Users') }
  const branchName = { title: branch.name }

  switch (pathname.split('/').at(-1)) {
    case 'business-hours': {
      return [branchesUrl, branchUrl, businessHours]
    }
    case 'users': {
      return [branchesUrl, branchUrl, users]
    }
    default: {
      return [branchesUrl, branchName]
    }
  }
}

export default useBreadcrumbs
