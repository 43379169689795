import CompanyContext from '../../../../../../context/company/CompanyContext'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

// project imports
import ClientContext from '../../../../../../context/client/ClientContext'
import { Service } from '../../../../../../types/Service'
import { ServiceAssistant } from '../../../../../../types/ServiceAssistant'
import { getDepositPercentage, isDepositRequired } from '../../../../../../utils/serviceAssistant'
import { getPriceString } from '../../../../../../utils/typography'

// ========================|| CHECKOUT - CARD PRICING ||======================== //

interface Props {
  currency: string
  subTotal: number
}

export default function CheckoutPricingCard({ currency, subTotal }: Props) {
  // hooks
  const theme = useTheme()
  const { i18n, t } = useTranslation()
  const { client } = useContext(ClientContext)
  const { company } = useContext(CompanyContext)
  const { watch } = useFormContext()

  // state
  const service: Service = watch('service')
  const assistant: ServiceAssistant = watch('assistant')
  const discunt = 0

  return (
    <Card
      sx={{
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <CardContent sx={{ '&:last-child': { pb: '16px' } }}>
        <Grid container spacing={3} display='flex' justifyContent='flex-end'>
          <Grid item md={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1' align='right'>
                      Sub Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' align='right'>
                      {getPriceString(subTotal, currency, i18n.language)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1' align='right'>
                      {t('Discount')} (0%):
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' align='right'>
                      {getPriceString(discunt, currency, i18n.language)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ borderColor: 'grey.200' }} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1' align='right' color='primary'>
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1' align='right' color='primary'>
                      {getPriceString(subTotal, currency, i18n.language)}
                    </Typography>
                  </Grid>
                  {isDepositRequired(client, service, assistant) && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1' align='right' color='primary'>
                          {`${t('Advance deposit')} (${getDepositPercentage(service, company) * 100}%):`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1' align='right' color='primary'>
                          {getPriceString(Math.ceil(subTotal * getDepositPercentage(service, company)), currency, i18n.language)}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
