// material-ui
import { useTheme } from '@mui/material/styles'
import { Button, Container, Grid, Typography, useMediaQuery } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// ========================|| LANDING - MAIN ||======================== //

export default function Main() {
  // hooks
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  const handleSignin = () => {
    navigate('signin')
  }

  return (
    <Container
      sx={{
        pt: matchesMD ? '104px' : '240px',
        height: `calc(100vh - ${matchesMD ? '48px' : '160px'})`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h1' fontSize={matchesXS ? '2.25rem' : '4rem'} textAlign='center'>
            {t('Creá tu agenda de ')}
            <Typography component='span' variant='h1' fontSize={matchesXS ? '2.25rem' : '4rem'} color='primary'>
              {t('turnos online')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h2'
            fontSize={matchesXS ? '1rem' : '1.5rem'}
            fontWeight={400}
            color='text.primary'
            textAlign='center'
          >
            {t('Simplificá tu agenda y maximizá tu tiempo con Empreturnos, la nueva forma de gestionar turnos de forma online y automatizada para tu emprendimiento.')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container display='flex' justifyContent='center' spacing={2} marginY='26px'>
            <Grid item>
              <Button variant='contained' size='large' color='secondary' onClick={handleSignin}>
                {t('Probar 30 días gratis')}
              </Button>
            </Grid>
            <Grid item>
              <Button size='large' href='/signin'>
                {(t('Sacar un turno'))}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
