import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import BillingApi from '../../api/billing/BillingApi'
import { BillingInformation } from '../../types/BillingInformation'
import { ApiError } from '../../types/ApiError'

// apis
const billingApi = new BillingApi()

// ========================|| HOOK - BILLING ||======================== //

const useBilling = (companyId: string) => {
  const { auth } = useAuth()
  const [billing, setBilling] = useState<BillingInformation>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const fetchBilling = () => {
    auth!.getIdToken().then(token => {
      billingApi.findByCompanyId(token, companyId)
        .then(data => setBilling(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(fetchBilling, [companyId])

  return { loading, error, billing, setBilling }
}

export default useBilling