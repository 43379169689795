import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import LinkOffTwoToneIcon from '@mui/icons-material/LinkOffTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserAssistantDeleteDialog from '../UserAssistnatDeleteDialog/UserAssistantDeleteDialog'
import { UserAssistant } from '../../../../types/UserAssistant'

// ========================|| USER ASSISTANT - DELETE BUTTON ||======================== //

interface Props {
  userAssistant: UserAssistant
}

export default function UserAssistantDeleteButton({ userAssistant }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Disassociate from assistant')}>
        <IconButton color='error' size='large' onClick={handleOpen}>
          <LinkOffTwoToneIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <UserAssistantDeleteDialog open={open} userAssistant={userAssistant} onClose={handleClose} />
    </>
  )
}
