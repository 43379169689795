import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlertColor } from '@mui/material/Alert'

// Define a type for the slice state
export interface SnackbarState {
  open: boolean
  message?: string | null
  severity: AlertColor | undefined
}

// Define the initial state using that type
export const initialState: SnackbarState = {
  open: false,
  message: undefined,
  severity: 'info',
}

export const menuSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state: SnackbarState, action: PayloadAction<SnackbarState>) => {
      return {
        open: action.payload.open,
        message: action.payload.message,
        severity: action.payload.severity,
      }
    },
  },
})

export const { setSnackbar } = menuSlice.actions

export default menuSlice.reducer
