// project imports
import CompanyCard from './CompanyCard/CompanyCard'
import CompanyProvider from '../../../context/company/CompanyProvider'
import ClientProvider from '../../../context/client/ClientProvider'

// ========================|| COMPANY BOOKING ||======================== //

export default function Company() {
  return (
    <CompanyProvider>
      <ClientProvider>
        <CompanyCard />
      </ClientProvider>
    </CompanyProvider>
  )
}
