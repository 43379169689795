import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { UserCompany, UserCompanyParams } from '../../types/UserCompany'
import { ApiError } from '../../types/ApiError'

// ========================|| CONTEXT - USER COMPANIES ||======================== //

interface UserCompaniesContextType {
  loading: boolean
  error?: ApiError
  userCompanies: UserCompany[]
  setUserCompanies: Dispatch<SetStateAction<UserCompany[]>>
  params: UserCompanyParams
  setParams: Dispatch<SetStateAction<UserCompanyParams>>
}

const UserCompaniesContext = createContext<UserCompaniesContextType>({} as UserCompaniesContextType)

export default UserCompaniesContext
