import { ReactNode } from 'react'

// project imports
import BusinessHoursContext from './BusinessHoursContext'
import BusinessHoursAdjustedContext from './BusinessHoursAdjustedContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'
import useCompanyBusinessHours from '../../hooks/businesshour/useCompanyBusinessHours'
import useCompanyBusinessHoursAdjusted from '../../hooks/businesshour/useCompanyBusinessHoursAdjusted'

// ========================|| PROVIDER - BUSINESS HOURS ||======================== //

export default function CompanyBusinessHoursProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const {
    loading: loadingBusinessHours,
    error: businessHoursError,
    businessHours,
    setBusinessHours
  } = useCompanyBusinessHours(companyId)

  const {
    loading: loadingBusinessHoursAdjusted,
    error: businessHoursAdjustedError,
    businessHoursAdjusted,
    setBusinessHoursAdjusted
  } = useCompanyBusinessHoursAdjusted(companyId)

  const loading = loadingBusinessHours || loadingBusinessHoursAdjusted

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (businessHoursError) {
    return <ApiErrorAlert error={businessHoursError} />
  }

  if (businessHoursAdjustedError) {
    return <ApiErrorAlert error={businessHoursAdjustedError} />
  }

  if (!businessHours || !businessHoursAdjusted) {
    return <ApiErrorAlert />
  }

  return (
    <BusinessHoursContext.Provider value={{ businessHours, setBusinessHours }}>
      <BusinessHoursAdjustedContext.Provider value={{ businessHoursAdjusted, setBusinessHoursAdjusted }}>
        {children}
      </BusinessHoursAdjustedContext.Provider>
    </BusinessHoursContext.Provider>
  )
}
