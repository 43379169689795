import React, { useEffect, useMemo, useState } from 'react'

// ========================|| HOOK - PAGINATION ||======================== //

const usePagination = (elements: any[]) => {
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(5)

  const count = useMemo(() => Math.ceil(elements.length / rowsPerPage), [elements])

  useEffect(() => setPage(1), [elements])

  const rows = elements.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }

  return { count, rows, page, handleChange }
}

export default usePagination
