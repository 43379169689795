import { useState } from 'react'

// material-ui
import { IconButton, Tooltip } from '@mui/material'
import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingFilterDialog from '../BookingFilterDialog/BookingFilterDialog'

// ========================|| BOOKING - FILTER BUTTON ||======================== //

export default function BookingFilterButton() {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Filters')}>
        <IconButton size='large' onClick={handleOpen}>
          <FilterListTwoToneIcon />
        </IconButton>
      </Tooltip>
      <BookingFilterDialog open={open} onClose={handleClose} />
    </>
  )
}
