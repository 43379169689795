import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { UserStats } from '../../types/UserStats'

// ========================|| CONTEXT - USER STATS ||======================== //

interface UserStatsContextType {
  stats: UserStats
  setStats: Dispatch<SetStateAction<UserStats | undefined>>
}

const UserStatsContext = createContext<UserStatsContextType>({} as UserStatsContextType)

export default UserStatsContext
