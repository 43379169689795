import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import AssistantApi from '../../api/assistant/CompanyAssistantApi'
import { Assistant, AssistantParams } from '../../types/Assistant'
import { ApiError } from '../../types/ApiError'

// apis
const assistantApi = new AssistantApi()

// ========================|| HOOK - COMPANY ASSISTANTS ||======================== //

export default function useAssistants(companyId: string) {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [assistants, setAssistants] = useState<Assistant[]>([])
  const [params, setParams] = useState<AssistantParams>({})

  useEffect(() => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      assistantApi
        .findAll(token, companyId, params)
        .then(data => setAssistants(data))
        .catch(error => setError(error))
        .finally(() => {
          setFirstLoading(false)
          setLoading(false)
        })
    })
  }, [companyId, params])

  return { firstLoading, loading, error, assistants, params, setAssistants, setParams }
}
