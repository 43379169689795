import { useContext, useMemo } from 'react'

// third-party
import { useTranslation } from 'react-i18next'
import debouce from 'lodash.debounce'

// project imports
import SearchBar from '../../../../ui-components/extended/textfield/SearchBar'
import UserCompaniesContext from '../../../../context/usercompany/UserCompaniesContext'

// ========================|| USER COMPANY - SEARCH BAR ||======================== //

export default function UserCompanySearchBar() {
  // hooks
  const { t } = useTranslation()
  const { setParams } = useContext(UserCompaniesContext)

  const handleSearch = (event: any) => {
    const search = event.target.value || undefined
    setParams(params => ({ ...params, search, page: 0 }))
  }

  const debounceSearch = useMemo(() => {
    return debouce(handleSearch, 300);
  }, [])

  return <SearchBar placeholder={t('Search Company') as string} onChange={debounceSearch} />
}