// third-party
import { Navigate, Outlet } from 'react-router-dom'

// project imports
import useUser from '../../hooks/user/useUser'
import Loader from '../../ui-components/extended/progress/Loader/Loader'

// ========================|| GUARD - NON USER ||======================== //

export default function NonUserGuard() {
  const { loading, user } = useUser()

  if (loading) {
    return <Loader />
  }

  if (user) {
    return <Navigate to='/users/me/bookings' replace />
  }

  return <Outlet />
}
