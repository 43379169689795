import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import ServiceApi from '../../api/service/ServiceApi'
import { ApiError } from '../../types/ApiError'
import { Service } from '../../types/Service'

// apis
const serviceApi = new ServiceApi()

// ========================|| HOOK - SERVICE ||======================== //

const useService = (serviceId: string) => {
  const { auth } = useAuth()
  const [service, setService] = useState<Service>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchService = () => {
    auth!.getIdToken().then(token => {
      serviceApi.findById(token, serviceId)
        .then(data => setService(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchService, [serviceId])

  return { loading, error, service, setService }
}

export default useService