// material-ui
import { IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// project imports
import { Company } from '../../../../types/Company'

// ========================|| COMPANY - BOOK BUTTON ||======================== //

interface Props {
  company: Company
}

export default function CompanyBookButton({ company }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/${company.name}`)
  }

  return (
    <Tooltip title={t('New booking')}>
      <span>
        <IconButton aria-label='New booking' color='primary' size='large' disabled={!company.active} onClick={handleClick}>
          <AddIcon fontSize='small' />
        </IconButton>
      </span>
    </Tooltip>
  )
}
