import { useContext } from 'react'

// material-ui
import { Grid, Typography } from '@mui/material'

// third-party
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

// project imports
import UserAssistantContext from '../../../../context/userassistant/UserAssistantContext'
import BookingConfirmButton from '../../../CompanyLayout/Bookings/BookingConfirmButton/BookingConfirmButton'
import BookingCancelButton from '../../../CompanyLayout/Bookings/BookingCancelButton/BookingCancelButton'
import BookingListItemMenu from '../../../CompanyLayout/Bookings/BookingListItemMenu/BookingListItemMenu'
import { BookingStatusDot } from '../../../../ui-components/extended/chip/BookingStatusChip'
import { Booking } from '../../../../types/Booking'
import { UserAssistantRole } from '../../../../types/UserAssistantRole'
import { locale } from '../../../../utils/locale'
import { getDepositText, getNullablePriceString } from '../../../../utils/typography'

// ========================|| BOOKING - LIST ITEM ||======================== //

interface Props {
  booking: Booking
}

export default function BookingListItem({ booking }: Props) {
  // hooks
  const { i18n, t } = useTranslation()
  const { userAssistant } = useContext(UserAssistantContext)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={true} zeroMinWidth>
            <Typography variant='subtitle1'>{booking.service.name}</Typography>
          </Grid>
          {userAssistant.role === UserAssistantRole.EDITOR && (
            <Grid item>
              <BookingListItemMenu booking={booking} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography component='span' variant='caption'>
          {t('Client')}
        </Typography>
        <Typography variant='h6'>{booking.client.user?.name || booking.client.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component='span' variant='caption'>
          {t('Status')}
        </Typography>
        <BookingStatusDot status={booking.status} />
      </Grid>
      <Grid item xs={6}>
        <Typography component='span' variant='caption'>
          {t('Branch')}
        </Typography>
        <Typography variant='h6'>{booking.branch.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component='span' variant='caption'>
          {t('Date')}
        </Typography>
        <Typography variant='h6'>{dayjs(booking.date).locale(locale(i18n.language)).format('DD MMM, HH:mm')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component='span' variant='caption'>
          {t('Price')}
        </Typography>
        <Typography variant='h6'>
          {t(getNullablePriceString(booking.price, booking.currency, i18n.language))}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component='span' variant='caption'>
          {t('Deposit')}
        </Typography>
        <Typography variant='h6'>
          {t(getDepositText(booking.price, booking.currency, booking.depositAmount, i18n.language))}
        </Typography>
      </Grid>
      {userAssistant.role === UserAssistantRole.EDITOR && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <BookingConfirmButton booking={booking} />
            </Grid>
            <Grid item xs={6}>
              <BookingCancelButton booking={booking} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
