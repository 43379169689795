// material-ui
import { CardContent, Divider, Grid } from '@mui/material'

// project imports
import { useTranslation } from 'react-i18next'

// project imports
import MainCard from '../../../../ui-components/extended/card/MainCard'
import UserBranchSearchBar from '../UserBranchSearchBar/UserBranchSearchBar'
import UserBranchTable from '../UserBranchTable/UserBranchTable'

// ========================|| USER BRANCH - CARD ||======================== //

export default function UserBranchCard() {
  // hooks
  const { t } = useTranslation()

  return (
    <MainCard title={t('Branches')} content={false} border={false}>
      <CardContent sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={true}>
            <UserBranchSearchBar />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <UserBranchTable />
    </MainCard>
  )
}
