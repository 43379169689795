// material-ui
import { ThemeProvider } from '@mui/material'

// project imports
import ThemeRoute from './routes/ThemeRoute'
import Snackbar from './ui-components/extended/snackbar/Snackbar'
import theme from './themes/theme'

// ========================|| APP ||======================== //

function App() {
  return (
    <ThemeProvider theme={theme()}>
      <ThemeRoute />
      <Snackbar />
    </ThemeProvider>
  )
}

export default App
