import { useContext } from 'react'

// material-ui
import { Box, Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import IntegrationContext from '../../../../context/integration/IntegrationContext'
import IconCard from '../IconCard/IconCard'
import IntegrationCard from '../IntegrationCard/IntegrationCard'
import IntegrationGoogleConnectButton from '../IntegrationGoogleConnectButton/IntegrationGoogleConnectButton'
import IntegrationGoogleDisconnectButton from '../IntegrationGoogleDisconnectButton/IntegrationGoogleDisconnectButton'

// assets
import { ReactComponent as GoogleCalendarIcon } from '../../../../assets/images/google-meet.svg'

// ========================|| INTEGRATIONS - GOOGLE - UNLINK CARD ||======================== //

export default function IntegrationGoogleCard() {
  const { t } = useTranslation()
  const { integration } = useContext(IntegrationContext)

  return (
    <IntegrationCard>
      <Grid container spacing={2} direction='column' flexWrap='nowrap'>
        <Grid item>
          <IconCard>
            <GoogleCalendarIcon width={48} height={48} />
          </IconCard>
        </Grid>
        <Grid item xs={true}>
          <Box>
            <Typography variant='subtitle1' display='flex' alignItems='center'>
              Google Meet
            </Typography>
            <Typography variant='subtitle2'>{t('Connect your Google account to create online services with a Google Meet link.')}</Typography>
          </Box>
        </Grid>
        <Grid item>{integration.googleMeet ? <IntegrationGoogleDisconnectButton /> : <IntegrationGoogleConnectButton />}</Grid>
      </Grid>
    </IntegrationCard>
  )
}
