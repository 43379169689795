import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import AssistantApi from '../../api/assistant/AssistantApi'
import { ApiError } from '../../types/ApiError'
import { Assistant } from '../../types/Assistant'

// apis
const assistantApi = new AssistantApi()

// ========================|| HOOK - ASSISTANT ||======================== //

const useAssistant = (assistantId: string) => {
  const { auth } = useAuth()
  const [assistant, setAssistant] = useState<Assistant>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchAssistant = () => {
    auth!.getIdToken().then(token => {
      assistantApi.findById(token, assistantId)
        .then(data => setAssistant(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchAssistant, [assistantId])

  return { loading, error, assistant, setAssistant }
}

export default useAssistant