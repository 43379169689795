// project imports
import { axiosInstance } from '../../axios'
import { Page } from '../../types/Page'
import { AssistantUser } from '../../types/AssistantUser'
import { UserAssistantParams } from '../../types/UserAssistant'
import { UserAssistantCreateDto } from './dto/UserAssistantCreateDto'
import { UserAssistantUpdateDto } from './dto/UserAssistantUpdateDto'

// ========================|| API - ASSISTANT USER ||======================== //

export default class AssistantUserApi {
  findAll(token: string, assistantId: string, params: UserAssistantParams): Promise<Page<AssistantUser>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/assistants/${assistantId}/users`, config)
  }

  create(token: string, assistantId: string, user: UserAssistantCreateDto): Promise<AssistantUser> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/assistants/${assistantId}/users`, user, config)
  }

  update(token: string, assistantId: string, userId: string, user: UserAssistantUpdateDto): Promise<AssistantUser> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/assistants/${assistantId}/users/${userId}`, user, config)
  }

  delete(token: string, assistantId: string, userId: string) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/assistants/${assistantId}/users/${userId}`, config)
  }
}
