// material-ui
import { FormControl, Grid, TextField } from '@mui/material'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import UserAutocomplete from '../../../../ui-components/extended/autocomplete/UserAutocomplete/UserAutocomplete'
import { ClientCreate } from '../../../../types/Client'

// ========================|| CLIENT - FORM ||======================== //

export default function ClientCreateForm() {
  const { t } = useTranslation()
  const { control } = useFormContext<ClientCreate>()

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid item xs={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 100, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                id='name'
                label={t('Name')}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                InputProps={{ autoComplete: 'off' }}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='user'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <UserAutocomplete
              value={value}
              error={error}
              onChange={(e, user) => onChange(user)}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
