// material-ui
import { Box, Toolbar, useTheme } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'

// project imports
import useDrawer from '../../hooks/useDrawer'
import LogoSection from '../LogoSection/LogoSection'
import BackButton from '../ProfileChip/BackButton'
import ProfileSection from '../ProfileSection/ProfileSection'

// assets
import MainMenuButton from './MainMenuButton'

// ========================|| APPBAR - COMPANY ||======================== //

export default function CompanyAppBar() {
  // hooks
  const theme = useTheme()
  const { drawerOpened, handleDrawerToggle } = useDrawer()

  return (
    <MuiAppBar
      position='fixed'
      color='inherit'
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.default,
        transition: drawerOpened ? theme.transitions.create('width') : 'none',
      }}
    >
      <Toolbar>
        <Box
          display='flex'
          alignItems='center'
          width={236}
          pr='16px'
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box component='span' sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
            <LogoSection />
          </Box>
        </Box>

        <Box display='flex' flexDirection='row'>
          <Box>
            <MainMenuButton onClick={handleDrawerToggle} />
          </Box>
          <Box ml={2}>
            <BackButton />
          </Box>
        </Box>

        <Box flexGrow={1} />

        <ProfileSection />
      </Toolbar>
    </MuiAppBar>
  )
}
