import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Company, CompanyParams } from '../../types/Company'
import { Page } from '../../types/Page'
import { ApiError } from '../../types/ApiError'

// ========================|| CONTEXT - COMPANY ||======================== //

interface CompanyContextType {
  loading: boolean
  error?: ApiError
  companies: Page<Company>
  params: CompanyParams
  setCompanies: Dispatch<SetStateAction<Page<Company>>>
  setParams: Dispatch<SetStateAction<CompanyParams>>
}

const CompaniesContext = createContext<CompanyContextType>({} as CompanyContextType)

export default CompaniesContext