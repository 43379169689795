import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Branch, BranchParams } from '../../types/Branch'
import { ApiError } from '../../types/ApiError'

// ========================|| CONTEXT - BRANCH ||======================== //

interface BranchContextType {
  loading: boolean
  error?: ApiError
  branches: Branch[]
  params: BranchParams
  setBranches: Dispatch<SetStateAction<Branch[]>>
  setParams: Dispatch<SetStateAction<BranchParams>>
}

const BranchesContext = createContext<BranchContextType>({} as BranchContextType)

export default BranchesContext