import { useEffect } from 'react'

// third-party
import { useDispatch } from 'react-redux'

// project imports
import AssistantContext from '../../../context/assistant/AssistantContext'
import useCompanyId from '../../../hooks/company/useCompanyId'
import useAssistantId from '../../../hooks/assistant/useAssistantId'
import useAssistant from '../../../hooks/assistant/useAssistant'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import AssistantCard from './AssistantCard/AssistantCard'
import { setIsOpen } from '../../../store/menu/reducer'

// ========================|| ASSISTANT ||======================== //

export default function Assistant() {
  const companyId = useCompanyId()
  const assistantId = useAssistantId()
  const dispatch = useDispatch()
  const { loading, error, assistant, setAssistant } = useAssistant(assistantId)

  useEffect(() => {
    dispatch(setIsOpen(`/companies/${companyId}/assistants`))
  }, [])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!assistant) {
    return <ApiErrorAlert />
  }

  return (
    <AssistantContext.Provider value={{ assistant, setAssistant }}>
      <AssistantCard />
    </AssistantContext.Provider>
  )
}
