// project imports
import { axiosInstance } from '../../axios'
import { CompanyUpdateDto } from './dto/CompanyUpdateDto'
import { Company, CompanyParams } from '../../types/Company'
import { Page } from '../../types/Page'

// ========================|| API - COMPANY ||======================== //

export default class CompanyApi {
  findByName(token: string, companyName: string): Promise<Company> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyName}`, config)
  }

  findAll(token: string, params: CompanyParams): Promise<Page<Company>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get('/api/v1/companies', config)
  }

  update(token: string, companyId: string, details: CompanyUpdateDto): Promise<Company> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/companies/${companyId}`, details, config)
  }
}
