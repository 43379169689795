import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Client, ClientParams } from '../../types/Client'
import { Page } from '../../types/Page'

// ========================|| CONTEXT - CLIENT ||======================== //

interface ClientsContextType {
  clients: Page<Client>
  params: ClientParams
  setClients: Dispatch<SetStateAction<Page<Client>>>
  setParams: Dispatch<SetStateAction<ClientParams>>
}

const ClientsContext = createContext<ClientsContextType>({} as ClientsContextType)

export default ClientsContext
