import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import AssistantUserApi from '../../api/userassistant/AssistantUserApi'
import { DEFAULT_PAGE, DEFAULT_PARAMS, Page } from '../../types/Page'
import { ApiError } from '../../types/ApiError'
import { UserAssistantParams } from '../../types/UserAssistant'
import { AssistantUser } from '../../types/AssistantUser'

// apis
const assistantUserApi = new AssistantUserApi()

// ========================|| HOOK - ASSISTANT USERS ||======================== //

const useAssistantUsers = (assistantId: string) => {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [assistantUsers, setAssistantUsers] = useState<Page<AssistantUser>>(DEFAULT_PAGE)
  const [params, setParams] = useState<UserAssistantParams>(DEFAULT_PARAMS)

  const searchAssistantUsers = () => {
    if (assistantId) {
      setLoading(true)
      auth!.getIdToken().then(token => {
        assistantUserApi
          .findAll(token, assistantId, params)
          .then(data => setAssistantUsers(data))
          .catch(error => setError(error))
          .finally(() => {
            setFirstLoading(false)
            setLoading(false)
          })
      })
    } else {
      setFirstLoading(false)
      setLoading(false)
    }
  }

  useEffect(searchAssistantUsers, [assistantId])

  return { firstLoading, loading, error, assistantUsers, params, setAssistantUsers, setParams }
}

export default useAssistantUsers
