import { ReactNode } from 'react'

// material-ui
import { Box, BoxProps, Container, Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

interface Props {
  id: string
  title: string
  subtitle: string
  description: string
  children: ReactNode
  sx?: BoxProps['sx']
  colorWhite?: boolean
}

export default function Section({ id, title, subtitle, description, children, sx, colorWhite }: Props) {
  const { t } = useTranslation()

  return (
    <Box sx={sx} id={id} display='flex'>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h5' color={colorWhite ? 'white' : 'primary'}>
                  {t(title)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h2' color={colorWhite ? 'white' : 'text.primary'}>{t(subtitle)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component='p' variant='body2' color={colorWhite ? 'white' : 'text.primary'}>
                  {t(description)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
