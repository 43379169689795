import { ReactNode } from 'react'

// material-ui
import { CardContent } from '@mui/material'

// ========================|| UI COMPONENTS - TABLE - MAIN CONTENT ||======================== //

interface Props {
  children: ReactNode
}

export default function MainContent({ children }: Props) {
  return <CardContent sx={{ p: 3 }}>{children}</CardContent>
}
