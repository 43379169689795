import { useState } from 'react'

// material-ui
import { Link } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import PaymentMethodEditDialog from '../PaymentMethodEditDialog/PaymentMethodEditDialog'

// ========================|| PAYMENT - EDIT BUTTON ||======================== //

export default function PaymentMethodEditButton() {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Link variant='body1' color='primary' underline='hover' sx={{ cursor: 'pointer' }} onClick={handleOpen}>
        {t('Edit')}
      </Link>
      <PaymentMethodEditDialog open={open} onClose={handleClose} />
    </>
  )
}
