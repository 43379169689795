// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, ButtonBase, Tooltip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconUser } from '@tabler/icons-react'

// ========================|| BACK BUTTON ||======================== //

export default function BackButton() {
  const theme = useTheme()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    const main = pathname
      .split('/')
      .at(1) || ''

    navigate(`/users/me/${main}`)
  }

  return (
    <ButtonBase sx={{ overflow: 'hidden' }}>
      <Tooltip title={t('Return to client page')}>
        <Avatar
          variant='rounded'
          sx={{
            ...theme.commonAvatar,
            ...theme.mediumAvatar,
            transition: 'all .2s ease-in-out',
            background: theme.palette.primary.light,
            color: theme.palette.primary.dark,
            '&:hover': {
              background: theme.palette.primary.dark,
              color: theme.palette.primary.light,
            },
          }}
          onClick={handleClick}
          color='inherit'
        >
          <IconUser stroke={1.5} size='1.3rem' />
        </Avatar>
      </Tooltip>
    </ButtonBase>
  )
}
