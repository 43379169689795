// material-ui
import { Box, Grid } from '@mui/material'

// project imports
import Section from '../Section/Section'
import PriceCard from './PriceCard/PriceCard'

const tier = {
  title: 'Pagá por lo que usás',
  description: 'Ideal para la gran mayoria de negocios, emprendimientos y emprendedores.',
  price: 3990,
  features: [
    {
      name: 'Turnos ilimitados',
      included: true,
    },
    {
      name: 'Creá hasta 10 sucursales',
      included: true,
    },
    {
      name: 'Creá hasta 100 servicios',
      included: true,
    },
    {
      name: 'Recordatorios por WhatsApp',
      included: true,
    },
    {
      name: 'Recordatorios por mail automatizados',
      included: true,
    },
    {
      name: 'Sumá colaboradores',
      included: true,
    },
    {
      name: 'Exigí señas a través de Mercado Pago',
      included: true,
    },
    {
      name: 'Asistencia técnica',
      included: true,
    },
  ],
  buttonText: 'Probar 30 días gratis',
  buttonVariant: 'contained',
  link: '/signin',
}

export default function Pricing() {
  return (
    <>
      <Box id='pricing' height='80px' />
      <Section
        title='Precio'
        subtitle='Único plan, todo incluido'
        description='Pagá únicamente por la cantidad asistentes que requieran agendamiento.'
        id='pricing2'
        sx={{ backgroundColor: '#2196f3', py: '80px' }}
        colorWhite
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <PriceCard
              title={tier.title}
              description={tier.description}
              price={tier.price}
              features={tier.features}
              buttonText={tier.buttonText}
              buttonVariant={tier.buttonVariant}
              link={tier.link}
            />
          </Grid>
        </Grid>
      </Section>
    </>
  )
}
