import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanyClientApi from '../../api/client/CompanyClientApi'
import { Client } from '../../types/Client'
import { ApiError } from '../../types/ApiError'

// apis
const clientApi = new CompanyClientApi()

// ========================|| HOOK - CLIENT ||======================== //

const useClient = (companyId: string) => {
  const { auth } = useAuth()
  const [client, setClient] = useState<Client>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()

  const findAll = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      clientApi
        .findMe(token, companyId)
        .then(data => setClient(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(findAll, [companyId])

  return { loading, error, client }
}

export default useClient
