import { ReactNode } from 'react'

// material-ui
import { Table } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// ========================|| UI COMPONENTS - TABLE - SUB TABLE ||======================== //

interface Props {
  children: ReactNode
}

export default function SubTable({ children }: Props) {
  const theme = useTheme()

  return (
    <Table
      sx={{
        '.MuiTableCell-root': {
          '&:first-of-type': {
            padding: '14px 14px 14px 20px',
          },
          '&:last-child': {
            padding: '14px 20px 14px 14px',
          },
          borderColor: theme.palette.divider,
        },
        '.MuiTableHead-root': {
          backgroundColor: theme.palette.grey[50],
        },
      }}
    >
      {children}
    </Table>
  )
}
