import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserAssistantContext from '../../context/userassistant/UserAssistantContext'

// ========================|| HOOK - USER ASSISTANT BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { userAssistant } = useContext(UserAssistantContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const assistantsUrl = { title: t('Assistants'), url: '/users/me/assistants' }
  const assistantUrl = { title: userAssistant.name }
  const bookings = { title: t('Bookings') }

  switch (pathname.split('/').at(-1)) {
    case 'bookings': {
      return [assistantsUrl, assistantUrl, bookings]
    }
    default: {
      return []
    }
  }
}

export default useBreadcrumbs
