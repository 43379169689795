// project imports
import { axiosInstance } from '../../axios'
import { Subscription } from '../../types/Subscription'
import { SubscriptionUpdateDto } from './dto/SubscriptionUpdateDto'

// ========================|| API - SUBSCRIPTION ||======================== //

export default class SubscriptionApi {
  update(token: string, subscriptionId: string, subscription: SubscriptionUpdateDto): Promise<Subscription> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/subscriptions/${subscriptionId}`, subscription, config)
  }

  cancel(token: string, subscriptionId: string): Promise<Subscription> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/subscriptions/${subscriptionId}`, config)
  }
}
