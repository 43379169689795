// material-ui
import { Link, Typography } from '@mui/material'

// ========================|| COPYRIGHT ||======================== //

export default function Copyright(props: any) {
  return (
    <Typography variant='subtitle2' {...props}>
      <Link color='inherit' href='/' underline='hover'>
        &copy; Empreturnos
      </Link>
    </Typography>
  )
}
