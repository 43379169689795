import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Page } from '../../types/Page'
import { AssistantUser } from '../../types/AssistantUser'
import { UserAssistantParams } from '../../types/UserAssistant'

// ========================|| CONTEXT - ASSISTANT USERS ||======================== //

interface AssistantUsersContextType {
  assistantUsers: Page<AssistantUser>
  params: UserAssistantParams
  setAssistantUsers: Dispatch<SetStateAction<Page<AssistantUser>>>
  setParams: Dispatch<SetStateAction<UserAssistantParams>>
}

const AssistantUsersContext = createContext<AssistantUsersContextType>({} as AssistantUsersContextType)

export default AssistantUsersContext