// project imports
import { axiosInstance } from '../../axios'
import { AuthorizationUrlDto } from '../integration/dto/AuthorizationUrlDto'

// ========================|| API - INTEGRATION - GOOGLE ||======================== //

export default class IntegrationGoogleApi {
  getAuthorizationUrl(token: string, companyId: string): Promise<AuthorizationUrlDto> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/google`, config)
  }

  delete(token: string, companyId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/companies/${companyId}/google`, config)
  }
}
