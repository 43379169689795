// project imports
import { axiosInstance } from '../../axios'
import { Page } from '../../types/Page'
import { BranchUser } from '../../types/BranchUser'
import { UserBranchCreateDto } from './dto/UserBranchCreateDto'
import { UserBranchUpdateDto } from './dto/UserBranchUpdateDto'
import { UserBranchParams } from '../../types/UserBranch'

// ========================|| API - BRANCH USER ||======================== //

export default class BranchUserApi {
  findAll(token: string, branchId: string, params: UserBranchParams): Promise<Page<BranchUser>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/branches/${branchId}/users`, config)
  }

  create(token: string, branchId: string, user: UserBranchCreateDto): Promise<BranchUser> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/branches/${branchId}/users`, user, config)
  }

  update(token: string, branchId: string, userId: string, user: UserBranchUpdateDto): Promise<BranchUser> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/branches/${branchId}/users/${userId}`, user, config)
  }

  delete(token: string, branchId: string, userId: string) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/branches/${branchId}/users/${userId}`, config)
  }
}
