// third-party
import { Navigate, Outlet } from 'react-router-dom'

// project imports
import useAuth from '../../context/auth/useAuth'

// ========================|| GUARD - AUTH ||======================== //

export default function AuthGuard() {
  const { auth } = useAuth()

  if (!auth) {
    return <Navigate to={'/signin'} replace />
  }

  return <Outlet />
}
