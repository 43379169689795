// material-ui
import Grid from '@mui/material/Grid'
import { CalendarMonth, DisplaySettings, WhatsApp, Group, Email, Instagram } from '@mui/icons-material'

// project imports
import Section from '../Section/Section'
import FeatureCard from './FeatureCard/FeatureCard'

// ========================|| FEATURES ||======================== //

const features = [
  {
    key: '1',
    icon: <CalendarMonth fontSize='large' />,
    title: 'Agendar turnos es rápido',
    description: 'Tus clientes podrán ver la agenda de tus profesionales sin sacarte tiempo valioso.',
  },
  {
    key: '2',
    icon: <DisplaySettings fontSize='large' />,
    title: 'Administrá tu empresa',
    description: 'Configurá sucursales, asistentes y servicios de una manera muy simple y rápida.',
    color: '#673ab7',
    backgroundColor: '#ede7f6',
  },
  {
    key: '3',
    icon: <WhatsApp fontSize='large' />,
    title: 'Integrado con WhatsApp',
    description: 'Enviá recordatorios a tus clientes con una plantilla automatizada haciendo un click.',
    color: '#00c853',
    backgroundColor: '#b9f6ca',
  },
  {
    key: '4',
    icon: <Email fontSize='large' />,
    title: 'Recordatorios automáticos',
    description: 'Tus clientes reciben un recordatorio por mail cuando se aproxima el turno.',
    color: '#e64a19',
    backgroundColor: '#fbe9e7',
  },
  {
    key: '5',
    icon: <Group fontSize='large' />,
    title: 'Sumá colaboradores',
    description: 'Invitá a otras personas a gestionar tu empresa, sucursal o asistentes.',
    color: '#c2185b',
    backgroundColor: '#fce4ec',
  },
  {
    key: '6',
    icon: <Instagram fontSize='large' />,
    title: 'Link personalizado',
    description: 'Creá un link personalizado a la agenda de turnos para postear en las redes.',
    color: '#f57c00',
    backgroundColor: '#fff3e0',
  },
]

export default function Features() {
  return (
    <Section
      title='Características'
      subtitle='¿De qué se trata?'
      description='Empreturnos es una plataforma que te permite crear y gestionar una agenda de turnos para los profesionales de tu negocio.'
      id='features'
      sx={{ pt: '160px' }}
    >
      <Grid container spacing={3}>
        {features.map(feature => (
          <Grid item xs={12} md={4} key={feature.key}>
            <FeatureCard
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
              color={feature.color}
              backgroundColor={feature.backgroundColor}
            />
          </Grid>
        ))}
      </Grid>
    </Section>
  )
}
