export default function componentStyleOverrides(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: theme.menuSelected,
            backgroundColor: theme.menuSelectedBack,
            '&:hover': {
              backgroundColor: theme.menuSelectedBack,
            },
            '& .MuiListItemIcon-root': {
              color: theme.menuSelected,
              '& svg': {
                stroke: theme.colors.secondaryMain,
              },
            },
          },
          '&:hover': {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            '& .MuiListItemIcon-root': {
              color: theme.menuSelected,
              '& svg': {
                stroke: theme.colors.secondaryMain,
              },
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: theme.colors.grey50,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 28 : theme.colors.grey400,
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors?.primaryLight,
          },
        },
        input: {
          fontWeight: 500,
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          borderBottom: `1px solid ${theme.colors.divider}`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '12px 8px',
          marginRight: '18px',
          minHeight: 'auto',
          minWidth: '10px',
          color: theme.colors.grey900,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          borderWidth: '0px 0px thin',
          opacity: 1,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        colorPrimary: {
          color: theme.colors.primaryMain,
          background: theme.colors.primaryLight,
          '.MuiChip-deleteIcon': {
            color: 'inherit',
            '&:hover': {
              color: 'inherit',
            },
          },
        },
        colorSecondary: {
          color: theme.colors.secondaryMain,
          background: theme.colors.secondaryLight,
          '.MuiChip-deleteIcon': {
            color: 'inherit',
            '&:hover': {
              color: 'inherit',
            },
          },
        },
        colorSuccess: {
          color: theme.colors.successDark,
          backgroundColor: theme.colors.successLight + 60,
          '.MuiChip-deleteIcon': {
            color: 'inherit',
            '&:hover': {
              color: 'inherit',
            },
          },
        },
        colorError: {
          color: theme.colors.errorDark,
          backgroundColor: theme.colors.errorLight + 60,
          '.MuiChip-deleteIcon': {
            color: 'inherit',
            '&:hover': {
              color: 'inherit',
            },
          },
        },
        colorWarning: {
          color: theme.colors.warningDark,
          backgroundColor: theme.colors.warningLight,
          '.MuiChip-deleteIcon': {
            color: 'inherit',
            '&:hover': {
              color: 'inherit',
            },
          },
        },
        colorInfo: {
          color: '#364152',
          backgroundColor: 'transparent',
          border: '1px solid #bdbdbd',
          '.MuiChip-deleteIcon': {
            color: 'inherit',
            '&:hover': {
              color: 'inherit',
            },
          },
        },
        colorDefault: {
          color: theme.colors.grey900,
          background: theme.colors.secondaryLight,
          '.MuiChip-deleteIcon': {
            color: 'inherit',
            '&:hover': {
              color: 'inherit',
            },
          },
        }
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: '#fdf5ea',
        },
        outlinedWarning: {
          border: '1px dashed',
          borderColor: '#f0ad4e'
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.colors.grey700,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.colors.grey500,
        },
      },
    },
  }
}
