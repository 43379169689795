// material-ui
import { Button, Grid, Typography } from '@mui/material'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// project imports
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'
import CompanyUserRoleChip from '../../../CompanyLayout/CompanyUsers/CompanyUserRoleChip/CompanyUserRoleChip'
import { UserCompany } from '../../../../types/UserCompany'

// ========================|| USER COMPANY - LIST ITEM ||======================== //

interface Props {
  userCompany: UserCompany
}

export default function UserCompanyListItem({ userCompany }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/companies/${userCompany.id}/bookings`)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
        <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
          <Grid item>
            <CompanyAvatar active={userCompany.active} />
          </Grid>
          <Grid item>
            <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
              {userCompany.displayName}
            </Typography>
            <Typography variant='caption' align='left' whiteSpace='nowrap'>
              @{userCompany.name}
            </Typography>
          </Grid>
        </Grid>
        <CompanyUserRoleChip role={userCompany.role} />
      </Grid>
      <Grid item xs={12}>
        <Button variant='outlined' size='small' fullWidth startIcon={<VisibilityTwoToneIcon fontSize='small' />} onClick={handleClick}>
          {t('See company')}
        </Button>
      </Grid>
    </Grid>
  )
}
