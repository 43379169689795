import { useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import IntegrationGoogleApi from '../../../../api/integrationgoogle/IntegrationGoogleApi'
import { AuthorizationUrlDto } from '../../../../api/integration/dto/AuthorizationUrlDto'
import { setSnackbar } from '../../../../store/snackbar/reducer'

// ========================|| INTEGRATION - GOOGLE - LINK DIALOG ||======================== //

const integrationGoogleApi = new IntegrationGoogleApi()

export default function IntegrationGoogleConnectButton() {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const companyId = useCompanyId()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleContinue = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      integrationGoogleApi
        .getAuthorizationUrl(token, companyId)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  const handleSuccess = ({ authorizationUrl }: AuthorizationUrlDto) => {
    setOpen(false)
    window.open(authorizationUrl, '_self')
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred when linking Google account') as string,
        severity: 'error',
        open: true,
      }),
    )
  }

  return (
    <>
      <Button size='small' variant='contained' disableElevation fullWidth onClick={() => setOpen(true)}>{t('Connect')}</Button>
      <Dialog
        aria-labelledby='integration-google-link-dialog'
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { p: '12px 0px' } }}
      >
        <DialogTitle id='integration-google-link-dialog'>{t('You will be redirected')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='integration-google-link-dialog-description'>
            {t('Click continue to be re-directed to Google website to connect your account.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
          <Button variant='outlined' color='primary' onClick={() => setOpen(false)}>
            {t('Cancel')}
          </Button>
          <LoadingButton variant='contained' color='primary' disableElevation loading={loading} onClick={handleContinue}>
            {t('Continue')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
