// material-ui
import { FormControl, FormControlLabel, FormHelperText, Grid, Switch, TextField } from '@mui/material'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import UserAutocomplete from '../../../../ui-components/extended/autocomplete/UserAutocomplete/UserAutocomplete'
import { ClientUpdate } from '../../../../types/Client'

// ========================|| CLIENT - EDIT FORM ||======================== //

export default function ClientEditForm() {
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext<ClientUpdate>()

  const user = watch('user')

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid item xs={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 100, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                id='name'
                label={t('Name')}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                InputProps={{ autoComplete: 'off' }}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='user'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <UserAutocomplete
              value={value}
              error={error}
              onChange={(e, user) => {
                if (!user) {
                  setValue('depositEnabled', false, { shouldDirty: true })
                }

                onChange(user)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='depositEnabled'
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl component='fieldset' variant='standard' fullWidth onChange={onChange} disabled={!user}>
              <FormControlLabel
                control={<Switch id='depositEnabled' value={value} checked={value} />}
                label={t('Require deposit')}
              />
              <FormHelperText>
                {user ? t('Enable or disable deposits for this client.') : t('Deposits can only be required from clients with a linked user.')}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  )
}
