// material-ui
import { Box, CssBaseline } from '@mui/material'

// third-party
import { Outlet } from 'react-router-dom'

// project imports
import UserAssistantContext from '../../context/userassistant/UserAssistantContext'
import useAssistantId from '../../hooks/assistant/useAssistantId'
import useUserAssistant from '../../hooks/userassistant/useUserAssistant'
import useDrawer from '../../hooks/useDrawer'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import NotFound from '../Maintenance/NotFound/NotFound'
import Error from '../Maintenance/Error/Error'
import CompanyAppBar from '../../layout/AppBar/CompanyAppBar'
import Sidebar from '../../layout/Sidebar/Sidebar'
import Main from '../../layout/Main/Main'
import Menu from './Menu/Menu'

// ========================|| ASSISTANT - LAYOUT ||======================== //

export default function AssistantLayout() {
  const assistantId = useAssistantId()
  const { drawerOpened } = useDrawer()
  const { loading, error, userAssistant, setUserAssistant } = useUserAssistant(assistantId)

  if (!assistantId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    if (error.status === 403 || error.status === 404) {
      return <NotFound />
    }

    return <Error />
  }

  if (!userAssistant) {
    return <Error />
  }

  return (
    <UserAssistantContext.Provider value={{ userAssistant, setUserAssistant }}>
      <Box display='flex'>
        <CssBaseline />

        <CompanyAppBar />

        <Sidebar>
          <Menu />
        </Sidebar>

        <Main open={drawerOpened}>
          <Outlet />
        </Main>
      </Box>
    </UserAssistantContext.Provider>
  )
}
