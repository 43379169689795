// material-ui
import { Avatar, ButtonBase, ButtonBaseProps, Tooltip, useTheme } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { IconMenu2 } from '@tabler/icons-react'

export default function MainMenuButton(props: ButtonBaseProps) {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <ButtonBase {...props} sx={{ overflow: 'hidden' }}>
      <Tooltip title={t('Main Menu')}>
        <Avatar
          variant='rounded'
          color='inherit'
          sx={{
            ...theme.commonAvatar,
            ...theme.mediumAvatar,
            transition: 'all .2s ease-in-out',
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            '&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
          }}
        >
          <IconMenu2 stroke={1.5} size='1.3rem' />
        </Avatar>
      </Tooltip>
    </ButtonBase>
  )
}