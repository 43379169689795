import { useContext } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Chip, useMediaQuery } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import UserContext from '../../../../context/user/UserContext'
import BookingFilterTag from '../BookingFilterTag/BookingFilterTag'
import { locale } from '../../../../utils/locale'

// ========================|| BOOKING - FILTER TAGS - END DATE ||======================== //

export default function BookingFilterEndDateTag() {
  const { t, i18n } = useTranslation()
  const { params, setParams } = useContext(BookingsContext)
  const { user } = useContext(UserContext)
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  const handleDelete = () => {
    setParams(filter => ({ ...filter, endDate: undefined, page: 0 }))
  }

  return (
    <BookingFilterTag title={t('To')}>
      <Chip
        label={capitalize(params.endDate?.locale(locale(i18n.language)).tz(user.timezone).calendar())}
        color='warning'
        size={matchesXS ? 'small' : 'medium'}
        onDelete={handleDelete}
      />
    </BookingFilterTag>
  )
}