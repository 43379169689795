import { useContext, useState } from 'react'

// material-ui
import { FormControl } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// third-party
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'

// project-imports
import BookingsContext from '../../../../../context/booking/BookingsContext'
import { locale } from '../../../../../utils/locale'

// ========================|| START DATE FILTER ||======================== //

export default function BookingStartDateFilter() {
  // hooks
  const { t, i18n } = useTranslation()
  const { params, setParams } = useContext(BookingsContext)
  const [value, setValue] = useState<Dayjs | null>(params.startDate ? dayjs(params.startDate) : null)

  const handleChange = (startDate: Dayjs | null) => {
    if (startDate && startDate.isValid()) {
      setValue(startDate)
      setParams(params => ({ ...params, startDate: startDate }))
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale(i18n.language)}>
      <FormControl fullWidth>
        <DateTimePicker
          value={value}
          label={t('From')}
          onChange={handleChange}
          disableFuture
          ampm={false}
        />
      </FormControl>
    </LocalizationProvider>
  )
}
