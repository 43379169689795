import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Subscription } from '../../types/Subscription'

// ========================|| CONTEXT - OPTIONAL SUBSCRIPTION ||======================== //

interface OptionalSubscriptionContextType {
  subscription: Subscription | undefined
  setSubscription: Dispatch<SetStateAction<Subscription | undefined>>
}

const OptionalSubscriptionContext = createContext<OptionalSubscriptionContextType>({} as OptionalSubscriptionContextType)

export default OptionalSubscriptionContext