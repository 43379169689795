// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ServiceAssistantsContext from '../../../../context/serviceassistant/ServiceAssistantsContext'
import useServiceId from '../../../../hooks/service/useServiceId'
import useServiceAssistants from '../../../../hooks/serviceassistant/useServiceAssistants'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import Loader from '../../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../../ui-components/extended/alert/ApiErrorAlert'
import ServiceAssistantTable from '../ServiceAssistantTable/ServiceAssistantTable'

// ========================|| ASSISTANT - TABS - SERVICES ||======================== //

export default function ServiceAssistantTab() {
  const serviceId = useServiceId()
  const { t } = useTranslation()
  const { loading, error, ...state } = useServiceAssistants(serviceId)

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return (
    <ServiceAssistantsContext.Provider value={state}>
      <SubCard title={t('Assistants')} content={false}>
        <ServiceAssistantTable />
      </SubCard>
    </ServiceAssistantsContext.Provider>
  )
}
