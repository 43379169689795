// project imports
import CompaniesContext from '../../../context/company/CompaniesContext'
import useCompanies from '../../../hooks/company/useCompanies'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import CompanyCard from './CompanyCard/CompanyCard'

// ========================|| COMPANIES ||======================== //

export default function Companies() {
  const state = useCompanies()

  if (state.firstLoading) {
    return <Loader />
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <CompaniesContext.Provider value={state}>
      <CompanyCard />
    </CompaniesContext.Provider>
  )
}
