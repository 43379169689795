import { FormEvent, useContext, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Dayjs } from 'dayjs'

// project imports
import UserStatsContext from '../../../../context/userstats/UserStatsContext'
import UserBookingApi from '../../../../api/booking/UserBookingApi'
import useAuth from '../../../../context/auth/useAuth'
import BrowserStepper from '../CompanyBookingStepper/BrowserStepper'
import MobileStepper from '../CompanyBookingStepper/MobileStepper'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Branch } from '../../../../types/Branch'
import { Service } from '../../../../types/Service'
import { ServiceAssistant } from '../../../../types/ServiceAssistant'
import { Booking, BookingStatus } from '../../../../types/Booking'
import { ApiError } from '../../../../types/ApiError'
import steps, { BookingField } from '../stepper-items/stepperItems'
import { EXCEPTION_BOOKING_NOT_AVAILABLE } from '../../../../api/exceptions/exceptions'

// apis
const userBookingApi = new UserBookingApi()

// ========================|| COMPANY BOOKING - FORM ||======================== //

interface FormValues {
  branch: Branch
  service: Service
  assistant: ServiceAssistant
  date: Dayjs
  time: string
}

export default function CompanyBookingForm() {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(UserStatsContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  // react-hook-form
  const methods = useForm<FormValues>({
    mode: 'onChange',
  })

  // state
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = async () => {
    const fields = steps[activeStep].fields
    const isValid = await methods.trigger(fields as BookingField[])
    if (isValid) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSuccess = (booking: Booking) => {
    setStats({ ...stats, bookings: stats.bookings + 1 })

    if (booking.checkoutUrl) {
      window.open(booking.checkoutUrl, '_self')
      return
    }

    dispatch(
      setSnackbar({
        message:
          booking.status === BookingStatus.PENDING
            ? t('Your booking was created and is pending confirmation')
            : t('Your booking was created successfully'),
        severity: 'success',
        open: true,
      }),
    )

    navigate('/users/me/bookings')
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_BOOKING_NOT_AVAILABLE) {
      dispatch(
        setSnackbar({
          message: t('Ops, it seems that the chosen date is no longer available. Please go back and choose another one.'),
          severity: 'error',
          open: true,
        }),
      )
    } else {
      dispatch(
        setSnackbar({
          message: t('An unexpected error occurred while creating booking'),
          severity: 'error',
          open: true,
        }),
      )
    }
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    methods.handleSubmit(handleSubmitForm)(event);
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    if (form.service && form.assistant && form.time) {
      setLoading(true)
      auth!.getIdToken().then(token => {
        userBookingApi.create(token, {
          serviceId: form.service.id,
          assistantId: form.assistant.id,
          date: form.time
        })
          .then(handleSuccess)
          .catch(handleError)
          .finally(() => setLoading(false))
      })
    }
  }

  return (
    <Box component='form' onSubmit={onSubmit}>
      <FormProvider {...methods}>
        {/* Desktop view */}
        {!matchesXS && (
          <BrowserStepper
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            loading={loading}
          />
        )}
        {/* Mobile view */}
        {matchesXS && (
          <MobileStepper
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            loading={loading}
          />
        )}
      </FormProvider>
    </Box>
  )
}
