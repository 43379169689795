import { ReactNode } from 'react'

// project imports
import { Subscription } from '../../types/Subscription'
import SubscriptionContext from './SubscriptionContext'

interface Props {
  subscription: Subscription
  children: ReactNode
}

export default function SubscriptionProvider({ subscription, children }: Props): JSX.Element {
  return <SubscriptionContext.Provider value={{ subscription }}>{children}</SubscriptionContext.Provider>
}