// material-ui
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

// ==============================|| UI COMPONENTS - LOADER ||============================== //

export default function Loader() {
  return (
    <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1350 }}>
      <LinearProgress />
    </Box>
  )
}
