import { ReactNode } from 'react'

// project imports
import SettingsContext from '../../../context/settings/SettingsContext'
import useCompanyId from '../../../hooks/company/useCompanyId'
import useSettings from '../../../hooks/settings/useSettings'
import NotFound from '../../Maintenance/NotFound/NotFound'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| SETTINGS - PROVIDER ||======================== //

export default function SettingsProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { loading, error, settings, setSettings } = useSettings(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!settings) {
    return <ApiErrorAlert />
  }

  return <SettingsContext.Provider value={{ settings, setSettings }}>{children}</SettingsContext.Provider>
}
