import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { ApiError } from '../../types/ApiError'
import { Assistant, AssistantParams } from '../../types/Assistant'

// ========================|| CONTEXT - ASSISTANT ||======================== //

interface AssistantContextType {
  loading: boolean
  error?: ApiError
  assistants: Assistant[]
  params: AssistantParams
  setAssistants: Dispatch<SetStateAction<Assistant[]>>
  setParams: Dispatch<SetStateAction<AssistantParams>>
}

const AssistantsContext = createContext<AssistantContextType>({} as AssistantContextType)

export default AssistantsContext