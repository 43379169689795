// material-ui
import { Divider } from '@mui/material'

// project imports
import useCompanyId from '../../../hooks/company/useCompanyId'
import MenuGroup from '../../../layout/Menu/MenuGroup'
import MenuItem from '../../../layout/Menu/MenuItem'

// assets
import { IconBriefcase, IconBuildingStore, IconCalendarTime, IconMapPin, IconUser, IconUsers, IconUserStar } from '@tabler/icons-react'
import MenuCard from './MenuCard/MenuCard'

// ========================|| LAYOUT - MENU ||======================== //

export default function Menu() {
  const companyId = useCompanyId()

  return (
    <>
      <MenuGroup title='Workspace'>
        <MenuItem title='Bookings' url={`/companies/${companyId}/bookings`} icon={IconCalendarTime} />
        <MenuItem title='Clients' url={`/companies/${companyId}/clients`} icon={IconUserStar} />
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuGroup title='Menu'>
        <MenuItem title='Company' url={`/companies/${companyId}`} icon={IconBuildingStore} />
        <MenuItem title='Branches' url={`/companies/${companyId}/branches`} icon={IconMapPin} />
        <MenuItem title='Assistants' url={`/companies/${companyId}/assistants`} icon={IconUser} />
        <MenuItem title='Services' url={`/companies/${companyId}/services`} icon={IconBriefcase} />
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuGroup title='Admin'>
        <MenuItem title='Users' url={`/companies/${companyId}/users`} icon={IconUsers} />
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuCard />
    </>
  )
}
