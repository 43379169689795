// third-party
import { Navigate, Outlet } from 'react-router-dom'

// project imports
import useAuth from '../../context/auth/useAuth'

// ========================|| GUARD - GUEST ||======================== //

export default function GuestGuard() {
  const { auth } = useAuth()

  if (auth) {
   return <Navigate to='/users/me/bookings' replace />
  }

  return <Outlet />
}
