import { createContext, Dispatch, SetStateAction } from 'react'
import { UserBranch } from '../../types/UserBranch'

// ========================|| CONTEXT - USER BRANCH ||======================== //

interface UserBranchContextType {
  userBranch: UserBranch
  setUserBranch: Dispatch<SetStateAction<UserBranch | undefined>>
}

const UserBranchContext = createContext<UserBranchContextType>({} as UserBranchContextType)

export default UserBranchContext
