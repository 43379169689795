// material-ui
import { styled, useTheme } from '@mui/material/styles'
import { Avatar, Badge } from '@mui/material'
import { IconBuildingStore } from '@tabler/icons-react'

// style
const OnlineBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

// ========================|| COMPANY - ONLINE AVATAR ||======================== //

const CompanyOnlineAvatar = () => {
  const theme = useTheme()

  return (
    <OnlineBadge overlap='circular' variant='dot' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Avatar
        sx={{
          width: 40,
          height: 40,
          backgroundColor: theme.palette.primary.light,
          border: `1px solid ${theme.palette.primary[200] + 75}`,
        }}
      >
        <IconBuildingStore strokeWidth={1.2} width={25} height={25} color={theme.palette.primary[200]} />
      </Avatar>
    </OnlineBadge>
  )
}

// ========================|| COMPANY - OFFLINE AVATAR ||======================== //

const CompanyOfflineAvatar = () => {
  const theme = useTheme()

  return (
    <Badge overlap='circular' color='error' variant='dot' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Avatar
        sx={{
          width: 40,
          height: 40,
          backgroundColor: theme.palette.primary.light,
          border: `1px solid ${theme.palette.primary[200] + 75}`,
        }}
      >
        <IconBuildingStore strokeWidth={1.2} width={30} height={30} color={theme.palette.primary[200]} />
      </Avatar>
    </Badge>
  )
}

// ========================|| COMPANY - AVATAR ||======================== //

interface Props {
  active: boolean
}

export default function CompanyAvatar({ active }: Props) {
  if (active) {
    return <CompanyOnlineAvatar />
  } else {
    return <CompanyOfflineAvatar />
  }
}