import { useEffect } from 'react'

// third-party
import { useDispatch } from 'react-redux'

// project imports
import BranchContext from '../../../context/branch/BranchContext'
import useCompanyId from '../../../hooks/company/useCompanyId'
import useBranchId from '../../../hooks/branch/useBranchId'
import useBranch from '../../../hooks/branch/useBranch'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import BranchCard from './BranchCard/BranchCard'
import { setIsOpen } from '../../../store/menu/reducer'

// ========================|| BRANCH ||======================== //

export default function Branch() {
  const companyId = useCompanyId()
  const branchId = useBranchId()
  const dispatch = useDispatch()
  const { loading, error, branch, setBranch } = useBranch(branchId)

  useEffect(() => {
    dispatch(setIsOpen(`/companies/${companyId}/branches`))
  }, [])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!branch) {
    return <ApiErrorAlert />
  }

  return (
    <BranchContext.Provider value={{ branch, setBranch }}>
      <BranchCard />
    </BranchContext.Provider>
  )
}
