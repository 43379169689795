// third-party
import { Outlet } from 'react-router-dom'

// project imports
import useBreadcrumbs from '../../../hooks/company/useBreadcrumbs'
import useTooltip from '../../../hooks/company/useTooltip'
import MainCard from '../../../ui-components/extended/card/MainCard'
import Breadcrumbs from '../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import CompanyTabs from './CompanyTabs'

// ========================|| COMPANY ||======================== //

export default function Company() {
  const items = useBreadcrumbs()
  const tooltip = useTooltip()

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} border={false}>
      <CompanyTabs />
      <Outlet />
    </MainCard>
  )
}
