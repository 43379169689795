// project imports
import ProfileProvider from './ProfileProvider'
import ProfileCard from './ProfileCard/ProfileCard'

// ========================|| PROFILE ||======================== //

export default function Profile() {
  return (
    <ProfileProvider>
      <ProfileCard />
    </ProfileProvider>
  )
}
