import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Booking, BookingParams } from '../../types/Booking'
import { Page } from '../../types/Page'
import { Layout } from '../../types/Layout'
import { ApiError } from '../../types/ApiError'

// ========================|| CONTEXT - BOOKING ||======================== //

interface BookingContextType {
  loading: boolean
  error?: ApiError
  bookings: Page<Booking>
  params: BookingParams
  setBookings: Dispatch<SetStateAction<Page<Booking>>>
  setParams: Dispatch<SetStateAction<BookingParams>>
  layout: Layout
}

const BookingsContext = createContext<BookingContextType>({} as BookingContextType)

export default BookingsContext