// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Typography,
} from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { getPriceString } from '../../../../utils/typography'

interface Props {
  title: string
  description: string
  price: number
  features: { name: string; included: boolean }[]
  buttonText: string
  buttonVariant: string
  link: string
}

export default function PriceCard(props: Props) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [assistants, setAssistants] = useState(1)

  const basePrice = 10000
  const aditionals = 2000

  const price = useMemo(() => basePrice + (assistants - 1) * aditionals, [assistants])

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setAssistants(newValue)
    }
  }

  return (
    <Card
      variant='outlined'
      sx={{
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: `${theme.shape.borderRadius}px`,
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h2' textAlign='center'>
              {t(props.title)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component='p' variant='body2' textAlign='center' color='text.primary'>
              {t(props.description)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List disablePadding>
              {props.features.map(feature => (
                <ListItem key={feature.name} disabled={!feature.included} disableGutters>
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: feature.included ? theme.palette.warning.main : theme.palette.grey[300],
                      }}
                    >
                      <CheckIcon sx={{ fontSize: '0.75rem' }} />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color='text.primary'>{t(feature.name)}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box mb={1}>
              <Typography variant='subtitle1' gutterBottom>
                Seleccioná la cantidad de asistentes
              </Typography>
              <Typography variant='body1'>Los asistentes son aquellos que requieren una agenda de turnos.</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Typography mr={3}>1</Typography>
              <Slider value={assistants} min={1} step={1} max={50} onChange={handleChange} valueLabelDisplay='auto' />
              <Typography ml={3}>50</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h1' textAlign='center'>
              {getPriceString(price, 'ARS', 'es-AR', 0)}
            </Typography>
            <Typography variant='h5' color='text.primary' textAlign='center'>
              {t('Finales por mes')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          p: theme.spacing(4),
          pt: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button href={props.link} variant={props.buttonVariant as 'outlined' | 'contained'} disableElevation>
          {t(props.buttonText)}
        </Button>
      </CardActions>
    </Card>
  )
}
