import { useContext } from 'react'

// material-ui
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingsContext from '../../../../../context/booking/BookingsContext'
import { BookingStatus } from '../../../../../types/Booking'

// ========================|| STATUS FILTER ||======================== //

export default function BookingStatusFilter() {
  const { t } = useTranslation()
  const { params, setParams } = useContext(BookingsContext)

  const handleChange = (bookingStatus: BookingStatus) => {
    let status = params.status

    if (status.includes(bookingStatus)) {
      status = status.filter(status => status !== bookingStatus)
    } else {
      status = status.concat(bookingStatus)
    }

    setParams(filter => ({ ...filter, status, page: 0 }))
  }

  return (
    <FormControl component='fieldset' variant='standard'>
      <FormLabel component='legend'>
        <Typography variant='subtitle1' mb={1}>{t('Status')}</Typography>
      </FormLabel>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox id='pending' />}
          checked={params.status.includes(BookingStatus.PENDING)}
          onChange={() => handleChange(BookingStatus.PENDING)}
          label={t('Pending')}
        />
        <FormControlLabel
          control={<Checkbox id='confirmed' />}
          checked={params.status.includes(BookingStatus.CONFIRMED)}
          onChange={() => handleChange(BookingStatus.CONFIRMED)}
          label={t('Confirmed')}
        />
        <FormControlLabel
          control={<Checkbox id='canceld' />}
          checked={params.status.includes(BookingStatus.CANCELED)}
          onChange={() => handleChange(BookingStatus.CANCELED)}
          label={t('Canceled')}
        />
      </FormGroup>
    </FormControl>
  )
}
