// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import ProfileForm from '../ProfileForm/ProfileForm'

// ========================|| PROFILE - CARD ||======================== //

export default function ProfileCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Profile')}>
      <ProfileForm />
    </SubCard>
  )
}
