// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { Booking } from '../../../../types/Booking'

// ========================|| BOOKING - DEPOSIT DIALOG ||======================== //

interface Props extends DialogProps {
  booking: Booking
  onClose: any
}

export default function BookingDepositDialog({ booking, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()

  const handleDeposit = () => {
    if (booking.checkoutUrl) {
      window.open(booking.checkoutUrl, '_self')
      onClose()
    }
  }

  return (
    <Dialog aria-labelledby='booking-deposit-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='deposit-dialog'>{t('You will be redirected')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('Click continue to be re-directed to Mercado Pago website to pay the deposit.')}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='primary' disableElevation onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='primary' disableElevation onClick={handleDeposit}>
          {t('Continue')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
