import { ReactNode } from 'react'

// project imports
import CompanyUsersContext from './CompanyUsersContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useCompanyUsers from '../../hooks/usercompany/useCompanyUsers'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - COMPANY USERS ||======================== //

export default function CompanyUsersProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { firstLoading, loading, error, companyUsers, params, setCompanyUsers, setParams } = useCompanyUsers(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (firstLoading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return <CompanyUsersContext.Provider value={{ loading, companyUsers, params, setCompanyUsers, setParams }}>{children}</CompanyUsersContext.Provider>
}