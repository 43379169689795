// project imports
import { axiosInstance } from '../../axios'
import { BusinessHourAdjusted } from '../../types/BusinessHour'
import { BusinessHoursAdjustedUpdateDto } from './dto/BusinessHoursAdjustedUpdateDto'

// ========================|| API - BUSINESS HOURS ADJUSTED - ASSISTANT ||======================== //

export default class AssistantBusinessHoursAdjustedApi {
  findByAssistantId(token: string, assistantId: string): Promise<BusinessHourAdjusted[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/assistants/${assistantId}/business-hours-adjusted`, config)
  }

  update(token: string, assistantId: string, businessHoursAdjusted: BusinessHoursAdjustedUpdateDto): Promise<BusinessHourAdjusted[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/assistants/${assistantId}/business-hours-adjusted`, businessHoursAdjusted, config)
  }
}
