import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { ServiceAssistant, ServiceAssistantParams } from '../../types/ServiceAssistant'

// ========================|| CONTEXT - SERVICE ASSISTANTS ||======================== //

interface ServiceAssistantsContextType {
  serviceAssistants: ServiceAssistant[]
  params: ServiceAssistantParams
  setServiceAssistants: Dispatch<SetStateAction<ServiceAssistant[]>>
  setParams: Dispatch<SetStateAction<ServiceAssistantParams>>
}

const ServiceAssistantsContext = createContext<ServiceAssistantsContextType>({} as ServiceAssistantsContextType)

export default ServiceAssistantsContext