export interface Page<T> {
  content: T[]
  page: {
    size: number,
    number: number,
    totalElements: number
    totalPages: number
  }
}

export interface Pageable {
  page: number
  size: number
  sort?: string
}

export interface Searchable {
  search?: string
}

export const DEFAULT_PAGE = {
  content: [],
  page: {
    size: 0,
    number: 0,
    totalElements: 0,
    totalPages: 0,
  }
}

export const DEFAULT_PARAMS = {
  page: 0,
  size: 5,
}
