import { useState } from 'react'

// material-ui
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { ServiceDetails } from '../../../../types/Service'
import ServiceEditDialog from '../ServiceDeleteDialog/ServiceDeleteDialog'

// ========================|| SERVICE - DELETE BUTTON ||======================== //

interface Props {
  service: ServiceDetails
}

export default function ServiceDeleteButton({ service }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button variant='outlined' color='error' size='small' fullWidth startIcon={<DeleteIcon fontSize='small' />} onClick={handleOpen}>
        {t('Delete')}
      </Button>
      <ServiceEditDialog open={open} service={service} onClose={handleClose} />
    </>
  )
}
