// material-ui
import { styled } from '@mui/material/styles'
import { alpha, Switch } from '@mui/material'

// ==============================|| UI COMPONENTS - SWITCH - GREEN SWITCH ||============================== //

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.success.dark,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.dark, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.success.dark,
  },
}))

export default GreenSwitch
