import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Settings } from '../../types/Settings'

// ========================|| CONTEXT - SETTINGS ||======================== //

interface SettingsContextType {
  settings: Settings
  setSettings: Dispatch<SetStateAction<Settings | undefined>>
}

const SettingsContext = createContext<SettingsContextType>({} as SettingsContextType)

export default SettingsContext