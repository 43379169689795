import { axiosInstance } from '../../axios'
import { CompanyStats } from '../../types/CompanyStats'

// ========================|| API - COMPANY STATS ||======================== //

export default class CompanyStatsApi {
  findById(token: string, companyId: string): Promise<CompanyStats> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/stats`, config)
  }
}
