import { useContext } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Button, CardActions, Step, StepLabel, Stepper } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

// project imports
import ClientContext from '../../../../context/client/ClientContext'
import { StepperItem } from '../stepper-items/stepperItems'
import { Service } from '../../../../types/Service'
import { ServiceAssistant } from '../../../../types/ServiceAssistant'
import { isDepositRequired } from '../../../../utils/serviceAssistant'

// ========================|| BOOKING FORM - BROWSER STEPPER ||======================== //

interface Props {
  steps: StepperItem[]
  activeStep: number
  handleNext: () => void
  handleBack: () => void
  loading: boolean
}

export default function BrowserStepper({ steps, activeStep, handleNext, handleBack, loading }: Props) {
  // hooks
  const theme = useTheme()
  const { t } = useTranslation()
  const { client } = useContext(ClientContext)
  const { formState, watch } = useFormContext()

  const isStepFailed = (fields: string[]) => {
    return fields.some(field => Object.keys(formState.errors).includes(field))
  }

  const confirmationLabel = (): string => {
    const service: Service = watch('service')
    const assistant: ServiceAssistant = watch('assistant')

    if (isDepositRequired(client, service, assistant)) {
      return 'Confirm and pay'
    } else {
      return 'Confirm'
    }
  }

  return (
    <>
      <Stepper activeStep={activeStep} sx={{ p: '24px 0 40px', overflow: 'hidden' }}>
        {steps.map(item => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: { error?: boolean } = {}

          if (isStepFailed(item.fields)) {
            labelProps.error = true
          }

          return (
            <Step key={item.id} {...stepProps}>
              <StepLabel {...labelProps}>{t(item.title)}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {steps[activeStep].component}
      <CardActions
        sx={{
          [theme.breakpoints.up('xs')]: { padding: '16px 0' },
          [theme.breakpoints.up('sm')]: { padding: '24px 0' },
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button color='primary' disabled={activeStep === 0} onClick={handleBack}>
            {t('Back')}
          </Button>
          {activeStep === steps.length - 1 && (
            <LoadingButton type='submit' variant='contained' loading={loading} disableElevation>
              {t(confirmationLabel())}
            </LoadingButton>
          )}
          {activeStep !== steps.length - 1 && (
            <Button variant='contained' disableElevation onClick={handleNext}>
              {t('Next')}
            </Button>
          )}
        </Box>
      </CardActions>
    </>
  )
}
