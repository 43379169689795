import { axiosInstance } from '../../axios'
import { UserStats } from '../../types/UserStats'

// ========================|| API - USER STATS ||======================== //

export default class UserStatsApi {
  findById(token: string): Promise<UserStats> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get('/api/v1/users/me/stats', config)
  }
}
