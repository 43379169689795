import { Fragment, useContext } from 'react'

// material-ui
import { CardContent, Divider, Grid, Pagination, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BranchesContext from '../../../../context/branch/BranchesContext'
import useListPagination from '../../../../hooks/pagination/useListPagination'
import BranchListItem from '../BranchListItem/BranchListItem'
import { Branch } from '../../../../types/Branch'

// ========================|| BRANCH - LIST ||======================== //

export default function BranchList() {
  // hooks
  const { t } = useTranslation()
  const { loading, branches, params } = useContext(BranchesContext)
  const { count, rows, page, handleChange } = useListPagination(branches)

  if (loading) {
    return null;
  }

  if (count === 0) {
    if (params.search && params.search.length > 0) {
      return (
        <CardContent sx={{ padding: 3 }}>
          <Typography>{t('No branches found.')}</Typography>
        </CardContent>
      )
    }

    return (
      <CardContent sx={{ padding: 3 }}>
        <Typography>{t("You don't have branches created yet.")}</Typography>
      </CardContent>
    )
  }

  return (
    <CardContent sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {rows.map((branch: Branch) => (
          <Fragment key={branch.id}>
            <Grid item xs={12}>
              <BranchListItem branch={branch} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={12} display='flex' justifyContent='center'>
          <Pagination count={count} page={page} onChange={handleChange} />
        </Grid>
      </Grid>
    </CardContent>
  )
}
