import React, { useEffect, useState } from 'react'

// ========================|| HOOK - PAGINATION ||======================== //

const usePagination = (elements: any[]) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  useEffect(() => setPage(0), [elements])

  const rows = rowsPerPage > 0 ? elements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : elements

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return { rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }
}

export default usePagination
