import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import PlanApi from '../../api/plan/PlanApi'
import { ApiError } from '../../types/ApiError'
import { Plan } from '../../types/Plan'

// apis
const planApi = new PlanApi()

// ========================|| HOOK - PLAN ||======================== //

const usePlan = (country: string) => {
  const { auth } = useAuth()
  const [plan, setPlan] = useState<Plan>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchPlan = () => {
    auth!.getIdToken().then(token => {
      planApi
        .findByCountry(token, country)
        .then(data => setPlan(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchPlan, [country])

  return { loading, error, plan, setPlan }
}

export default usePlan
