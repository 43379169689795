import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserCompanyContext from '../../context/usercompany/UserCompanyContext'
import ServiceContext from '../../context/service/ServiceContext'

// ========================|| HOOK - SERVICE BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { userCompany } = useContext(UserCompanyContext)
  const { service } = useContext(ServiceContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const servicesUrl = { title: t('Services'), url: `/companies/${userCompany.id}/services` }
  const serviceUrl = { title: service.name, url: `/companies/${userCompany.id}/services/${service.id}` }

  const assistants = { title: t('Assistants') }
  const serviceName = { title: service.name }

  switch (pathname.split('/').at(-1)) {
    case 'assistants': {
      return [servicesUrl, serviceUrl, assistants]
    }
    default: {
      return [servicesUrl, serviceName]
    }
  }
}

export default useBreadcrumbs
