// project imports
import CompanyUsersProvider from '../../../context/usercompany/CompanyUsersProvider'
import CompanyUserCard from './CompanyUserCard/CompanyUserCard'

// ========================|| COMPANY USER ||======================== //

export default function CompanyUsers() {
  return (
    <CompanyUsersProvider>
      <CompanyUserCard />
    </CompanyUsersProvider>
  )
}
