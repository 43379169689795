// material-ui
import { Chip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { UserCompanyRole } from '../../../../types/UserCompanyRole'

// ========================|| COMPANY USER - ROLE CHIP ||======================== //

interface Props {
  role: UserCompanyRole
}

export default function CompanyUserRoleChip({ role }: Props) {
  const { t } = useTranslation()

  switch (role) {
    case UserCompanyRole.OWNER:
      return <Chip label={t('Owner')} size='small' color='info' variant='outlined' />
    case UserCompanyRole.ADMIN:
      return <Chip label={t('Admin')} size='small' color='primary' />
    default:
      return <Chip label={t('Unknown')} size='small' color='error' />
  }
}
