import { useState } from 'react'

// material-ui
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import AssistantDeleteDialog from '../AssistantDeleteDialog/AssistantDeleteDialog'
import { Assistant } from '../../../../types/Assistant'

// ========================|| ASSISTANT - DELETE BUTTON ||======================== //

interface Props {
  assistant: Assistant
}

export default function AssistantDeleteButton({ assistant }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button variant='outlined' color='error' size='small' fullWidth startIcon={<DeleteIcon fontSize='small' />} onClick={handleOpen}>
        {t('Delete')}
      </Button>
      <AssistantDeleteDialog open={open} assistant={assistant} onClose={handleClose} />
    </>
  )
}
