import { useState } from 'react'

// material-ui
import { Button } from '@mui/material'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingConfirmDialog from '../BookingConfirmDialog/BookingConfirmDialog'
import { Booking, BookingStatus } from '../../../../types/Booking'

// ========================|| BOOKING - CONFIRM BUTTON ||======================== //

interface Props {
  booking: Booking
}

export default function BookingConfirmButton({ booking }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const isNotPending = booking.status !== BookingStatus.PENDING

  const isExpired = new Date(booking.date).getTime() < new Date().getTime()

  const isDepositEnabled = booking.depositAmount > 0

  const isDisabled = isNotPending || isExpired || isDepositEnabled

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        size='small'
        fullWidth
        startIcon={<CheckCircleTwoToneIcon fontSize='small' />}
        onClick={handleOpen}
        disabled={isDisabled}
      >
        {t('Confirm')}
      </Button>
      <BookingConfirmDialog open={open} booking={booking} onClose={handleClose} />
    </>
  )
}
