import { useContext } from 'react'

// material-ui
import { Grid } from '@mui/material'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'
import PaymentMethodCard from '../PaymentMethodCard/PaymentMethodCard'
import SubscriptionUpdateCard from '../SubscriptionUpdateCard/SubscriptionUpdateCard'
import SubscriptionFreeTrialAlert from '../SubscriptionFreeTrialAlert/SubscriptionFreeTrialAlert'
import { isPendingAndHasFreeTrialReminingDays } from '../../../../utils/subscription'

// ========================|| SUBSCRIPTION - CARD ||======================== //

export default function SubscriptionCard() {
  const { subscription } = useContext(SubscriptionContext)

  return (
    <Grid container spacing={3}>
      {isPendingAndHasFreeTrialReminingDays(subscription) && (
        <Grid item xs={12}>
          <SubscriptionFreeTrialAlert freeTrialEndDate={subscription.freeTrialEndDate!} />
        </Grid>
      )}
      <Grid item xs={12}>
        <SubscriptionUpdateCard />
      </Grid>
      <Grid item xs={12}>
        <PaymentMethodCard />
      </Grid>
    </Grid>
  )
}
