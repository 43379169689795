// project imports
import { axiosInstance } from '../../axios'
import { User } from '../../types/User'
import { UserCreateDto } from './dto/UserCreateDto'
import { UserUpdateDto } from './dto/UserUpdateDto'

// ========================|| API - USER ||======================== //

export default class UserApi {
  findAll(token: string, search: string): Promise<User[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { search }
    }

    return axiosInstance.get('/api/v1/users', config)
  }

  findMe(token: string): Promise<User> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get('/api/v1/users/me', config)
  }

  create(token: string, user: UserCreateDto): Promise<User> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post('/api/v1/users', user, config)
  }

  update(token: string, user: UserUpdateDto): Promise<User> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put('/api/v1/users/me', user, config)
  }
}
