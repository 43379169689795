import { useContext } from 'react'

// project imports
import CompanyContext from '../../../../context/company/CompanyContext'
import MainCard from '../../../../ui-components/extended/card/MainCard'
import CompanyBookingForm from '../CompanyBookingForm/CompanyBookingForm'
import CompanyOfflineAlert from '../CompanyOfflineAlert/CompanyOfflineAlert'

// ========================|| COMPANY BOOKING - CARD ||======================== //

export default function CompanyCard() {
  const { company } = useContext(CompanyContext)

  if (!company.active) {
    return <CompanyOfflineAlert />
  }

  return (
    <MainCard title={company.displayName} border={false}>
      <CompanyBookingForm />
    </MainCard>
  )
}