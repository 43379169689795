import { ReactNode } from 'react'

// project imports
import BranchesContext from './BranchesContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useBranches from '../../hooks/branch/useBranches'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - BRANCH ||======================== //

export default function BranchesProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { firstLoading, loading, error, branches, params, setBranches, setParams } = useBranches(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (firstLoading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return <BranchesContext.Provider value={{ loading, error, branches, params, setBranches, setParams }}>{children}</BranchesContext.Provider>
}