// material-ui
import { DialogContentText } from '@mui/material'

// third-party
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// project imports
import { Booking, BookingStatus } from '../../../../../types/Booking'
import { locale } from '../../../../../utils/locale'

// ========================|| BOOKING - CANCEL DIALOG TEXT ||======================== //

interface Props {
  booking: Booking
}

export default function BookingCancelDialogText({ booking }: Props) {
  const { i18n, t } = useTranslation()

  const refundEnabled = !!booking.refundDeadline
  const depositEnabled = booking.depositAmount > 0

  const DepositNotRefundableDialogContentText = () => (
    <DialogContentText id='cancel-booking-dialog-description'>
      {t('If you cancel the booking you will lose the deposit. Do you want to cancel?')}
    </DialogContentText>
  )

  if (booking.status === BookingStatus.PENDING || !depositEnabled) {
    return (
      <DialogContentText id='cancel-booking-dialog-description'>
        {t('Do you want to cancel the booking?')}
      </DialogContentText>
    )
  }

  const isNowBeforeRefundDeadline = dayjs().isBefore(dayjs(booking.refundDeadline))

  if (refundEnabled && isNowBeforeRefundDeadline) {
    const refundDeadline = dayjs(booking.refundDeadline)
      .locale(locale(i18n.language))
      .format('LLLL')
      .toLowerCase()

    return (
      <DialogContentText id='cancel-booking-dialog-description'>
        <Trans t={t} i18nKey='booking_cancelationPolicy' refundDeadline={refundDeadline}>
          The booking is cancelable until {{ refundDeadline }}. Do you want to cancel?
        </Trans>
      </DialogContentText>
    )
  }

  return <DepositNotRefundableDialogContentText />
}
