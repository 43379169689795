// material-ui
import { ButtonProps, IconButton, Tooltip } from '@mui/material'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// ========================|| BUSINESS HOURS ADJUSTED - DELETE BUTTON ||======================== //

export default function BusinessHourAdjustedDeleteButton(props: ButtonProps) {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('Delete this day')} placement='top' enterDelay={400} enterNextDelay={400}>
      <span>
        <IconButton {...props} aria-label='delete' color='secondary' size='medium'>
          <HighlightOffTwoToneIcon fontSize='small' />
        </IconButton>
      </span>
    </Tooltip>
  )
}