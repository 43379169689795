// project imports
import ElevateAppBar from './AppBar/ElevateAppBar'
import Main from './Main/Main'
import Features from './Features/Features'
import Pricing from './Pricing/Pricing'
import Help from './Help/Help'
import Contact from './Contact/Contact'
import Footer from './Footer/Footer'

// ========================|| LANDING ||======================== //

export default function Landing() {
  return (
    <>
      <ElevateAppBar />
      <Main />
      <Features />
      <Pricing />
      <Help />
      <Contact />
      <Footer />
    </>
  )
}
