import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserCompanyContext from '../../context/usercompany/UserCompanyContext'
import AssistantContext from '../../context/assistant/AssistantContext'

// ========================|| HOOK - ASSISTANT BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { userCompany } = useContext(UserCompanyContext)
  const { assistant } = useContext(AssistantContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const assistantsUrl = { title: t('Assistants'), url: `/companies/${userCompany.id}/assistants` }
  const assistantUrl = { title: assistant.name, url: `/companies/${userCompany.id}/assistants/${assistant.id}` }

  const businessHours = { title: t('Business Hours') }
  const services = { title: t('Services') }
  const users = { title: t('Users') }
  const assistantName = { title: assistant.name }

  switch (pathname.split('/').at(-1)) {
    case 'business-hours': {
      return [assistantsUrl, assistantUrl, businessHours]
    }
    case 'services': {
      return [assistantsUrl, assistantUrl, services]
    }
    case 'users': {
      return [assistantsUrl, assistantUrl, users]
    }
    default: {
      return [assistantsUrl, assistantName]
    }
  }
}

export default useBreadcrumbs
