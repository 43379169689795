import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import AssistantUserApi from '../../../../api/userassistant/AssistantUserApi'
import AssistantUsersContext from '../../../../context/userassistant/AssistantUsersContext'
import useAuth from '../../../../context/auth/useAuth'
import useAssistantId from '../../../../hooks/assistant/useAssistantId'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import AssistantUserCreateForm from '../AssistantUserCreateForm/AssistantUserCreateForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { AssistantUser } from '../../../../types/AssistantUser'
import { UserAssistantRole } from '../../../../types/UserAssistantRole'
import { ApiError } from '../../../../types/ApiError'
import { EXCEPTION_USER_ASSISTANT_ALREADY_EXIST, EXCEPTION_USER_NOT_FOUND } from '../../../../api/exceptions/exceptions'

// apis
const assistantUserApi = new AssistantUserApi()

// ========================|| ASSISTANT USER - CREATE DIALOG ||======================== //

interface FormValues {
  email: string
  role: UserAssistantRole
}

interface Props extends DialogProps {
  onClose: () => void
}

export default function AssistantUserCreateDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setAssistantUsers } = useContext(AssistantUsersContext)
  const assistantId = useAssistantId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      role:  UserAssistantRole.VIEWER,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (assistantUser: AssistantUser) => {
    setAssistantUsers(assistantUsers => ({ ...assistantUsers, content: assistantUsers.content.concat(assistantUser) }))

    dispatch(
      setSnackbar({
        message: t('User added successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_USER_NOT_FOUND) {
      methods.setError('email', { message: EXCEPTION_USER_NOT_FOUND })
      return
    }

    if (error.message === EXCEPTION_USER_ASSISTANT_ALREADY_EXIST) {
      methods.setError('email', { message: 'The user is already associated with the assistant.' })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while inviting user'),
        severity: 'error',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      assistantUserApi.create(token, assistantId, {
        email: form.email,
        role: form.role,
      })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Invite User')}</DialogTitle>
          <DialogContent>
            <AssistantUserCreateForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LoadingButton type='submit' variant='contained' size='large' loading={loading} fullWidth disableElevation>
                  {t('Invite')}
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
