import { ReactNode } from 'react'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'

// ==============================|| CUSTOM INTEGRATION CARD ||============================== //

interface Props {
  children: ReactNode
}

export default function IntegrationCard({ children }: Props) {
  return (
    <SubCard sx={{ height: '100%' }} contentSX={{ display: 'flex', height: '100%', '&:last-child': { pb: '20px' } }}>
      {children}
    </SubCard>
  )
}