// project imports
import AssistantsProvider from '../../../context/assistant/AssistantsProvider'
import AssistantCard from './AssistantCard/AssistantCard'

// ========================|| ASSISTANTS ||======================== //

export default function Assistants() {
  return (
    <AssistantsProvider>
      <AssistantCard />
    </AssistantsProvider>
  )
}
