// project imports
import SettingsProvider from './SettingsProvider'
import SettingsCard from './SettingsCard/SettingsCard'

// ========================|| SETTINGS ||======================== //

export default function Settings() {
  return (
    <SettingsProvider>
      <SettingsCard />
    </SettingsProvider>
  )
}
