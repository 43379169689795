// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import BranchBusinessHoursForm from '../BranchBusinessHoursForm/BranchBusinessHoursForm'

// ========================|| BRANCH TABS - BUSINESS HOURS CARD ||======================== //

export default function BranchBusinessHoursCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Business Hours')} contentSX={{ overflowX: 'scroll' }}>
      <BranchBusinessHoursForm />
    </SubCard>
  )
}
