import React, { ReactNode, Ref } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material'

// ==============================|| CUSTOM SUB CARD ||============================== //

export interface SubCardProps {
  children: ReactNode
  darkTitle?: boolean
  secondary?: ReactNode
  sx?: {}
  headerSX?: {}
  contentSX?: {}
  content?: boolean
  title?: ReactNode
  border?: boolean
  divider?: boolean
}

const SubCard = React.forwardRef(
  (
    { children, content = true, darkTitle, secondary, sx, headerSX, contentSX, title, border = true, divider = true, ...others }: SubCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme()

    return (
      <Card
        ref={ref}
        elevation={0}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.divider,
          ':hover': {
            boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
          },
          ...sx,
        }}
        {...others}
      >
        {/* card header and action */}
        {title && (
          <CardHeader
            sx={{ p: 2.5, ...headerSX }}
            title={<Typography variant={darkTitle ? 'h4' : 'h5'}>{title}</Typography>}
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title && divider && <Divider sx={{ opacity: 1, borderColor: theme.palette.divider }} />}

        {/* card content */}
        {content && <CardContent sx={{ p: 2.5, ...contentSX }}>{children}</CardContent>}
        {!content && children}
      </Card>
    )
  },
)

export default SubCard
