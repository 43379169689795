// material-ui
import { Chip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { UserAssistantRole } from '../../../../types/UserAssistantRole'

// ========================|| ASSISTANT USER - ROLE CHIP ||======================== //

interface Props {
  role: UserAssistantRole
}

export default function AssistantUserRoleChip({ role }: Props) {
  const { t } = useTranslation()

  switch (role) {
    case UserAssistantRole.EDITOR:
      return <Chip label={t('Editor')} size='small' color='primary' />
    case UserAssistantRole.VIEWER:
      return <Chip label={t('Viewer')} size='small' color='secondary' />
    default:
      return <Chip label={t('Unknown')} size='small' color='error' />
  }
}
