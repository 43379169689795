import { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import UserStatsContext from '../../../../context/userstats/UserStatsContext'
import UserBookingApi from '../../../../api/booking/UserBookingApi'
import useAuth from '../../../../context/auth/useAuth'
import BookingCancelDialogText from './BookingCancelDialogText/BookingCancelDialogText'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Booking, BookingStatus } from '../../../../types/Booking'
import { ApiError } from '../../../../types/ApiError'
import { EXCEPTION_BOOKING_NOT_CANCELABLE } from '../../../../api/exceptions/exceptions'

// apis
const bookingApi = new UserBookingApi()

// ========================|| BOOKING - CANCEL DIALOG ||======================== //

interface Props extends DialogProps {
  booking: Booking
  onClose: any
}

export default function BookingCancelDialog({ booking, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setBookings } = useContext(BookingsContext)
  const { stats, setStats } = useContext(UserStatsContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (canceled: Booking) => {
    setBookings(bookings => ({
      ...bookings,
      content: bookings.content
        .map(booking => (booking.id === canceled.id ? canceled : booking))
        .filter(booking => booking.status !== BookingStatus.CANCELED),
    }))

    setStats({ ...stats, bookings: stats.bookings - 1 })

    dispatch(
      setSnackbar({
        message: t('Booking canceled successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_BOOKING_NOT_CANCELABLE) {
      dispatch(
        setSnackbar({
          message: t('The booking is no longer cancelable'),
          severity: 'error',
          open: true,
        }),
      )
    } else {
      dispatch(
        setSnackbar({
          message: t('An unexpected error occurred while canceling booking'),
          severity: 'error',
          open: true,
        }),
      )
    }
  }

  const handleCancel = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      bookingApi
        .cancel(token, booking.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          onClose()
          setLoading(false)
        })
    })
  }

  return (
    <Dialog aria-labelledby='cancel-booking-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='cancel-booking-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <BookingCancelDialogText booking={booking} />
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='contained' color='error' disableElevation onClick={() => onClose()}>
          {t('Not now')}
        </Button>
        <LoadingButton variant='outlined' color='error' loading={loading} disableElevation onClick={handleCancel}>
          {t('Yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
