// material-ui
import { Typography } from '@mui/material'

// third-party
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

// ========================|| CHECKOUT STEP - REFUND POLICY ||======================== //

interface Props {
  bookingDate: string
  refundAnticipationTime: number
}

export default function RefundPolicyTypography({ bookingDate, refundAnticipationTime }: Props) {
  const { t } = useTranslation()

  const diff = dayjs(bookingDate)
    .subtract(refundAnticipationTime, 'minutes')
    .diff(dayjs(), 'minutes')

  const refundPolicy = () => {
    if (diff < 0) {
      return t('The deposit will not be refunded if you decide to cancel the booking')
    }

    if (refundAnticipationTime < 60) {
      return t('The deposit will be refunded if you cancel up to {{anticipation}} minutes before the booking', { anticipation: refundAnticipationTime })
    }

    if (refundAnticipationTime === 60) {
      return t('The deposit will be refunded if you cancel up to 1 hour before the booking')
    }

    if (refundAnticipationTime < 1440) {
      return t('The deposit will be refunded if you cancel up to {{anticipation}} hours before the booking', { anticipation: refundAnticipationTime / 60 })
    }

    if (refundAnticipationTime === 1440) {
      return t('The deposit will be refunded if you cancel up to 1 day before the booking')
    }

    return t('The deposit will be refunded if you cancel up to {{anticipation}} days before the booking', { anticipation: refundAnticipationTime / 1440 })
  }

  return (
    <Typography component='p' variant='body1'>
      {refundPolicy()}
    </Typography>
  )
}