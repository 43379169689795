// material-ui
import { useTheme } from '@mui/material/styles'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

// ==============================|| UI COMPONENTS - TEXT FIELD - SEARCH BAR ||============================== //

const SearchBar = (props: TextFieldProps) => {
  const theme = useTheme()

  return (
    <TextField
      {...props}
      id='search-bar'
      type='search'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon fontSize='small' />
          </InputAdornment>
        ),
      }}
      sx={{
        '& input': {
          padding: '10px 14px',
          paddingLeft: 0,
          fontWeight: 500,
          '&::placeholder': {
            color: theme.palette.grey[500],
          },
        },
        '& fieldset': {
          borderColor: '#bdbdbd',
        },
      }}
    />
  )
}

export default SearchBar
