import { useState } from 'react'

// material-ui
import { IconButton, Tooltip } from '@mui/material'
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingDepositDialog from '../BookingDepositDialog/BookingDepositDialog'
import { Booking, BookingStatus, DepositStatus } from '../../../../types/Booking'

// ========================|| BOOKING - DEPOSIT BUTTON ||======================== //

interface Props {
  booking: Booking
}

export default function BookingDepositIconButton({ booking }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const isCanceled = booking.status === BookingStatus.CANCELED

  const isConfirmed = booking.status === BookingStatus.CONFIRMED

  const isExpired = new Date(booking.date).getTime() < new Date().getTime()

  const isDepositEnabled = booking.depositAmount > 0

  /**
   * Disable the button if the booking is already canceled, is old or deposits are disabled.
   */
  const isDisabled = isCanceled || isConfirmed || isExpired || !isDepositEnabled;

  const title = () => {
    if (isExpired) {
      return 'Pay deposit'
    }

    if (booking.depositStatus === DepositStatus.NOT_REQUIRED) {
      return 'This booking does not require a deposit'
    }

    return 'Pay deposit'
  }

  return (
    <>
      <Tooltip title={t(title())}>
        <span>
          <IconButton color='success' size='large' onClick={handleOpen} disabled={isDisabled}>
            <PaidTwoToneIcon fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
      <BookingDepositDialog open={open} booking={booking} onClose={handleClose} />
    </>
  )
}
