// material-ui
import { Chip, ChipProps, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { BookingStatus } from '../../../types/Booking'

// ========================|| UI COMPONENTS - CHIP - BOOKING STATUS ||======================== //

interface Props extends ChipProps {
  status: BookingStatus
}

export const label = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.PENDING:
      return 'Pending'
    case BookingStatus.CONFIRMED:
      return 'Confirmed'
    case BookingStatus.CANCELED:
      return 'Canceled'
  }
}

export const color = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.PENDING:
      return 'warning'
    case BookingStatus.CONFIRMED:
      return 'success'
    case BookingStatus.CANCELED:
      return 'error'
  }
}

export const dotColor = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.PENDING:
      return 'warning.main'
    case BookingStatus.CONFIRMED:
      return 'success.main'
    case BookingStatus.CANCELED:
      return 'error'
  }
}

export default function BookingStatusChip({ status, ...props }: Props) {
  const { t } = useTranslation()

  return <Chip {...props} label={t(label(status))} color={props.color || color(status)} />
}

export function BookingStatusDot({ status }: Props) {
  const { t } = useTranslation()

  return (
    <Typography variant='h6' display='flex' alignItems='center'>
      {t(label(status))}
      <Typography component='span' color={dotColor(status)} ml='4px' sx={{ cursor: 'default' }}>
        ●
      </Typography>
    </Typography>
  )
}