import { useContext } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, DialogProps } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'

// ========================|| PAYMENT - EDIT DIALOG ||======================== //

/**
 * Redirects the user to Mercado Pago checkout page using the preapproval id.
 *
 * @param preapprovalId the preapproval id.
 */
const redirectToMercadoPago = (preapprovalId: string) => {
  const checkoutLink = `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_id=${preapprovalId}`
  window.open(checkoutLink, '_blank')
}

interface Props extends DialogProps {
  onClose: () => void
}

export default function SubscriptionEnableDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { subscription } = useContext(SubscriptionContext)

  const handleContinue = () => {
    redirectToMercadoPago(subscription.externalReference)
    onClose()
  }

  return (
    <Dialog
      aria-labelledby='edit-payment-dialog'
      onClose={onClose}
      PaperProps={{ sx: { p: '12px 0px' } }}
      {...dialogProps}
    >
      <DialogTitle id='edit-payment-dialog'>{t('You will be redirected')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='edit-payment-dialog-description'>
          {t('Click continue to be re-directed to Mercado Pago website to edit your payment method securely.')} {' '}
          <strong>{t('Changes may take a few minutes to be reflected.')}</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='primary' onClick={onClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='primary' disableElevation onClick={handleContinue}>
          {t('Continue')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}