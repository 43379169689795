import { useState } from 'react'

// material-ui
import { Button } from '@mui/material'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingCancelDialog from '../BookingCancelDialog/BookingCancelDialog'
import { Booking, BookingStatus } from '../../../../types/Booking'

// ========================|| BOOKING - CANCEL BUTTON ||======================== //

interface Props {
  booking: Booking
}

export default function BookingCancelButton({ booking }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const isCanceled = booking.status === BookingStatus.CANCELED

  const isExpired = new Date(booking.date).getTime() < new Date().getTime()

  const isDisabled = isCanceled || isExpired

  return (
    <>
      <Button
        variant='outlined'
        color='error'
        size='small'
        fullWidth
        startIcon={<CancelTwoToneIcon fontSize='small' />}
        onClick={handleOpen}
        disabled={isDisabled}
      >
        {t('Cancel')}
      </Button>
      <BookingCancelDialog open={open} booking={booking} onClose={handleClose} />
    </>
  )
}
