export enum PaymentStatus {
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  IN_PROCESS = 'IN_PROCESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  IN_MEDIATION = 'IN_MEDIATION',
  CHARGED_BACK = 'CHARGED_BACK',
}

export enum InvoiceStatus {
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  ISSUED = 'ISSUED',
  ERROR = 'ERROR',
  UPLOADED = 'UPLOADED',
}

export interface Payment {
  id: string
  amount: number
  currency: string
  debitDate: string
  status: PaymentStatus
  invoiceStatus: InvoiceStatus
}