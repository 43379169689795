// material-ui
import { ButtonProps, IconButton, Tooltip } from '@mui/material'
import DoDisturbOnTwoToneIcon from '@mui/icons-material/DoDisturbOnTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// ========================|| BUSINESS HOURS - DELETE BUTTON ||======================== //

interface Props extends ButtonProps {
  timeRangesLength: number
}

export default function BusinessHourDeleteButton({ timeRangesLength, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <Tooltip title={timeRangesLength > 1 ? t('Unavailable') : t('Unavailable all day')} placement='top' enterDelay={400} enterNextDelay={400}>
      <span>
        <IconButton {...props} aria-label='delete' color='error' size='medium'>
          <DoDisturbOnTwoToneIcon fontSize='small' />
        </IconButton>
      </span>
    </Tooltip>
  )
}