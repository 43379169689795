// material-ui
import { CardContent } from '@mui/material'

// project imports
import { useTranslation } from 'react-i18next'

// project imports
import MainCard from '../../../ui-components/extended/card/MainCard'
import AccountSettingsCard from './AccountSettingsCard/AccountSettingsCard'

// ========================|| ACCOUNT SETTINGS ||======================== //

export default function AccountSettings() {
  const { t } = useTranslation()

  return (
    <MainCard title={t('Account Settings')} content={false} border={false}>
      <CardContent sx={{ p: 3 }}>
        <AccountSettingsCard />
      </CardContent>
    </MainCard>
  )
}
