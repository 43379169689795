// material-ui
import { Box, FormControl, ListItem, Typography } from '@mui/material'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'

// project imports
import TimeRangeFormControl from '../../TimeRangeFormControl/TimeRangeFormControl'
import BusinessHourAddButton from '../../BusinessHourAddButton/BusinessHourAddButton'
import BusinessHourDeleteButton from '../../BusinessHourDeleteButton/BusinessHourDeleteButton'
import BusinessHourAdjustedDeleteButton from '../../BusinessHourAdjustedDeleteButton/BusinessHourAdjustedDeleteButton'
import { locale } from '../../../../../utils/locale'
import { BusinessHourAdjusted, DEFAULT_CLOSING_TIME, DEFAULT_OPENING_TIME } from '../../../../../types/BusinessHour'

// ========================|| BUSINESS HOUR ADJUSTED - FORM CONTROL ||======================== //

interface FormValues {
  businessHoursAdjusted: BusinessHourAdjusted[]
}

interface Props {
  businessHourAdjustedIndex: number
  timezone: string
  onDelete: () => void
}

export default function BusinessHourAdjustedFormControl({ businessHourAdjustedIndex, timezone, onDelete }: Props) {
  // hooks
  const { i18n, t } = useTranslation()
  const { control, watch } = useFormContext<FormValues>()
  const { fields, append, remove } = useFieldArray({ control, name: `businessHoursAdjusted.${businessHourAdjustedIndex}.timeRanges` })

  // state
  const businessHoursAdjusted = watch('businessHoursAdjusted')
  const lastTimeRange = watch(`businessHoursAdjusted.${businessHourAdjustedIndex}.timeRanges.${fields.length - 1}`)

  const handleAppend = () => {
    if (!lastTimeRange) {
      append({ openingTime: DEFAULT_OPENING_TIME, closingTime: DEFAULT_CLOSING_TIME })
      return
    }

    const openingTime = dayjs(lastTimeRange.closingTime, 'HH:mm').add(15, 'minutes').format('HH:mm')
    const closingTime = dayjs(lastTimeRange.closingTime, 'HH:mm').add(30, 'minutes').format('HH:mm')

    append({ openingTime, closingTime })
  }

  const handleRemove = (index: number) => remove(index)

  const shouldDisableAdd = () => {
    if (!lastTimeRange) {
      return true
    }

    return fields.length > 2 || dayjs(lastTimeRange.closingTime, 'HH:mm').isSameOrAfter(dayjs('23:30', 'HH:mm')) || !lastTimeRange.closingTime
  }

  const dateMustBeFuture = (date: string) => {
    const today = dayjs().tz(timezone).set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0)

    if (dayjs(date).isBefore(today)) {
      return "Can't add dates in the past."
    }

    return true
  }

  const dateMustBeUnique = (date: string) => {
    const length = businessHoursAdjusted.filter(
      businessHour => dayjs(businessHour.date).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD'),
    ).length
    return length > 1 ? 'Same dates are added more than once.' : true
  }

  const dateMustBeValid = (date: string) => {
    if (dayjs(date).isValid()) {
      return true
    }

    return 'Date must be valid.'
  }

  if (fields.length === 0) {
    return (
      <ListItem disableGutters disablePadding sx={{ mb: businessHourAdjustedIndex === businessHoursAdjusted.length - 1 ? 0 : 1.5, minHeight: '38px', alignItems: 'flex-start' }}>
        <Controller
          name={`businessHoursAdjusted.${businessHourAdjustedIndex}.date`}
          control={control}
          rules={{ validate: { dateMustBeValid, dateMustBeFuture, dateMustBeUnique } }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale(i18n.language)}>
                <DateField
                  value={dayjs(value)}
                  onChange={onChange}
                  format='DD MMM YYYY'
                  size='small'
                  sx={{ width: '120px', mr: 2 }}
                  helperText={error ? t(error.message as string) : null}
                  slotProps={{
                    textField: {
                      error: !!error,
                      inputProps: {
                        style: {
                          textTransform: 'capitalize',
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          )}
        />
        <Typography variant='body1' color='grey.500' pl={1} width='277px' height='38px' lineHeight='38px'>
          {t('Unavailable')}
        </Typography>
        <BusinessHourAddButton sx={{ ml: '12px' }} onClick={handleAppend} />
        <BusinessHourAdjustedDeleteButton onClick={onDelete} />
      </ListItem>
    )
  }

  return (
    <>
      {fields.map((field, timeRangeIndex) => (
        <ListItem key={field.id} disableGutters disablePadding sx={{ mb: businessHourAdjustedIndex === businessHoursAdjusted.length - 1 && timeRangeIndex === fields.length - 1 ? 0 : 1.5, minHeight: '38px', alignItems: 'flex-start' }}>
          {timeRangeIndex === 0 ? (
            <Controller
              name={`businessHoursAdjusted.${businessHourAdjustedIndex}.date`}
              rules={{ validate: { dateMustBeValid, dateMustBeFuture, dateMustBeUnique } }}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale(i18n.language)}>
                    <DateField
                      value={dayjs(value)}
                      onChange={onChange}
                      format='DD MMM YYYY'
                      size='small'
                      sx={{ width: '120px', mr: 2 }}
                      helperText={error ? t(error.message as string) : null}
                      slotProps={{
                        textField: {
                          error: !!error,
                          inputProps: {
                            style: {
                              textTransform: 'capitalize',
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />
          ) : (
            <Box width={120 + 16} />
          )}
          <TimeRangeFormControl
            formControlName='businessHoursAdjusted'
            businessHourIndex={businessHourAdjustedIndex}
            timeRangeIndex={timeRangeIndex}
          />
          <BusinessHourDeleteButton timeRangesLength={fields.length} onClick={() => handleRemove(timeRangeIndex)} sx={{ ml: 1 }} />
          {timeRangeIndex === 0 && <BusinessHourAddButton disabled={shouldDisableAdd()} onClick={handleAppend} />}
          {timeRangeIndex === 0 && <BusinessHourAdjustedDeleteButton onClick={onDelete} />}
        </ListItem>
      ))}
    </>
  )
}
