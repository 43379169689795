import { useState } from 'react'

// material-ui
import { Button } from '@mui/material'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingDepositDialog from '../BookingDepositDialog/BookingDepositDialog'
import { Booking, DepositStatus } from '../../../../types/Booking'

// ========================|| BOOKING - DEPOSIT BUTTON ||======================== //

interface Props {
  booking: Booking
}

export default function BookingDepositButton({ booking }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const isDepositPending = booking.depositStatus === DepositStatus.PENDING

  const isExpired = new Date(booking.date).getTime() < new Date().getTime()

  /**
   * Disable the button if the booking is already canceled or is old.
   */
  const isDisabled = !isDepositPending || isExpired

  return (
    <>
      <span>
        <Button
          variant='outlined'
          color='success'
          size='small'
          fullWidth
          startIcon={<CancelTwoToneIcon fontSize='small' />}
          disabled={isDisabled}
          onClick={handleOpen}
        >
          {t('Pay deposit')}
        </Button>
      </span>
      <BookingDepositDialog booking={booking} open={open} onClose={handleClose} />
    </>
  )
}
