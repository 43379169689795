// material-ui
import { Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { Service } from '../../../../types/Service'
import ServiceDeleteButton from '../ServiceDeleteButton/ServiceDeleteButton'
import ServiceEditButton from '../ServiceEditButton/ServiceEditButton'
import { getNullablePriceString } from '../../../../utils/typography'

// ========================|| SERVICE - LIST ITEM ||======================== //

interface Props {
  service: Service
}

export default function ServiceListItem({ service }: Props) {
  const { i18n, t } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>{service.name}</Typography>
        {service.description && <Typography variant='caption'>{service.description}</Typography>}
      </Grid>
      <Grid item xs={6}>
        <Typography component='span' variant='caption'>
          {t('Duration')}
        </Typography>
        <Typography variant='h6'>{`${service.duration} ${t('minutes')}`}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component='span' variant='caption'>
          {t('Buffer time')}
        </Typography>
        <Typography variant='h6'>{`${service.buffer} ${t('minutes')}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component='span' variant='caption'>
          {t('Price')}
        </Typography>
        <Typography variant='h6'>
          {t(getNullablePriceString(service.price, service.currency, i18n.language))}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ServiceEditButton service={service} />
          </Grid>
          <Grid item xs={6}>
            <ServiceDeleteButton service={service} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
