// material-ui
import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material'

// project imports
import useBreadcrumbs from '../../../../hooks/company/useBreadcrumbs'
import useTooltip from '../../../../hooks/company/useTooltip'
import MainCard from '../../../../ui-components/extended/card/MainCard'
import MainContent from '../../../../ui-components/extended/table/MainContent'
import Breadcrumbs from '../../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import CompanyUserSearchBar from '../CompanyUserSearchBar/CompanyUserSearchBar'
import CompanyUserCreateButton from '../CompanyUserCreateButton/CompanyUserCreateButton'
import CompanyUserTable from '../CompanyUserTable/CompanyUserTable'
import CompanyUserList from '../CompanyUserList/CompanyUserList'

// ========================|| COMPANY USER - CARD ||======================== //

export default function CompanyUserCard() {
  const theme = useTheme()
  const items = useBreadcrumbs()
  const tooltip = useTooltip()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} content={false} border={false}>
      <MainContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={true}>
            <CompanyUserSearchBar />
          </Grid>
          <Grid item textAlign='center'>
            <CompanyUserCreateButton />
          </Grid>
        </Grid>
      </MainContent>
      <Divider />
      {matchesXS ? <CompanyUserList /> : <CompanyUserTable />}
    </MainCard>
  )
}
