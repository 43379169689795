import { ReactNode } from 'react'

// project imports
import ClientsContext from './ClientsContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useClients from '../../hooks/client/useClients'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - CLIENT ||======================== //

export default function ClientsProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { firstLoading, error, clients, params, setParams, setClients } = useClients(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (firstLoading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return <ClientsContext.Provider value={{ clients, params, setParams, setClients }}>{children}</ClientsContext.Provider>
}