// material-ui
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

// project imports
import ElevateAppBar from '../Landing/AppBar/ElevateAppBar'

// ========================|| TERMS AND CONDITIONS ||======================== //

export default function TermsAndConditions() {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <>
      <ElevateAppBar />
      <Container sx={{ mt: '81px' }}>
        <Box textAlign='justify' p={matchesXS ? '24px 16px' : 3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h2' mb={1}>
                Términos y Condiciones de Uso
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                Bienvenido a nuestra plataforma de turnos online “Empreturnos”. Estos términos y condiciones (&quot;Términos&quot;) rigen el acceso y
                uso de nuestra página web y servicios relacionados de Empreturnos S.A.S. (en adelante, la &quot;Plataforma&quot;). Por favor, lea
                detenidamente estos términos que regulan el acceso, visita y uso de la Plataforma antes de acceder, visitar o utilizar la misma.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                1. Definiciones y Aceptación de los Términos
              </Typography>
              <Typography>
                Al registrarse y/o acceder a la Plataforma, acepta estar legalmente vinculado por estos Términos. Si no está de acuerdo con alguno de
                estos Términos, no utilice la Plataforma. Los presentes Términos y Condiciones, así como las políticas que oportunamente comunique
                EMPRETURNOS al Cliente (&quot;Empresa usuaria&quot;) constituyen todas las condiciones de contratación entre EMPRETURNOS y la Empresa
                usuaria y sustituyen a cualquier comunicación anterior, verbal o escrita, entre las mismas.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                2. Servicios
              </Typography>
              <Typography mb={1}>
                2.1. Nuestra Plataforma ofrece servicios de reserva de turnos proporcionados por terceros (&quot;Empresas usuarias&quot;). Actuamos
                como intermediarios entre los Usuarios finales y los Empresas usuarias, facilitando la reserva de turnos en su nombre. No somos
                responsables de la prestación de los servicios ofrecidos por las Empresas usuarias.
              </Typography>
              <Typography>
                2.2. Asimismo, nuestra Plataforma ofrece el servicio de crear y gestionar una agenda de turnos para los profesionales o representantes
                de las Empresas usuarias que contraten el mismo.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                3. Suscripción de Empresas usuarias
              </Typography>
              <Typography mb={1}>
                3.1. Las Empresas usuarias pueden acceder a los servicios ofrecidos por Empreturnos mediante una suscripción mensual, cuyo precio
                varía en función de la cantidad de asistentes (profesionales que requieren una agenda de turnos).
              </Typography>
              <Typography mb={1}>3.2. La suscripción se puede modificar o cancelar en cualquier momento por parte de la Empresa usuaria.</Typography>
              <Typography>3.3. Empreturnos se reserva el derecho de actualizar los precios de la suscripción en cualquier momento.</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                4. Reserva de Turnos y Políticas de Cancelación
              </Typography>
              <Typography mb={1}>
                4.1. Al utilizar nuestra Plataforma para reservar un turno con una Empresa usuaria, acepta cumplir con los términos y condiciones de
                la Empresa usuaria. No nos hacemos responsables de cualquier cambio, cancelación o incumplimiento de los servicios por parte de la
                Empresa usuaria.
              </Typography>
              <Typography mb={1}>
                4.2. Además, las Empresas usuarias pueden establecer sus propias políticas de cancelación y reprogramación de turnos. Es
                responsabilidad del Usuario final informarse sobre estas políticas antes de realizar una reserva.
              </Typography>
              <Typography mb={1}>
                4.3. Si el Usuario final desea cancelar su reserva, puede hacerlo en cualquier momento, sujeto a la política de cancelación definida
                por la Empresa usuaria y mostrada al Usuario final en los detalles del turno previo a reservar.
              </Typography>
              <Typography mb={1}>
                La política de cancelación puede determinar si el dinero es reembolsable o no cuando el Usuario final cancela un turno.
              </Typography>
              <Typography mb={1}>
                Si es reembolsable, puede definir un tiempo de anticipación para la cancelación que permita el reembolso automático de la seña.
              </Typography>
              <Typography mb={1}>
                Si la Empresa usuaria cancela un turno con seña por parte del Usuario final, la seña se devolverá automáticamente al Usuario final.
              </Typography>
              <Typography>
                4.4. En caso de que el Usuario final haya abonado una seña (o el total del valor del servicio reservado), será reembolsado
                automáticamente únicamente si se aplica la política de cancelación definida por la Empresa usuaria.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                5. Comunicaciones
              </Typography>
              <Typography mb={1}>
                Empreturnos enviará correos electrónicos tanto a los Usuarios finales como a las Empresas usuarias acerca del estado de la reserva de
                turnos:
              </Typography>
              <Typography mb={1}>• Turno pendiente</Typography>
              <Typography mb={1}>• Turno confirmado</Typography>
              <Typography mb={1}>• Turno cancelado</Typography>
              <Typography mb={1}>• Turno pendiente de seña</Typography>
              <Typography mb={1}>• Recordatorios de turnos</Typography>
              <Typography>Por el momento, no es configurable el recibir o dejar de recibir estos correos.</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                6. Registro
              </Typography>
              <Typography mb={1}>
                6.1. Para acceder a ciertas funciones de la Plataforma, es necesario que cree una cuenta y se registre. El Usuario asume el compromiso
                y la responsabilidad de:
              </Typography>
              <Typography mb={1}>• No proporcionar información personal falsa ni crear cuentas a nombre de terceros.</Typography>
              <Typography mb={1}>• No crear más de una cuenta personal.</Typography>
              <Typography mb={1}>• Mantener la información de contacto exacta y actualizada.</Typography>
              <Typography mb={1}>• No compartir la contraseña ni permitir a otra persona acceder a su cuenta.</Typography>
              <Typography mb={1}>
                Mantener la confidencialidad de su información de inicio de sesión y de todas las actividades que ocurran bajo su cuenta.
              </Typography>
              <Typography>
                6.2. Asimismo, la Empresa usuaria también debe registrarse para utilizar los servicios de gestión de agenda y proporcionar información
                veraz sobre sus servicios y disponibilidad.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                7. Privacidad
              </Typography>
              <Typography>
                Nos comprometemos a proteger su privacidad y a tratar sus datos personales de acuerdo con nuestra Política de Privacidad y conforme a
                la Ley de Protección de Datos Personales Ley 25.326. Al utilizar la Plataforma, acepta el tratamiento de sus datos personales de
                acuerdo con nuestra Política de Privacidad.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                8. Restricciones de Uso
              </Typography>
              <Typography>
                Usted no puede realizar ni intentar realizar, ni facilitar a un tercero la realización de ninguna de las siguientes acciones: (i)
                descifrar, descompilar, desensamblar o realizar ingeniería inversa de cualquier software y/o código utilizado para proporcionar la
                Plataforma, aplicaciones o servicios sin nuestra autorización previa por escrito; (ii) eludir, deshabilitar o interferir de cualquier
                otra manera con las funciones de seguridad o con las funciones que impiden o restringen el uso o la copia de cualquier contenido;
                (iii) utilizar la Plataforma, aplicaciones o servicios, o el contenido de las mismas en relación con cualquier emprendimiento
                comercial de cualquier manera, excepto para los fines específicamente establecidos en estos Términos; (iv) utilizar cualquier robot,
                araña, aplicación de búsqueda o recuperación de sitios, u otro dispositivo o proceso manual o automático para recuperar, indexar,
                extraer datos, o de cualquier manera reproducir o eludir la estructura de navegación o la presentación de la Plataforma, aplicaciones
                o servicios, incluyendo el enmarcado o espejado de cualquier parte de la Plataforma, aplicaciones o servicios; (v) utilizar o acceder
                al perfil de otro usuario o contraseña sin permiso; y/o (vi) utilizar la Plataforma, aplicaciones o servicios, o el contenido de las
                mismas de cualquier manera no permitida por estos Términos o por la ley aplicable.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                9. Propiedad Intelectual
              </Typography>
              <Typography mb={1}>
                9.1. Todos los contenidos así como el diseño de esta Plataforma son de nuestra exclusiva propiedad y/o de terceras personas que han
                autorizado su publicación y/o utilización en esta Plataforma; asimismo, todos los nombres de los productos y los logos de Empreturnos
                son marca registrada y están protegidos por las leyes de Propiedad Intelectual. Cualquier copia, reproducción, retransmisión,
                modificación u otro uso no expresamente autorizado, está estrictamente prohibido por la ley y hará pasible al infractor de las
                sanciones previstas en la misma.
              </Typography>
              <Typography>
                9.2. No se le concede al Usuario ningún derecho de propiedad sobre la Plataforma, excepto para usarla de acuerdo con estos Términos.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                10. Disclaimer y Limitación de Responsabilidad
              </Typography>
              <Typography mb={1}>
                10.1. El uso de la Plataforma, las aplicaciones y los servicios es bajo su propia discreción y riesgo. La Plataforma, las aplicaciones
                y los servicios se proporcionan “TAL CUAL” (AS IS) y “SEGÚN DISPONIBILIDAD” (AS AVAILABLE) sin garantías de ningún tipo.
              </Typography>
              <Typography mb={1}>
                10.2. Ningún consejo o información, ya sea oral o escrita, obtenida por usted de nosotros, creará ninguna garantía que no esté
                expresamente establecida en estos Términos.
              </Typography>
              <Typography mb={1}>
                10.3. Usted reconoce y acepta que Empreturnos no es un servicio de guardado de datos. Por lo tanto, debe crear copias de seguridad de
                sus datos, y Empreturnos no tendrá ninguna responsabilidad en relación con cualquier pérdida, daño o corrupción de dichos datos.
              </Typography>
              <Typography mb={1}>
                10.4. Además de lo anterior, no asumimos ninguna responsabilidad por cualquier error, omisión, interrupción, eliminación, defecto,
                demora en la operación o transmisión, fallo de la línea de comunicaciones, robo o destrucción o acceso no autorizado a, o alteración
                de, la Plataforma, aplicaciones o servicios. No somos responsables de ningún problema o mal funcionamiento técnico de ninguna red
                telefónica o líneas, sistemas informáticos en línea, servidores o proveedores, equipos informáticos, software, fallos de cualquier
                correo electrónico debido a problemas técnicos o congestión del tráfico en Internet o en cualquiera de las Plataformas, aplicaciones o
                servicios o combinación de los mismos, incluyendo cualquier lesión o daño a usted o a cualquier dispositivo móvil o computadora de
                cualquier persona relacionado o resultante del uso de la Plataforma, aplicaciones o servicios o del uso de los mismos. Bajo ninguna
                circunstancia seremos responsables de cualquier pérdida o daño, incluyendo lesiones personales o muerte, resultantes del uso de la
                Plataforma, aplicaciones o servicios, de cualquier contenido publicado en o a través de los servicios, o de la conducta de cualquier
                Usuario de los servicios, ya sea en línea o fuera de línea. Además, no asumimos ninguna responsabilidad por cualquier dato incorrecto,
                incluyendo datos personales y/o contenido del Usuario proporcionado por usted o en su nombre y por la presente usted declara y
                garantiza que es el único responsable de cualquier y todos los datos proporcionados a Empreturnos, incluyendo cualquier dato
                incorrecto y usted asumirá toda la responsabilidad por cualquier consecuencia de la provisión de dichos datos incorrectos a nosotros.
              </Typography>
              <Typography mb={1}>
                10.5. En la medida máxima permitida por la ley aplicable, no seremos responsables de ningún daño directo, indirecto, incidental,
                especial, consecuencial o punitivo que surja del uso de la Plataforma.
              </Typography>
              <Typography>
                10.6. Las Empresas son responsables de los servicios que ofrecen a través de la Plataforma, y cualquier reclamo relacionado con los
                servicios debe dirigirse directamente a ellas.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                12. Modificaciones
              </Typography>
              <Typography>
                Nos reservamos el derecho de modificar estos Términos en cualquier momento y sin previo aviso. Cualquier modificación entrará en
                vigencia al publicarse en la Plataforma. Se recomienda revisar periódicamente estos Términos para estar al tanto de las
                actualizaciones.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                13. Subsistencia en caso de nulidad
              </Typography>
              <Typography>
                Si cualquier disposición de estos Términos y Condiciones es calificada como inválida, ilegal, inaplicable o inejecutable por un
                tribunal competente, las demás disposiciones del mismo permanecerán vigentes y válidas, y serán aplicables con todos los efectos
                previstos por las leyes que sean aplicables.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                14. Ley Aplicable y Jurisdicción
              </Typography>
              <Typography>
                Estos Términos se rigen por la legislación de la República Argentina y cualquier disputa relacionada con estos términos estará sujeta
                a la jurisdicción exclusiva de los Tribunales Ordinarios Nacionales en lo Comercial con sede en la Ciudad de Buenos Aires.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' mb={1}>
                15. Conformidad
              </Typography>
              <Typography>Al registrarse y/o acceder a la Plataforma, confirma que ha leído, entendido y aceptado estos Términos.</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
