import { createContext } from 'react'

// project imports
import { Plan } from '../../types/Plan'

// ========================|| CONTEXT - PLAN ||======================== //

interface PlanContextType {
  plan: Plan
}

const PlanContext = createContext<PlanContextType>({} as PlanContextType)

export default PlanContext