import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import BranchApi from '../../api/branch/BranchApi'
import { ApiError } from '../../types/ApiError'
import { Branch } from '../../types/Branch'

// apis
const branchApi = new BranchApi()

// ========================|| HOOK - BRANCH ||======================== //

const useBranch = (branchId: string) => {
  const { auth } = useAuth()
  const [branch, setBranch] = useState<Branch>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchBranch = () => {
    auth!.getIdToken().then(token => {
      branchApi.findById(token, branchId)
        .then(data => setBranch(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchBranch, [branchId])

  return { loading, error, branch, setBranch }
}

export default useBranch