// material-ui
import { Chip, ChipProps } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { PaymentStatus } from '../../../types/Payment'

// ========================|| UI COMPONENTS - CHIP - PAYMENT STATUS ||======================== //

interface Props extends ChipProps {
  status: PaymentStatus
}

export const label = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PENDING:
      return 'Pending'
    case PaymentStatus.AUTHORIZED:
      return 'Authorized'
    case PaymentStatus.IN_PROCESS:
      return 'In Process'
    case PaymentStatus.APPROVED:
      return 'Approved'
    case PaymentStatus.CANCELED:
      return 'Canceled'
    case PaymentStatus.REJECTED:
      return 'Rejected'
    case PaymentStatus.REFUNDED:
      return 'Refunded'
    case PaymentStatus.IN_MEDIATION:
      return 'In Mediation'
    case PaymentStatus.CHARGED_BACK:
      return 'Charged Back'
  }
}

export const color = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PENDING:
      return 'warning'
    case PaymentStatus.AUTHORIZED:
      return 'primary'
    case PaymentStatus.IN_PROCESS:
      return 'warning'
    case PaymentStatus.APPROVED:
      return 'success'
    case PaymentStatus.CANCELED:
      return 'error'
    case PaymentStatus.REJECTED:
      return 'error'
    case PaymentStatus.REFUNDED:
      return 'warning'
    case PaymentStatus.IN_MEDIATION:
      return 'warning'
    case PaymentStatus.CHARGED_BACK:
      return 'warning'
  }
}

export default function PaymentStatusChip({ status, ...props }: Props) {
  const { t } = useTranslation()

  return <Chip {...props} label={t(label(status))} color={props.color || color(status)} />
}