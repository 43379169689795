import { forwardRef, ReactNode, useEffect } from 'react'

// material-ui
import { ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// third-party
import { Link, LinkProps, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '@tabler/icons-react'

// project imports
import { RootState } from '../../store/store'
import { setIsOpen, setOpened } from '../../store/menu/reducer'

// ==============================|| MENU - ITEM ||============================== //

interface Props {
  title: string,
  url: string
  icon: Icon
  disabled?: boolean
  chip?: ReactNode
}

export default function MenuItem({ title, url, icon, disabled = false, chip }: Props) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const opened = useSelector<RootState, boolean>(state => state.menu.opened)
  const isOpen = useSelector<RootState, string>(state => state.menu.isOpen)
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()

  useEffect(() => {
    if (pathname === url) {
      dispatch(setIsOpen(url))
    }
  }, [pathname])

  const handleClick = (url: string) => {
    dispatch(setIsOpen(url))
    if (matchDownMd) {
      dispatch(setOpened(!opened))
    }
  }

  const NavItemLink = forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>((linkProps, ref) => (
    <Link ref={ref} {...linkProps} to={url} target='_self' />
  ))

  const ItemIcon = icon as Icon

  return (
    <ListItemButton
      component={NavItemLink}
      sx={{
        borderRadius: `${theme.shape.borderRadius}px`,
        alignItems: 'flex-start',
        mb: 0.5,
        py: 1.25,
        pl: `${24}px`,
      }}
      disabled={disabled}
      selected={isOpen === url}
      onClick={() => handleClick(url)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
        <ItemIcon color={theme.palette.grey[700]} width={20} height={20} strokeWidth={1.5} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant={isOpen === url ? 'h5' : 'body1'} color='inherit'>
            {t(title)}
          </Typography>
        }
      />
      {chip}
    </ListItemButton>
  )
}