import { ReactNode, useContext, useEffect } from 'react'

// project imports
import UserCompanyContext from '../../../context/usercompany/UserCompanyContext'
import useCompanyId from '../../../hooks/company/useCompanyId'
import useUserCompany from '../../../hooks/usercompany/useUserCompany'
import NotFound from '../../Maintenance/NotFound/NotFound'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| INTEGRATION - PROFILE ||======================== //

export default function ProfileProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { setUserCompany } = useContext(UserCompanyContext)
  const { loading, error, userCompany } = useUserCompany(companyId)

  useEffect(() => {
    if (userCompany) {
      setUserCompany(userCompany)
    }
  }, [userCompany])

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!userCompany) {
    return <ApiErrorAlert />
  }

  return <>{children}</>
}