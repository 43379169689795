import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserAssistantContext from '../../context/userassistant/UserAssistantContext'
import { UserAssistantRole } from '../../types/UserAssistantRole'

// ========================|| HOOK - USER ASSISTANT TOOLTIP ||======================== //

const useTooltip = () => {
  const { userAssistant } = useContext(UserAssistantContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  switch (pathname.split('/').at(-1)) {
    case 'bookings': {
      switch (userAssistant.role) {
        case UserAssistantRole.EDITOR:
          return t('tooltip.assistant.bookings.editor')
        case UserAssistantRole.VIEWER:
          return t('tooltip.assistant.bookings.viewer')
      }
    }
  }
}

export default useTooltip
