import { Link } from 'react-router-dom'

// material-ui
import { ButtonBase, Typography } from '@mui/material'

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  secondary?: string
}

const LogoSection = ({ secondary }: Props) => (
  <ButtonBase disableRipple component={Link} to={'/'}>
    <Typography variant='h3' alignItems='center'>
      Empreturnos
    </Typography>
    {secondary && <Typography variant='caption'>{secondary}</Typography>}
  </ButtonBase>
)

export default LogoSection
