import { ReactNode } from 'react'

// project imports
import BusinessHoursContext from './BusinessHoursContext'
import BusinessHoursAdjustedContext from './BusinessHoursAdjustedContext'
import useBranchId from '../../hooks/branch/useBranchId'
import useBranchBusinessHours from '../../hooks/businesshour/useBranchBusinessHours'
import useBranchBusinessHoursAdjusted from '../../hooks/businesshour/useBranchBusinessHoursAdjusted'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'
import Loader from '../../ui-components/extended/progress/Loader/Loader'

// ========================|| PROVIDER - BUSINESS HOURS ||======================== //

export default function BranchBusinessHoursProvider({ children }: { children: ReactNode }): JSX.Element {
  const branchId = useBranchId()

  const {
    loading: loadingBusinessHours,
    error: businessHoursError,
    businessHours,
    setBusinessHours
  } = useBranchBusinessHours(branchId)

  const {
    loading: loadingBusinessHoursAdjusted,
    error: businessHoursAdjustedError,
    businessHoursAdjusted,
    setBusinessHoursAdjusted,
  } = useBranchBusinessHoursAdjusted(branchId)

  const loading = loadingBusinessHours || loadingBusinessHoursAdjusted

  if (!branchId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (businessHoursError) {
    return <ApiErrorAlert error={businessHoursError} />
  }

  if (businessHoursAdjustedError) {
    return <ApiErrorAlert error={businessHoursAdjustedError} />
  }

  if (!businessHours || !businessHoursAdjusted) {
    return <ApiErrorAlert />
  }

  return (
    <BusinessHoursContext.Provider value={{ businessHours, setBusinessHours }}>
      <BusinessHoursAdjustedContext.Provider value={{ businessHoursAdjusted, setBusinessHoursAdjusted }}>
        {children}
      </BusinessHoursAdjustedContext.Provider>
    </BusinessHoursContext.Provider>
  )
}
