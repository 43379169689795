// material-ui
import { Tooltip } from '@mui/material'
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone'

// ==============================|| UI COMPONENTS - HELPER ||============================== //

interface Props {
  title: string
}

export default function Helper({ title }: Props) {
  return (
    <Tooltip title={title} color='primary'>
      <HelpTwoToneIcon fontSize='small' sx={{ ml: 1 }} />
    </Tooltip>
  )
}
