import { useContext } from 'react'

// material-ui
import { Divider, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// third-party
import { useTranslation } from 'react-i18next'
import { IconBuildingStore, IconCalendarPlus, IconCalendarTime, IconLogout, IconMapPin, IconSettings, IconUser } from '@tabler/icons-react'

// project imports
import UserStatsContext from '../../../context/userstats/UserStatsContext'
import useAuth from '../../../context/auth/useAuth'
import MenuGroup from '../../../layout/Menu/MenuGroup'
import MenuItem from '../../../layout/Menu/MenuItem'
import CreateCompanyCard from './CreateCompanyCard/CreateCompanyCard'
import StatsChip from '../../../ui-components/extended/chip/StatsChip'

// ========================|| LAYOUT - MENU ||======================== //

export default function Menu() {
  const theme = useTheme()
  const { t } = useTranslation()
  const { stats } = useContext(UserStatsContext)
  const { logout } = useAuth()

  const handleLogout = () => {
    logout()
  }

  const bookingsStatsChip = stats.bookings > 0 ? <StatsChip label={stats.bookings} /> : null

  const hasBranches = stats.branches > 0

  const hasAssistants = stats.assistants > 0

  return (
    <>
      <MenuGroup title={t('Bookings')} caption={t('Make an appointment or manage the ones you already have') as string}>
        <MenuItem title='New Booking' url='/companies' icon={IconCalendarPlus} />
        <MenuItem title='My Bookings' url='/users/me/bookings' icon={IconCalendarTime} chip={bookingsStatsChip} />
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuGroup title={t('Manage')} caption={t('Manage companies, branches and assistants you have access to') as string}>
        <MenuItem title='Companies' url='/users/me/companies' icon={IconBuildingStore} />
        {hasBranches && <MenuItem title='Branches' url='/users/me/branches' icon={IconMapPin} />}
        {hasAssistants && <MenuItem title='Assistants' url='/users/me/assistants' icon={IconUser} />}
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuGroup title='Account' caption={t('Manage your personal data and preferences') as string}>
        <MenuItem title='Settings' url='/users/me' icon={IconSettings} />
        <ListItemButton
          sx={{
            borderRadius: `${theme.shape.borderRadius}px`,
            alignItems: 'flex-start',
            mb: 0.5,
            py: 1.25,
            pl: `${24}px`,
          }}
          onClick={handleLogout}
        >
          <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
            <IconLogout color={theme.palette.grey[700]} width={20} height={20} strokeWidth={1.5} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant='body1' color='inherit'>
                {t('Logout')}
              </Typography>
            }
          />
        </ListItemButton>
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <CreateCompanyCard />
    </>
  )
}
