// project imports
import { axiosInstance } from '../../axios'
import { Payment } from '../../types/Payment'
import { Page, Pageable } from '../../types/Page'
import { ResponseType } from 'axios'

// ========================|| API - PAYMENT ||======================== //

export default class PaymentApi {
  findAll(token: string, companyId: string, pageable: Pageable): Promise<Page<Payment>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        page: pageable.page,
        size: pageable.size,
        sort: pageable.sort,
      },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/payments`, config)
  }

  findById(token: string, companyId: string, paymentId: string): Promise<Blob> {
    const config = {
      headers: { Authorization: `Bearer ${token}`},
      responseType: 'blob' as ResponseType,
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/payments/${paymentId}`, config)
  }
}
