import { ReactNode, useEffect } from 'react'

// third-party
import { useDispatch } from 'react-redux'

// project imports
import CompanyContext from './CompanyContext'
import useCompanyByName from '../../hooks/company/useCompanyByName'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import useCompanyName from '../../hooks/company/useCompanyName'
import { setIsOpen } from '../../store/menu/reducer'

// ========================|| PROVIDER - COMPANY ||======================== //

export default function CompanyProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyName = useCompanyName()
  const dispatch = useDispatch()
  const { loading, error, company, setCompany } = useCompanyByName(companyName)

  useEffect(() => {
    dispatch(setIsOpen('/companies'))
  }, [])

  if (!companyName) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!company) {
    return <ApiErrorAlert />
  }

  return <CompanyContext.Provider value={{ company, setCompany }}>{children}</CompanyContext.Provider>
}
