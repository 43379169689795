import { createContext, Dispatch, SetStateAction } from 'react'
import { UserBranch, UserBranchParams } from '../../types/UserBranch'
import { Page } from '../../types/Page'
import { ApiError } from '../../types/ApiError'

// ========================|| CONTEXT - USER BRANCH ||======================== //

interface UserBranchesContextType {
  loading: boolean
  userBranches: Page<UserBranch>
  error?: ApiError
  params: UserBranchParams
  setUserBranches: Dispatch<SetStateAction<Page<UserBranch>>>
  setParams: Dispatch<SetStateAction<UserBranchParams>>
}

const UserBranchesContext = createContext<UserBranchesContextType>({} as UserBranchesContextType)

export default UserBranchesContext
