// material-ui
import { styled } from '@mui/material'
import { drawerWidth } from '../../utils/constants'

// ========================|| MAIN LAYOUT ||======================== //

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  ...theme.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        },
  ),
  width: `calc(100% - ${drawerWidth}px)`,
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    padding: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    padding: '16px',
    marginRight: '10px',
  },
}))

export default Main
