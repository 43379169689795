import { ReactNode } from 'react'

// project imports
import PlanContext from './PlanContext'
import { Plan } from '../../types/Plan'

// ========================|| PROVIDER - PLAN ||======================== //

interface Props {
  plan: Plan
  children: ReactNode
}

export default function PlanProvider({ plan, children }: Props): JSX.Element {
  return <PlanContext.Provider value={{ plan }}>{children}</PlanContext.Provider>
}