import React from 'react'

// material-ui
import { Alert, AlertTitle } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { ApiError } from '../../../types/ApiError'

// ========================|| UI COMPONENTS - ALERTS - ERROR ||======================== //

interface Props {
  error?: ApiError
}

const ApiErrorAlert = ({ error }: Props) => {
  const { t } = useTranslation()

  if (error && error.status === 400) {
    return (
      <Alert severity='error'>
        <AlertTitle>Error</AlertTitle>
        {t('Your client has issued a malformed or illegal request.')}
      </Alert>
    )
  }

  if (error && error.status === 403) {
    return (
      <Alert severity='error'>
        <AlertTitle>Error</AlertTitle>
        {t('Sorry, you don\'t have permissions to see this page.')}
      </Alert>
    )
  }

  if (error && error.status === 404) {
    return (
      <Alert severity='error'>
        <AlertTitle>Error</AlertTitle>
        {t('The resource you are looking was not found.')}
      </Alert>
    )
  }

  return (
    <Alert severity='error'>
      <AlertTitle>Error</AlertTitle>
      {t('Some error has occurred with your request, please try again.')}
    </Alert>
  )
}

export default ApiErrorAlert
