// material-ui
import { Grid, Typography } from '@mui/material'

// project imports
import AssistantDeleteButton from '../AssistantDeleteButton/AssistantDeleteButton'
import AssistantEditButton from '../AssistantEditButton/AssistantEditButton'
import { Assistant } from '../../../../types/Assistant'

// ========================|| ASSISTANT - LIST ITEM ||======================== //

interface Props {
  assistant: Assistant
}

export default function AssistantListItem({ assistant }: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>{assistant.name}</Typography>
        <Typography variant='caption'>{assistant.branch.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AssistantEditButton assistant={assistant} />
          </Grid>
          <Grid item xs={6}>
            <AssistantDeleteButton assistant={assistant} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
