import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Payment } from '../../types/Payment'
import { Page, Pageable } from '../../types/Page'

// ========================|| CONTEXT - PAYMENT ||======================== //

interface PaymentsContextType {
  payments: Page<Payment>
  pageable: Pageable
  setPageable: Dispatch<SetStateAction<Pageable>>
}

const PaymentsContext = createContext<PaymentsContextType>({} as PaymentsContextType)

export default PaymentsContext