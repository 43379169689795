import { Fragment, useContext } from 'react'

// material-ui
import { CardContent, Divider, Grid, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import BookingListItem from '../BookingListItem/BookingListItem'
import ListPagination from '../../../../ui-components/extended/pagination/ListPagination'
import { Booking } from '../../../../types/Booking'

// ========================|| BOOKING - LIST ||======================== //

export default function BookingList() {
  // hooks
  const { t } = useTranslation()
  const { loading, bookings, params, setParams } = useContext(BookingsContext)

  if (loading) {
    return null
  }

  if (bookings.page.totalElements === 0) {
    return (
      <CardContent sx={{ padding: 3 }}>
        <Typography>{t('No bookings found.')}</Typography>
      </CardContent>
    )
  }

  return (
    <CardContent sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {bookings.content.map((booking: Booking) => (
          <Fragment key={booking.id}>
            <Grid item xs={12}>
              <BookingListItem booking={booking} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={12} display='flex' justifyContent='center'>
          <ListPagination count={bookings.page.totalPages} pageable={params} setPageable={setParams} />
        </Grid>
      </Grid>
    </CardContent>
  )
}
