import React, { useState } from 'react'

// material-ui
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import WhatsAppDialog from '../BookingWhatsAppDialog/WhatsAppDialog'
import { Booking } from '../../../../types/Booking'

// ========================|| BOOKING - LIST ITEM MENU ||======================== //

const whatsAppColor = '#25d366'

interface Props {
  booking: Booking
}

export default function BookingListItemMenu({ booking }: Props) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleWhatsAppModalOpen = () => {
    setAnchorEl(null)
    setWhatsAppModalOpen(true)
  }

  const handleWhatsAppModalClose = () => {
    setWhatsAppModalOpen(false)
  }

  return (
    <Box mt='-6px'>
      <IconButton
        aria-label='more'
        id='booking-menu-button'
        aria-controls={open ? 'booking-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize='small' />
      </IconButton>
      <Menu
        id='booking-menu-button'
        MenuListProps={{ 'aria-labelledby': 'booking-menu-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem dense onClick={handleWhatsAppModalOpen}>
          <ListItemIcon>
            <WhatsAppIcon fontSize='small' sx={{ color: whatsAppColor }} />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant='body2'>{t('Chat on WhatsApp')}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
      <WhatsAppDialog booking={booking} onClose={handleWhatsAppModalClose} open={whatsAppModalOpen} />
    </Box>
  )
}
