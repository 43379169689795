import { ReactNode } from 'react'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'

// ==============================|| CUSTOM ICON CARD ||============================== //

interface Props {
  children: ReactNode
}

export default function IconCard({ children }: Props) {
  return (
    <SubCard
      content={false}
      sx={{
        p: '6px',
        minWidth: '48px',
        minHeight: '48px',
        width: '48px',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fafafa',
        borderColor: '#eeeeee4d',
        ':hover': { boxShadow: 'none' },
      }}
    >
      {children}
    </SubCard>
  )
}
