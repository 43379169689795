import React, { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import UserAssistantApi from '../../../../api/userassistant/UserAssistantApi'
import UserStatsContext from '../../../../context/userstats/UserStatsContext'
import UserAssistantsContext from '../../../../context/userassistant/UserAssistantsContext'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { UserAssistant } from '../../../../types/UserAssistant'

// apis
const userAssistantApi = new UserAssistantApi()

// ========================|| USER ASSISTANT - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  userAssistant: UserAssistant
  onClose: () => void
}

export default function UserAssistantDeleteDialog({ userAssistant, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(UserStatsContext)
  const { setUserAssistants } = useContext(UserAssistantsContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeAssistant = (userAssistantId: string) => {
    setUserAssistants(userAssistants => ({
      ...userAssistants,
      content: userAssistants.content.filter(userAssistant => userAssistant.id !== userAssistantId),
    }))
  }

  const reduceAssistantsStat = () => {
    setStats({ ...stats, assistants: stats.assistants - 1 })
  }

  const handleSuccess = () => {
    removeAssistant(userAssistant.id)
    reduceAssistantsStat()

    dispatch(
      setSnackbar({
        message: t('User disassociated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while disassociating user'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      userAssistantApi.delete(token, userAssistant.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-userassistant-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-userassistant-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to be disassociated from the assistant') + ' '}
          <strong>{userAssistant.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Disassociate')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
