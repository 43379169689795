// third-party
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// ========================|| UTILS - TIME SLOT ||======================== //

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

/**
 * Gets an array of time slots in format HH:mm from the start to end provided times.
 *
 * @param startTime the start time in HH:mm format.
 * @param endTime the end time in HH:mm format.
 * @param interval the duration of each time slot in minutes.
 */
export const timeSlot = (startTime: string, endTime: string, interval: number): string[] => {
  const timeSlots: string[] = []
  let startDate = dayjs(startTime, 'HH:mm')
  let endDate = dayjs(endTime, 'HH:mm')

  // This handles the calculation of the difference
  // in minutes when start time is after end time
  if (startDate.isSameOrAfter(endDate, 'minutes')) {
    endDate = endDate.add(1, 'day')
  }

  while (startDate.add(interval, 'minutes').isSameOrBefore(endDate, 'minutes')) {
    timeSlots.push(startDate.format('HH:mm'))
    startDate = startDate.add(interval, 'minutes')
  }

  return timeSlots
}
