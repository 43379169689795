// project imports
import { axiosInstance } from '../../axios'
import { Integration } from '../../types/Integration'

// ========================|| API - INTEGRATION ||======================== //

export default class IntegrationApi {
  findByCompanyId(token: string, companyId: string): Promise<Integration> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/integrations`, config)
  }
}
