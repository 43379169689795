// material-ui
import { Box, CssBaseline } from '@mui/material'

// third-party
import { Outlet } from 'react-router-dom'

// project imports
import UserBranchContext from '../../context/userbranch/UserBranchContext'
import useBranchId from '../../hooks/branch/useBranchId'
import useUserBranch from '../../hooks/userbranch/useUserBranch'
import useDrawer from '../../hooks/useDrawer'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import NotFound from '../Maintenance/NotFound/NotFound'
import Error from '../Maintenance/Error/Error'
import CompanyAppBar from '../../layout/AppBar/CompanyAppBar'
import Sidebar from '../../layout/Sidebar/Sidebar'
import Main from '../../layout/Main/Main'
import Menu from './Menu/Menu'

// ========================|| BRANCH - LAYOUT ||======================== //

export default function BranchLayout() {
  const branchId = useBranchId()
  const { drawerOpened } = useDrawer()
  const { loading, error, userBranch, setUserBranch } = useUserBranch(branchId)

  if (!branchId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    if (error.status === 403 || error.status === 404) {
      return <NotFound />
    }

    return <Error />
  }

  if (!userBranch) {
    return <Error />
  }

  return (
    <UserBranchContext.Provider value={{ userBranch, setUserBranch }}>
      <Box display='flex'>
        <CssBaseline />

        <CompanyAppBar />

        <Sidebar>
          <Menu />
        </Sidebar>

        <Main open={drawerOpened}>
          <Outlet />
        </Main>
      </Box>
    </UserBranchContext.Provider>
  )
}
