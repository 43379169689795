import { useEffect, useState } from 'react'

// project imports
import CompanyUserApi from '../../api/usercompany/CompanyUserApi'
import useAuth from '../../context/auth/useAuth'
import { CompanyUser } from '../../types/CompanyUser'
import { UserCompanyParams } from '../../types/UserCompany'
import { ApiError } from '../../types/ApiError'

// apis
const companyUserApi = new CompanyUserApi()

// ========================|| HOOK - COMPANY USER ||======================== //

export default function useCompanyUsers(companyId: string) {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([])
  const [params, setParams] = useState<UserCompanyParams>({})

  const searchCompanyUsers = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      companyUserApi
        .findAll(token, companyId, params)
        .then(data => setCompanyUsers(data))
        .catch(error => setError(error))
        .finally(() => {
          setFirstLoading(false)
          setLoading(false)
        })
    })
  }

  useEffect(searchCompanyUsers, [companyId, params])

  return { firstLoading, loading, error, companyUsers, params, setCompanyUsers, setParams }
}
