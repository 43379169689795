import { createContext } from 'react'

// project imports
import { Client } from '../../types/Client'

// ========================|| CONTEXT - CLIENT ||======================== //

interface ClientContextType {
  client: Client | undefined
}

const ClientContext = createContext<ClientContextType>({} as ClientContextType)

export default ClientContext
