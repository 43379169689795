// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import SubscriptionCreateForm from '../SubscriptionCreateForm/SubscriptionCreateForm'

// ========================|| SUBSCRIPTION - CREATE CARD ||======================== //

export default function SubscriptionCreateCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Subscription')}>
      <SubscriptionCreateForm />
    </SubCard>
  )
}
