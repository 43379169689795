// project imports
import BranchesProvider from '../../../context/branch/BranchesProvider'
import BranchCard from './BranchCard/BranchCard'

// ========================|| BRANCHES ||======================== //

export default function Branches() {
  return (
    <BranchesProvider>
      <BranchCard />
    </BranchesProvider>
  )
}
