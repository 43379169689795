import { useContext } from 'react'

// material-ui
import { Link } from '@mui/material'

// third-party
import { Trans, useTranslation } from 'react-i18next'

// project imports
import MainCard from '../../../../ui-components/extended/card/MainCard'
import CompanyContext from '../../../../context/company/CompanyContext'

// ========================|| COMPANY BOOKING - OFFLINE ALERT ||======================== //

export default function CompanyOfflineAlert() {
  // hooks
  const { t } = useTranslation()
  const { company } = useContext(CompanyContext)

  return (
    <MainCard title={company.displayName}>
      <Trans t={t} i18nKey='companyOfflineMessage'>
        Sorry, this company is currently offline. Try again later or
        <Link component='a' href={'/companies'} underline='hover'>
          explore other companies
        </Link>
        .
      </Trans>
    </MainCard>
  )
}
