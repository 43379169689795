// third-party
import { Navigate, Outlet } from 'react-router-dom'

// project imports
import UserContext from '../../context/user/UserContext'
import useUser from '../../hooks/user/useUser'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import Error from '../../views/Maintenance/Error/Error'

// ========================|| GUARD - USER ||======================== //

export default function UserGuard() {
  const { loading, error, user, setUser } = useUser()

  if (loading) {
    return <Loader />
  }

  if (error) {
    if (error.status === 404) {
      return <Navigate to='/signup' replace />
    }

    return <Error />
  }

  if (!user) {
    return <Error />
  }

  return (
    <UserContext.Provider value={{ loading, error, user, setUser }}>
      <Outlet />
    </UserContext.Provider>
  )
}
