import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { ServiceDetails, ServiceParams } from '../../types/Service'
import { ApiError } from '../../types/ApiError'

// ========================|| CONTEXT - SERVICE ||======================== //

interface ServiceContextType {
  loading: boolean
  error?: ApiError
  services: ServiceDetails[]
  params: ServiceParams
  setServices: Dispatch<SetStateAction<ServiceDetails[]>>
  setParams: Dispatch<SetStateAction<ServiceParams>>
}

const ServicesContext = createContext<ServiceContextType>({} as ServiceContextType)

export default ServicesContext
