// material-ui
import { ListItem, ListItemText, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { capitalize } from 'lodash'
import dayjs from 'dayjs'

// project imports
import TimeRangeFormControl from '../TimeRangeFormControl/TimeRangeFormControl'
import BusinessHourAddButton from '../BusinessHourAddButton/BusinessHourAddButton'
import BusinessHourDeleteButton from '../BusinessHourDeleteButton/BusinessHourDeleteButton'
import BusinessHourCopyButton from '../BusinessHourCopyButton/BusinessHourCopyButton'
import { locale } from '../../../../utils/locale'
import { BusinessHour, DEFAULT_CLOSING_TIME, DEFAULT_OPENING_TIME, TimeRange, Weekday } from '../../../../types/BusinessHour'

// ========================|| BUSINESS HOUR - FORM ||======================== //

interface Props {
  businessHourIndex: number
}

export default function BusinessHourFormControl({ businessHourIndex }: Props) {
  // hooks
  const { t, i18n } = useTranslation()
  const { control, watch, getValues, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({ control, name: `businessHours.${businessHourIndex}.timeRanges` })

  // state
  const businessHours: BusinessHour[] = watch('businessHours')
  const weekday: Weekday = getValues(`businessHours.${businessHourIndex}.weekday`)

  const day: string = dayjs()
    .day(weekday)
    .locale(locale(i18n.language))
    .format('ddd')

  const handleAppend = () => {
    const timeRange: TimeRange = getValues(`businessHours.${businessHourIndex}.timeRanges.${fields.length - 1}`)

    if (!timeRange) {
      append({ openingTime: DEFAULT_OPENING_TIME, closingTime: DEFAULT_CLOSING_TIME })
      return
    }

    const openingTime = dayjs(timeRange.closingTime, 'HH:mm').add(15, 'minutes').format('HH:mm')
    const closingTime = dayjs(timeRange.closingTime, 'HH:mm').add(30, 'minutes').format('HH:mm')

    append({ openingTime, closingTime })
  }

  const handleRemove = (index: number) => remove(index)

  const handleCopy = () => {
    for (let i = 0; i < 7; i++) {
      const timeRanges: TimeRange[] = getValues(`businessHours.${i}.timeRanges`)

      if (timeRanges.length > 0) {
        setValue(`businessHours.${i}.timeRanges`, getValues(`businessHours.${businessHourIndex}.timeRanges`), { shouldDirty: true })
      }
    }
  }

  const shouldDisableRemove = () => {
    return (
      fields.length === 1 &&
      businessHours.filter(businessHour => businessHour.weekday !== weekday).every(businessHour => businessHour.timeRanges.length === 0)
    )
  }

  const shouldDisableAdd = () => {
    const timeRange: TimeRange = getValues(`businessHours.${businessHourIndex}.timeRanges.${fields.length - 1}`)
    return fields.length > 2 || dayjs(timeRange.closingTime, 'HH:mm').isSameOrAfter(dayjs('23:30', 'HH:mm')) || !timeRange.closingTime
  }

  const shouldDisableCopy = () => {
    for (let i = 0; i < fields.length; i++) {
      const timeRange: TimeRange = getValues(`businessHours.${businessHourIndex}.timeRanges.${i}`)

      if (!timeRange.openingTime || !timeRange.closingTime) {
        return true
      }
    }

    return false
  }

  if (fields.length === 0) {
    return (
      <ListItem disableGutters disablePadding sx={{ mb: businessHourIndex === 6 ? 0 : 1.5, minHeight: '38px' }}>
        <ListItemText primary={capitalize(day)} sx={{ width: '50px', flex: 'unset' }} />
        <Typography variant='body1' color='grey.500' pl={1} width='289px'>
          {t('Unavailable')}
        </Typography>
        <BusinessHourAddButton onClick={handleAppend} />
      </ListItem>
    )
  }

  return (
    <>
      {fields.map((field, timeRangeIndex) => (
        <ListItem
          key={field.id}
          disableGutters
          disablePadding
          sx={{ mb: businessHourIndex === 6 && timeRangeIndex === fields.length - 1 ? 0 : 1.5, minHeight: '38px' }}
        >
          <ListItemText primary={timeRangeIndex === 0 ? capitalize(day) : null} sx={{ width: '50px', flex: 'unset' }} />
          <TimeRangeFormControl businessHourIndex={businessHourIndex} timeRangeIndex={timeRangeIndex} />
          <BusinessHourDeleteButton timeRangesLength={fields.length} disabled={shouldDisableRemove()} onClick={() => handleRemove(timeRangeIndex)} sx={{ ml: 1 }} />
          {timeRangeIndex === 0 && <BusinessHourAddButton disabled={shouldDisableAdd()} onClick={handleAppend} />}
          {timeRangeIndex === 0 && <BusinessHourCopyButton disabled={shouldDisableCopy()} onClick={handleCopy} />}
        </ListItem>
      ))}
    </>
  )
}
