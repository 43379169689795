import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Page } from '../../types/Page'
import { BranchUser } from '../../types/BranchUser'
import { UserBranchParams } from '../../types/UserBranch'

// ========================|| CONTEXT - BRANCH USERS ||======================== //

interface BranchUsersContextType {
  branchUsers: Page<BranchUser>
  params: UserBranchParams
  setBranchUsers: Dispatch<SetStateAction<Page<BranchUser>>>
  setParams: Dispatch<SetStateAction<UserBranchParams>>
}

const BranchUsersContext = createContext<BranchUsersContextType>({} as BranchUsersContextType)

export default BranchUsersContext