import { ReactNode } from 'react'

// project imports
import ServicesContext from './ServicesContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useServices from '../../hooks/service/useServices'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - SERVICES ||======================== //

export default function ServicesProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { firstLoading, loading, error, services, params, setServices, setParams } = useServices(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (firstLoading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return <ServicesContext.Provider value={{ loading, error, services, params, setServices, setParams }}>{children}</ServicesContext.Provider>
}