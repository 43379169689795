import { useContext, useEffect } from 'react'

// project imports
import OptionalSubscriptionContext from '../../../context/subscription/OptionalSubscriptionContext'
import BillingProvider from '../../../context/billing/BillingProvider'
import SubscriptionProvider from '../../../context/subscription/SubscriptionProvider'
import PlanBillingProvider from '../../../context/plan/PlanBillingProvider'
import useCompanyId from '../../../hooks/company/useCompanyId'
import useSubscription from '../../../hooks/subscription/useSubscription'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import SubscriptionCard from './SubscriptionCard/SubscriptionCard'
import SubscriptionCreateCard from './SubscriptionCreateCard/SubscriptionCreateCard'
import SubscriptionFreeTrialCard from './SubscriptionFreeTrialCard/SubscriptionFreeTrialCard'
import PlanProvider from '../../../context/plan/PlanProvider'
import { SubscriptionStatus } from '../../../types/Subscription'

// ========================|| SUBSCRIPTION ||======================== //

export default function Subscription() {
  const companyId = useCompanyId()
  const { subscription, setSubscription } = useContext(OptionalSubscriptionContext)
  const { loading, error, subscription: updated } = useSubscription(companyId)

  useEffect(() => {
    if (updated) {
      setSubscription(updated)
    }
  }, [updated])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!subscription) {
    return (
      <PlanBillingProvider>
        <SubscriptionCreateCard />
      </PlanBillingProvider>
    )
  }

  if (subscription.status === SubscriptionStatus.FREE_TRIAL) {
    return (
      <SubscriptionProvider subscription={subscription}>
        <PlanProvider plan={subscription.plan}>
          <BillingProvider>
            <SubscriptionFreeTrialCard />
          </BillingProvider>
        </PlanProvider>
      </SubscriptionProvider>
    )
  }

  return (
    <SubscriptionProvider subscription={subscription}>
      <SubscriptionCard />
    </SubscriptionProvider>
  )
}
