import { useState } from 'react'

// material-ui
import { Link } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import PaymentMethodCancelDialog from '../PaymentMethodCancelDialog/PaymentMethodCancelDialog'

// ========================|| PAYMENT - CANCEL BUTTON ||======================== //

export default function PaymentMethodCancelButton() {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Link
        variant='body1'
        color='error'
        underline='hover'
        sx={{ cursor: 'pointer' }}
        onClick={handleOpen}
      >
        {t('Delete')}
      </Link>
      <PaymentMethodCancelDialog open={open} onClose={handleClose} />
    </>
  )
}