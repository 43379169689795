import { ReactNode } from 'react'

// project imports
import useUserStats from '../../hooks/userstats/useUserStats'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import UserStatsContext from './UserStatsContext'

// ========================|| PROVIDER - USER STATS ||======================== //

export default function UserStatsProvider({ children }: { children: ReactNode }): JSX.Element {
  const { loading, error, stats, setStats } = useUserStats()

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!stats) {
    return <ApiErrorAlert />
  }

  return <UserStatsContext.Provider value={{ stats, setStats }}>{children}</UserStatsContext.Provider>
}
