// project imports
import { axiosInstance } from '../../axios'
import { BusinessHour } from '../../types/BusinessHour'
import { BusinessHoursUpdateDto } from './dto/BusinessHoursUpdateDto'

// ========================|| API - BUSINESS HOUR - BRANCH ||======================== //

export default class BranchBusinessHoursApi {
  findByBranchId(token: string, branchId: string): Promise<BusinessHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/branches/${branchId}/business-hours`, config)
  }

  update(token: string, branchId: string, businessHours: BusinessHoursUpdateDto): Promise<BusinessHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/branches/${branchId}/business-hours`, businessHours, config)
  }
}
