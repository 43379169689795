import { ReactNode } from 'react'

// project imports
import ServiceContext from './ServiceContext'
import useServiceId from '../../hooks/service/useServiceId'
import useService from '../../hooks/service/useService'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - SERVICE ||======================== //

export default function ServiceProvider({ children }: { children: ReactNode }): JSX.Element {
  const serviceId = useServiceId()
  const { loading, error, service, setService } = useService(serviceId)

  if (!serviceId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!service) {
    return <ApiErrorAlert />
  }

  return <ServiceContext.Provider value={{ service, setService }}>{children}</ServiceContext.Provider>
}
