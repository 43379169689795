// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Button, Container, Grid, Link, Typography, useMediaQuery } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import Copyright from '../Copyright/Copyright'

// ========================|| FOOTER ||======================== //

export default function Footer() {
  const theme = useTheme()
  const { t } = useTranslation()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <>
      <Box sx={{ backgroundColor: theme.palette.secondary.main, p: '35px 0px' }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} sx={{ [theme.breakpoints.down('sm')]: { textAlign: 'center' } }}>
              <Typography variant='h3' color='background.default'>
                Empreturnos
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid
                container
                spacing={2}
                justifyContent='flex-end'
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                  },
                }}
              >
                <Grid item>
                  <Button
                    sx={{ color: theme.palette.background.default }}
                    startIcon={<InstagramIcon width='1em' height='1em' />}
                    target='_blank'
                    href='https://www.instagram.com/empreturnos'
                  >
                    <Typography variant='body1' color='inherit'>
                      Instagram
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button sx={{ color: theme.palette.background.default }} startIcon={<FacebookIcon width='1em' height='1em' />}>
                    <Typography variant='body1' color='inherit'>
                      Facebook
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button sx={{ color: theme.palette.background.default }} startIcon={<TwitterIcon width='1em' height='1em' />}>
                    <Typography variant='body1' color='inherit'>
                      Twitter
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {!matchesXS && (
        <Box sx={{ backgroundColor: theme.palette.secondary.dark, p: '20px 0px' }}>
          <Container>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant='caption'>
                  <Link component='a' color='#ffffff' href={'/terms'}>
                    {t('Terms and conditions')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={true}>
                <Typography variant='caption'>
                  <Link component='a' color='#ffffff' href={'/privacy'}>
                    {t('Privacy policy')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Copyright sx={{ color: theme.palette.background.default }} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      {matchesXS && (
        <Box sx={{ backgroundColor: theme.palette.secondary.dark, p: '20px 0px' }}>
          <Container>
            <Grid container spacing={1} sx={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <Typography variant='caption'>
                  <Link component='a' color='#ffffff' href={'/terms'}>
                    {t('Terms and conditions')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='caption'>
                  <Link component='a' color='#ffffff' href={'/privacy'}>
                    {t('Privacy policy')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Copyright sx={{ color: theme.palette.background.default }} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  )
}
