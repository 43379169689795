// material-ui
import { Autocomplete, FormControl, TextField, useMediaQuery, useTheme } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'

// project imports
import useTimes from '../../../../../hooks/availability/useTimes'
import { findTimezoneLabel } from '../../../../../utils/timezone'
import { Service } from '../../../../../types/Service'
import { Assistant } from '../../../../../types/Assistant'
import { Branch } from '../../../../../types/Branch'

// ========================|| SELECT DATE - TIME AUTOCOMPLETE ||======================== //

export default function TimeAutocomplete() {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()
  const { control, watch } = useFormContext()

  const branch: Branch = watch('branch')
  const service: Service = watch('service')
  const assistant: Assistant = watch('assistant')
  const date: Dayjs = watch('date')

  const { loading, timeSlots } = useTimes(service.id, assistant.id, date)

  const shouldOptionDisabled = (dateTime: string): boolean => {
    const timeSlot = timeSlots.find(timeSlot => timeSlot.startTime === dateTime)
    return timeSlot ? !timeSlot.available : true
  }

  return (
    <Controller
      name='time'
      control={control}
      rules={{ required: 'This field is required.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <Autocomplete
            options={timeSlots.map(timeSlot => timeSlot.startTime)}
            value={value || null}
            loading={loading}
            loadingText={t('Loading...')}
            onChange={(e, time) => onChange(time)}
            disableClearable
            isOptionEqualToValue={(option, value) => option === value}
            getOptionDisabled={shouldOptionDisabled}
            getOptionLabel={option => dayjs(option).tz(branch.address.timezone).format('HH:mm')}
            renderInput={params =>
              matchesXS ? (
                <TextField
                  {...params}
                  placeholder={t('Hour')}
                  error={!!error}
                  helperText={error ? t(error.message as string) : findTimezoneLabel(branch.address.timezone)}
                />
              ) : (
                <TextField
                  {...params}
                  label={t('Hour')}
                  error={!!error}
                  helperText={error ? t(error.message as string) : findTimezoneLabel(branch.address.timezone)}
                />
              )
            }
          />
        </FormControl>
      )}
    />
  )
}
