// project imports
import { axiosInstance } from '../../axios'
import { CompanyUser } from '../../types/CompanyUser'
import { CompanyUserDto } from './dto/CompanyUserDto'
import { UserCompanyParams } from '../../types/UserCompany'
import { CompanyUserUpdateDto } from './dto/CompanyUserUpdateDto'

// ========================|| API - COMPANY USER ||======================== //

export default class CompanyUserApi {
  findAll(token: string, companyId: string, params: UserCompanyParams): Promise<CompanyUser[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/users`, config)
  }

  create(token: string, companyId: string, user: CompanyUserDto): Promise<CompanyUser> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/users`, user, config)
  }

  update(token: string, companyId: string, userId: string, companyUser: CompanyUserUpdateDto): Promise<CompanyUser> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/companies/${companyId}/users/${userId}`, companyUser, config)
  }

  delete(token: string, companyId: string, userId: string) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/companies/${companyId}/users/${userId}`, config)
  }
}
