import React from 'react'

// material-ui
import { Chip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// ========================|| UI COMPONENTS - CHIP - COMPANY STATUS ||======================== //

interface Props {
  active: boolean
}

export default function CompanyStatusChip({ active }: Props) {
  const { t } = useTranslation()

  if (active) {
    return <Chip label={t('Online')} color='success' size='small' />
  } else {
    return <Chip label={t('Offline')} color='error' size='small' />
  }
}