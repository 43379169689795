import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserAssistantApi from '../../api/userassistant/UserAssistantApi'
import { UserAssistant } from '../../types/UserAssistant'
import { ApiError } from '../../types/ApiError'

// apis
const userAssistantApi = new UserAssistantApi()

// ========================|| HOOK - USER ASSISTANT ||======================== //

export default function useUserAssistant(assistantId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [userAssistant, setUserAssistant] = useState<UserAssistant>()

  const searchUserAssistants = () => {
    auth!.getIdToken().then(token => {
      userAssistantApi
        .findById(token, assistantId)
        .then(data => setUserAssistant(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchUserAssistants, [assistantId])

  return { loading, error, userAssistant, setUserAssistant }
}
