import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Company } from '../../types/Company'

// ========================|| CONTEXT - COMPANY ||======================== //

interface CompanyContextType {
  company: Company
  setCompany: Dispatch<SetStateAction<Company | undefined>>
}

const CompanyContext = createContext<CompanyContextType>({} as CompanyContextType)

export default CompanyContext