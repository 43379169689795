// material-ui
import { Chip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { UserBranchRole } from '../../../../types/UserBranchRole'

// ========================|| BRANCH USER - ROLE CHIP ||======================== //

interface Props {
  role: UserBranchRole
}

export default function BranchUserRoleChip({ role }: Props) {
  const { t } = useTranslation()

  switch (role) {
    case UserBranchRole.ADMIN:
      return <Chip label={t('Admin')} size='small' color='primary' />
    case UserBranchRole.RECEPTIONIST:
      return <Chip label={t('Receptionist')} size='small' color='secondary' />
    default:
      return <Chip label={t('Unknown')} size='small' color='error' />
  }
}
