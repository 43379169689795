// material-ui
import { DialogContentText } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { Booking, DepositStatus } from '../../../../../types/Booking'

// ========================|| BOOKING - CANCEL DIALOG TEXT ||======================== //

interface Props {
  booking: Booking
}

export default function BookingCancelDialogText({ booking }: Props) {
  const { t } = useTranslation()

  if (booking.depositStatus === DepositStatus.APPROVED) {
    return (
      <DialogContentText id='cancel-booking-dialog-description'>
        {t('The deposit will be refunded to the client. Do you want to cancel?')}
      </DialogContentText>
    )
  }

  return (
    <DialogContentText id='cancel-booking-dialog-description'>
      {t('Do you want to cancel the booking?')}
    </DialogContentText>
  )
}
