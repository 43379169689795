// project imports
import UserBranchesContext from '../../../context/userbranch/UserBranchesContext'
import useUserBranches from '../../../hooks/userbranch/useUserBranches'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import UserBranchCard from './UserBranchCard/UserBranchCard'

// ========================|| USER BRANCH ||======================== //

export default function UserBranches() {
  const state = useUserBranches()

  if (state.firstLoading) {
    return <Loader/>
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <UserBranchesContext.Provider value={state}>
      <UserBranchCard />
    </UserBranchesContext.Provider>
  )
}