import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserCompanyApi from '../../api/usercompany/UserCompanyApi'
import { UserCompany } from '../../types/UserCompany'
import { ApiError } from '../../types/ApiError'

// apis
const userCompanyApi = new UserCompanyApi()

// ========================|| HOOK - USER COMPANY ||======================== //

export default function useUserCompany(companyId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [userCompany, setUserCompany] = useState<UserCompany>()

  const fetchUserCompany = () => {
    auth!.getIdToken().then(token => {
      userCompanyApi
        .findById(token, companyId)
        .then(data => setUserCompany(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(fetchUserCompany, [companyId])

  return { loading, error, userCompany, setUserCompany }
}
