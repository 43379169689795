import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanyClientApi from '../../api/client/CompanyClientApi'
import { Client, ClientParams } from '../../types/Client'
import { ApiError } from '../../types/ApiError'
import { DEFAULT_PAGE, Page } from '../../types/Page'

// apis
const clientApi = new CompanyClientApi()

// ========================|| HOOK - CLIENT ||======================== //

const useClients = (companyId: string) => {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [clients, setClients] = useState<Page<Client>>(DEFAULT_PAGE)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [params, setParams] = useState<ClientParams>({
    page: 0,
    size: 5,
    sort: 'user.name,desc',
  })

  const findAll = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      clientApi
        .findAll(token, companyId, params)
        .then(data => setClients(data))
        .catch(error => setError(error))
        .finally(() => {
          setFirstLoading(false)
          setLoading(false)
        })
    })
  }

  useEffect(findAll, [companyId, params])

  return { firstLoading, loading, error, clients, setClients, params, setParams }
}

export default useClients
