// project imports
import { axiosInstance } from '../../axios'
import { Page } from '../../types/Page'
import { UserAssistant, UserAssistantParams } from '../../types/UserAssistant'

// ========================|| API - USER ASSISTANT ||======================== //

export default class UserAssistantApi {
  findById(token: string, assistantId: string): Promise<UserAssistant> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/users/me/assistants/${assistantId}`, config)
  }

  findAll(token: string, params: UserAssistantParams): Promise<Page<UserAssistant>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    }

    return axiosInstance.get('/api/v1/users/me/assistants', config)
  }

  delete(token: string, assistantId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/users/me/assistants/${assistantId}`, config)
  }
}
