// project imports
import UserAssistantsContext from '../../../context/userassistant/UserAssistantsContext'
import useUserAssistants from '../../../hooks/userassistant/useUserAssistants'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import UserAssistantCard from './UserAssistantCard/UserAssistantCard'

// ========================|| USER ASSISTANT ||======================== //

export default function UserAssistants() {
  const state = useUserAssistants()

  if (state.firstLoading) {
    return <Loader/>
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <UserAssistantsContext.Provider value={state}>
      <UserAssistantCard />
    </UserAssistantsContext.Provider>
  )
}