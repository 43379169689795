// project imports
import { axiosInstance } from '../../axios'
import { Service } from '../../types/Service'
import { ServiceUpdateDto } from './dto/ServiceUpdateDto'

// ========================|| API - SERVICE ||======================== //

export default class ServiceApi {
  findById(token: string, serviceId: string): Promise<Service> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/services/${serviceId}`, config)
  }

  update(token: string, serviceId: string, service: ServiceUpdateDto): Promise<Service> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/services/${serviceId}`, service, config)
  }

  delete(token: string, serviceId: string) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/services/${serviceId}`, config)
  }
}
