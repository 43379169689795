import { useContext, useEffect, useMemo } from 'react'

// third-party
import { useTranslation } from 'react-i18next'
import debouce from 'lodash.debounce'

// project imports
import AssistantsContext from '../../../../context/assistant/AssistantsContext'
import SearchBar from '../../../../ui-components/extended/textfield/SearchBar'

// ========================|| ASSISTANT - SEARCH BAR ||======================== //

export default function AssistantSearchBar() {
  const { t } = useTranslation()
  const { setParams } = useContext(AssistantsContext)

  const handleSearch = (event: any) => {
    const search = event.target.value || undefined
    setParams(params => ({ ...params, search }))
  }

  const debounceSearch = useMemo(() => {
    return debouce(handleSearch, 300);
  }, [])

  useEffect(() => debounceSearch.cancel, [])

  return <SearchBar placeholder={t('Search Assistant') as string} onChange={debounceSearch} />
}
