// third-party
import { configureStore } from '@reduxjs/toolkit'

// project imports
import menuReducer from './menu/reducer'
import snackbarReducer from './snackbar/reducer'

// ========================|| STORE ||======================== //

const store = configureStore({
  reducer: {
    menu: menuReducer,
    snackbar: snackbarReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export default store
