import { useState } from 'react'

// material-ui
import { IconButton, Tooltip } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import WhatsAppDialog from '../BookingWhatsAppDialog/WhatsAppDialog'
import { Booking } from '../../../../types/Booking'

// ==============================|| BUTTONS - WHATSAPP ||============================== //

interface Props {
  booking: Booking
}

export default function WhatsAppIconButton({ booking }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Chat on WhatsApp')}>
        <IconButton color='success' size='large' onClick={handleOpen}>
          <WhatsAppIcon fontSize='small' sx={{ color: '#25d366' }} />
        </IconButton>
      </Tooltip>
      <WhatsAppDialog booking={booking} onClose={handleClose} open={open} />
    </>
  )
}
