import { ReactNode } from 'react'

// project imports
import AssistantsContext from './AssistantsContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useAssistants from '../../hooks/assistant/useAssistants'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - ASSISTANTS ||======================== //

export default function AssistantsProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { firstLoading, loading, error, assistants, params, setAssistants, setParams } = useAssistants(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (firstLoading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return <AssistantsContext.Provider value={{ loading, error, assistants, params, setAssistants, setParams }}>{children}</AssistantsContext.Provider>
}