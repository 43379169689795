import { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import CompanyUsersContext from '../../../../context/usercompany/CompanyUsersContext'
import CompanyUserApi from '../../../../api/usercompany/CompanyUserApi'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { CompanyUser } from '../../../../types/CompanyUser'

// apis
const companyUserApi = new CompanyUserApi()

// ========================|| COMPANY USER - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  companyUser: CompanyUser
  onClose: any
}

export default function CompanyUserDeleteDialog({ companyUser, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setCompanyUsers } = useContext(CompanyUsersContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeUserCompany = (removed: CompanyUser) => {
    setCompanyUsers(companyUsers => companyUsers.filter(companyUser => companyUser !== removed))
  }

  const handleSuccess = () => {
    removeUserCompany(companyUser)

    dispatch(
      setSnackbar({
        message: t('User deleted successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while deleting user'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      companyUserApi.delete(token, companyId, companyUser.id).then(handleSuccess).catch(handleError).finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-user-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-user-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to delete the user') + ' '}
          <strong>{companyUser.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={onClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
