// project imports
import { axiosInstance } from '../../axios'
import { BusinessHourAdjusted } from '../../types/BusinessHour'
import { BusinessHoursAdjustedUpdateDto } from './dto/BusinessHoursAdjustedUpdateDto'

// ========================|| API - BUSINESS HOURS ADJUSTED - COMPANY |======================== //

export default class CompanyBusinessHoursAdjustedApi {
  findByCompanyId(token: string, companyId: string): Promise<BusinessHourAdjusted[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/business-hours-adjusted`, config)
  }

  update(token: string, companyId: string, businessHoursAdjusted: BusinessHoursAdjustedUpdateDto): Promise<BusinessHourAdjusted[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/companies/${companyId}/business-hours-adjusted`, businessHoursAdjusted, config)
  }
}
