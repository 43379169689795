import { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import IntegrationMercadoPagoApi from '../../../../api/integrationmercadopago/IntegrationMercadoPagoApi'
import IntegrationContext from '../../../../context/integration/IntegrationContext'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import { setSnackbar } from '../../../../store/snackbar/reducer'

// ========================|| INTEGRATION - MERCADO PAGO - UNLINK DIALOG ||======================== //

const integrationMercadoPagoApi = new IntegrationMercadoPagoApi()

export default function IntegrationMercadoPagoDisconnectButton() {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { integration, setIntegration } = useContext(IntegrationContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleUnlink = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      integrationMercadoPagoApi
        .delete(token, companyId)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred when disconnecting Mercado Pago account') as string,
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSuccess = () => {
    setIntegration({ ...integration, mercadoPago: false })
    setOpen(false)

    dispatch(
      setSnackbar({
        message: t('Mercado Pago account disconnected successfully') as string,
        severity: 'success',
        open: true,
      }),
    )
  }

  return (
    <>
      <Button color='error' size='small' variant='contained' disableElevation fullWidth onClick={() => setOpen(true)}>{t('Disconnect')}</Button>
      <Dialog
        aria-labelledby='integration-mercado-pago-unlink-dialog'
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { p: '12px 0px' } }}
      >
        <DialogTitle id='integration-mercado-pago-unlink-dialog'>{t('Are you sure?')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='integration-mercado-pago-unlink-dialog-description'>
            {t('By disconnecting your Mercado Pago account, you will no longer be able to receive payments from your clients.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
          <Button variant='outlined' color='error' onClick={() => setOpen(false)}>
            {t('Cancel')}
          </Button>
          <LoadingButton variant='contained' color='error' disableElevation loading={loading} onClick={handleUnlink}>
            {t('Disconnect')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}