import React, { useContext } from 'react'

// material-ui
import { TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ServicesContext from '../../../../context/service/ServicesContext'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import ServiceEditIconButton from '../ServiceEditIconButton/ServiceEditIconButton'
import ServiceDeleteIconButton from '../ServiceDeleteIconButton/ServiceDeleteIconButton'
import usePagination from '../../../../hooks/pagination/usePagination'
import { getNullablePriceString } from '../../../../utils/typography'
import { Service } from '../../../../types/Service'

// ========================|| SERVICE - TABLE ||======================== //

export default function ServiceTable() {
  // hooks
  const { i18n, t } = useTranslation()
  const { services } = useContext(ServicesContext)
  const { rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(services)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Name')}</TableCell>
              <TableCell>{t('Description')}</TableCell>
              <TableCell align='right' width={140}>
                {t('Duration')}
              </TableCell>
              <TableCell align='right' width={140}>
                {t('Buffer time')}
              </TableCell>
              <TableCell align='right'>{t('Price')}</TableCell>
              <TableCell align='right'>{t('Deposit')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((service: Service) => (
              <TableRow key={service.id}>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.description || <em>{t('No description')}</em>}</TableCell>
                <TableCell align='right'>{`${service.duration} ${t('minutes')}`}</TableCell>
                <TableCell align='right'>{`${service.buffer} ${t('minutes')}`}</TableCell>
                <TableCell align='right'>
                  {t(getNullablePriceString(service.price, service.currency, i18n.language))}
                </TableCell>
                <TableCell align='right'>{service.depositPercentage * 100}%</TableCell>
                <TableCell align='center'>
                  <ServiceEditIconButton service={service} />
                  <ServiceDeleteIconButton service={service} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <TablePagination
        component='div'
        count={services.length}
        rowsPerPageOptions={[5, 10, 20]}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        slotProps={{ select: { inputProps: { 'aria-label': 'rows per page' }, native: true } }}
        labelRowsPerPage={t('Rows per page:')}
      />
    </>
  )
}
