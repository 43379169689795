import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanyApi from '../../api/company/CompanyApi'
import { Company } from '../../types/Company'
import { ApiError } from '../../types/ApiError'

// apis
const companyApi = new CompanyApi()

// ========================|| CUSTOM HOOK - COMPANY ||======================== //

const useCompanyByName = (companyName: string) => {
  const { auth } = useAuth()
  const [company, setCompany] = useState<Company>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  useEffect(() => {
    auth!.getIdToken().then(token => {
      companyApi.findByName(token, companyName)
        .then(data => setCompany(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }, [companyName])

  return { loading, error, company, setCompany }
}

export default useCompanyByName
