export default {
  /* paper & background */
  paper: '#ffffff',

  /* primary */
  primaryLight: '#e3f2fd',
  primary200: '#90caf9',
  primaryMain: '#2196f3',
  primaryDark: '#1e88e5',
  primary800: '#1565c0',

  /* secondary */
  secondaryLight: '#ede7f6',
  secondary200: '#b39ddb',
  secondaryMain: '#673ab7',
  secondaryDark: '#5e35b1',
  secondary800: '#4527a0',

  /* success */
  successLight: '#b9f6ca',
  success200: '#69f0ae',
  successMain: '#00e676',
  successDark: '#00c853',

  /* error */
  errorLight: '#e48986',
  errorMain: '#d9534f',
  errorDark: '#d54c48',

  /* warning */
  warningLight: '#fdf5ea',
  warningMain: '#f0ad4e',
  warningDark: '#ec9c3d',

  /* grey */
  grey50: '#F8FAFC',
  grey100: '#EEF2F6',
  grey200: '#E3E8EF',
  grey300: '#CDD5DF',
  grey500: '#697586',
  grey600: '#4B5565',
  grey700: '#364152',
  grey900: '#121926',

  /* divider */
  divider: '#eeeeee',

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkBackground: '#1a223f',
  darkPaper: '#111936',

  // dark 800 & 900
  darkLevel1: '#29314f',
  darkLevel2: '#212946',

  // primary dark
  darkPrimaryLight: '#eef2f6',
  darkPrimaryMain: '#2196f3',
  darkPrimaryDark: '#1e88e5',
  darkPrimary200: '#90caf9',
  darkPrimary800: '#1565c0',

  // text variants
  darkTextTitle: '#d7dcec',
  darkTextPrimary: '#bdc8f0',
  darkTextSecondary: '#8492c4',
}
