import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Assistant } from '../../types/Assistant'

// ========================|| CONTEXT - ASSISTANT ||======================== //

interface AssistantContextType {
  assistant: Assistant
  setAssistant: Dispatch<SetStateAction<Assistant | undefined>>
}

const AssistantContext = createContext<AssistantContextType>({} as AssistantContextType)

export default AssistantContext