// material-ui
import { FormControl, List } from '@mui/material'

// third-party
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

// project imports
import BusinessHourFormControl from '../BusinessHourFormControl/BusinessHourFormControl'
import { BusinessHour } from '../../../../types/BusinessHour'

// ========================|| BUSINESS HOURS - FORM CONTROL ||======================== //

interface FormValues {
  businessHours: BusinessHour[]
}

export default function BusinessHoursFormControl() {
  const { control } = useFormContext<FormValues>()
  const { fields } = useFieldArray({ control: control, name: 'businessHours' })

  return (
    <Controller
      name='businessHours'
      control={control}
      render={() => (
        <FormControl variant='standard'>
          <List disablePadding>
            {fields.map((field, businessHourIndex) => (
              <BusinessHourFormControl key={field.id} businessHourIndex={businessHourIndex} />
            ))}
          </List>
        </FormControl>
      )}
    />
  )
}
