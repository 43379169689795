import { createContext } from 'react'

// third-party
import { User } from 'firebase/auth'

// project imports
import { FirebaseError } from '../../types/ApiError'

// ========================|| CONTEXT - AUTH ||======================== //

interface AuthContextType {
  auth?: User
  error?: FirebaseError
  loading: boolean
  signInWithGoogle: () => void
  signInWithFacebook: () => void
  signInWithLink: () => void
  sendSignInLink: (email: string, url: string) => Promise<void>
  logout: () => void
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export default AuthContext