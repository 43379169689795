// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

// third-party
import { useTranslation } from 'react-i18next'

export default function Help() {
  // hooks
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box pt='80px' id='help'>
      <Container>
        <Grid container spacing={4} display='flex'>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h2' textAlign='center' fontWeight={600}>
                  {t('¿Necesitas soporte?')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' textAlign='center' color='text.primary'>
                  {t('Contactanos si tenés dudas sobre la plataforma')}
                </Typography>
              </Grid>
              <Grid item xs={12} display='flex' justifyContent='center'>
                <Button variant='text' endIcon={<ArrowRightAltIcon />} href='/#contact'>
                  {t('Contacto')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} borderLeft={`1px solid ${theme.palette.divider}`}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h2' textAlign='center' fontWeight={600}>
                  {t('Personalizar plan')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' textAlign='center' color='text.primary'>
                  {t('¿Estás buscando algo diferente? Comentanos para revisar tu caso particular')}
                </Typography>
              </Grid>
              <Grid item xs={12} display='flex' justifyContent='center'>
                <Button variant='text' endIcon={<ArrowRightAltIcon />} href='/#contact'>
                  {t('Contacto')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
