import { ReactNode, useContext } from 'react'

// project imports
import ClientContext from './ClientContext'
import CompanyContext from '../company/CompanyContext'
import useClient from '../../hooks/client/useClient'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - CLIENT ||======================== //

interface Props {
  children: ReactNode
}

export default function ClientProvider({ children }: Props): JSX.Element {
  const { company } = useContext(CompanyContext)
  const { error, client } = useClient(company.id)

  if (!company) {
    return <NotFound />
  }

  if (error && error.status !== 404) {
    return <ApiErrorAlert error={error} />
  }

  return <ClientContext.Provider value={{ client }}>{children}</ClientContext.Provider>
}