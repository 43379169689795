import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { BusinessHourAdjusted } from '../../types/BusinessHour'

// ========================|| CONTEXT - BUSINESS HOURS ADJUSTED ||======================== //

interface BusinessHoursAdjustedContextType {
  businessHoursAdjusted: BusinessHourAdjusted[]
  setBusinessHoursAdjusted: Dispatch<SetStateAction<BusinessHourAdjusted[]>>
}

const BusinessHoursAdjustedContext = createContext<BusinessHoursAdjustedContextType>({} as BusinessHoursAdjustedContextType)

export default BusinessHoursAdjustedContext