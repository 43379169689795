import { createContext } from 'react'

// project imports
import { Subscription } from '../../types/Subscription'

// ========================|| CONTEXT - SUBSCRIPTION ||======================== //

interface SubscriptionContextType {
  subscription: Subscription
}

const SubscriptionContext = createContext<SubscriptionContextType>({} as SubscriptionContextType)

export default SubscriptionContext
