import { ReactNode } from 'react'

// material-ui
import { Table, TableProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// ========================|| UI COMPONENTS - TABLE - MAIN TABLE ||======================== //

interface Props extends TableProps {
  children: ReactNode
}

export default function MainTable({ children, ...props }: Props) {
  const theme = useTheme()

  return (
    <Table
      sx={{
        '.MuiTableCell-root': {
          '&:first-of-type': {
            padding: '16px 16px 16px 24px',
          },
          '&:last-child': {
            padding: '16px 24px 16px 16px',
          },
          borderColor: theme.palette.divider,
        },
        '.MuiTableHead-root': {
          backgroundColor: theme.palette.grey[50],
        },
      }}
      {...props}
    >
      {children}
    </Table>
  )
}
