import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import AssistantBusinessHoursAdjustedApi from '../../api/businesshouradjusted/AssistantBusinessHoursAdjustedApi'
import { ApiError } from '../../types/ApiError'
import { BusinessHourAdjusted } from '../../types/BusinessHour'

// apis
const businessHoursApi = new AssistantBusinessHoursAdjustedApi()

// ========================|| HOOK - BUSINESS HOURS - ASSISTANT ||======================== //

export default function useAssistantBusinessHoursAdjusted(assistantId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [businessHoursAdjusted, setBusinessHoursAdjusted] = useState<BusinessHourAdjusted[]>([])

  useEffect(() => {
    auth!.getIdToken().then(token => {
      businessHoursApi
        .findByAssistantId(token, assistantId)
        .then(data => setBusinessHoursAdjusted(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }, [assistantId])

  return { loading, error, businessHoursAdjusted, setBusinessHoursAdjusted }
}
