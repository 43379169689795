// material-ui
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import { Assistant } from '../../../../types/Assistant'

// ========================|| ASSISTANT - EDIT BUTTON ||======================== //

interface Props {
  assistant: Assistant
}

export default function AssistantEditIconButton({ assistant }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${assistant.id}`)
  }

  return (
    <Tooltip title={t('Edit Assistant')}>
      <IconButton aria-label='Edit assistant' color='secondary' size='large' onClick={handleClick}>
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
