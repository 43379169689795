import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanyBusinessHoursAdjustedApi from '../../api/businesshouradjusted/CompanyBusinessHoursAdjustedApi'
import { ApiError } from '../../types/ApiError'
import { BusinessHourAdjusted } from '../../types/BusinessHour'

// apis
const businessHoursApi = new CompanyBusinessHoursAdjustedApi()

// ========================|| HOOK - BUSINESS HOURS ADJUSTED - COMPANY ||======================== //

export default function useCompanyBusinessHoursAdjusted(companyId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [businessHoursAdjusted, setBusinessHoursAdjusted] = useState<BusinessHourAdjusted[]>([])

  useEffect(() => {
    auth!.getIdToken().then(token => {
      businessHoursApi
        .findByCompanyId(token, companyId)
        .then(data => setBusinessHoursAdjusted(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }, [companyId])

  return { loading, error, businessHoursAdjusted, setBusinessHoursAdjusted }
}
