import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanySettingsApi from '../../api/settings/CompanySettingsApi'
import { Settings } from '../../types/Settings'
import { ApiError } from '../../types/ApiError'

// apis
const settingsApi = new CompanySettingsApi()

// ========================|| HOOK - SETTINGS ||======================== //

const useSettings = (companyId: string) => {
  const { auth } = useAuth()
  const [settings, setSettings] = useState<Settings>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchSettings = () => {
    auth!.getIdToken().then(token => {
      settingsApi.findById(token, companyId)
        .then(data => setSettings(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchSettings, [companyId])

  return { loading, error, settings, setSettings }
}

export default useSettings
