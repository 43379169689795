// third-party
import { useTranslation } from 'react-i18next'

// project imports
import AssistantUsersContext from '../../../../context/userassistant/AssistantUsersContext'
import useAssistantId from '../../../../hooks/assistant/useAssistantId'
import useAssistantUsers from '../../../../hooks/userassistant/useAssistantUsers'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import Loader from '../../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../../ui-components/extended/alert/ApiErrorAlert'
import AssistantUserTable from '../AssistantUserTable/AssistantUserTable'
import AssistantUserCreateButton from '../AssistantUserCreateButton/AssistantUserCreateButton'

// ========================|| ASSISTANT - TABS - USERS ||======================== //

export default function AssistantUserTab() {
  const assistantId = useAssistantId()
  const { t } = useTranslation()
  const state = useAssistantUsers(assistantId)

  if (state.firstLoading) {
    return <Loader />
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <AssistantUsersContext.Provider value={state}>
      <SubCard title={t('Users')} content={false} secondary={<AssistantUserCreateButton />}>
        <AssistantUserTable />
      </SubCard>
    </AssistantUsersContext.Provider>
  )
}
