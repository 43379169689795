import { useContext } from 'react'

// material-ui
import { Grid } from '@mui/material'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import BookingStatusFilter from './BookingStatusFilter/BookingStatusFilter'
import BookingAssistantFilter from './BookingAssistantFilter/BookingAssistantFilter'
import { Layout } from '../../../../types/Layout'
import BookingStartDateFilter from './BookingStartDateFilter/BookingStartDateFilter'

// ==============================|| BOOKING - FILTER FORM ||============================== //

export default function BookingFilterForm() {
  const { layout } = useContext(BookingsContext)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BookingStatusFilter />
      </Grid>
      {layout === Layout.COMPANY && (
        <Grid item xs={12}>
          <BookingAssistantFilter />
        </Grid>
      )}
      <Grid item xs={12}>
        <BookingStartDateFilter />
      </Grid>
    </Grid>
  )
}
