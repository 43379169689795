// material-ui
import { Button } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import { ServiceDetails } from '../../../../types/Service'

// ========================|| SERVICE - EDIT BUTTON ||======================== //

interface Props {
  service: ServiceDetails
}

export default function ServiceEditButton({ service }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${service.id}`)
  }

  return (
    <Button variant='outlined' color='secondary' size='small' fullWidth startIcon={<EditIcon fontSize='small' />} onClick={handleClick}>
      {t('Edit')}
    </Button>
  )
}
