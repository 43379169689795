import { useState } from 'react'

// material-ui
import { IconButton, Tooltip } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ClientWhatsAppDialog from '../ClientWhatsAppDialog/ClientWhatsAppDialog'
import { Client } from '../../../../types/Client'

// ==============================|| CLIENT - WHATSAPP ICON BUTTON ||============================== //

interface Props {
  client: Client
}

export default function ClientWhatsAppIconButton({ client }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title={t('Chat on WhatsApp')}>
        <span>
          <IconButton size='large' disabled={!client.user} onClick={handleOpen} sx={{ color: '#25d366' }}>
            <WhatsAppIcon fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
      <ClientWhatsAppDialog open={open} client={{ ...client, user: client.user! }} onClose={handleClose} />
    </>
  )
}
