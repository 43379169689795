import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanyStatsApi from '../../api/companystats/CompanyStatsApi'
import { CompanyStats } from '../../types/CompanyStats'
import { ApiError } from '../../types/ApiError'

// apis
const statsApi = new CompanyStatsApi()

// ========================|| HOOK - COMPANY STATS ||======================== //

const useCompanyStats = (companyId: string) => {
  const { auth } = useAuth()
  const [stats, setStats] = useState<CompanyStats>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchCompanyStats = () => {
    auth!.getIdToken().then(token => {
      statsApi.findById(token, companyId)
        .then(data => setStats(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchCompanyStats, [auth])

  return { loading, error, stats, setStats }
}

export default useCompanyStats
