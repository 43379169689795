// project imports
import { axiosInstance } from '../../axios'
import { Assistant, AssistantParams } from '../../types/Assistant'
import { AssistantCreateDto } from './dto/AssistantCreateDto'

// ========================|| API - COMPANY ASSISTANT ||======================== //

export default class AssistantApi {
  findAll(token: string, companyId: string, params: AssistantParams): Promise<Assistant[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/assistants`, config)
  }

  create(token: string, companyId: string, assistant: AssistantCreateDto): Promise<Assistant> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/assistants`, assistant, config)
  }
}
