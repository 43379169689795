import { useContext } from 'react'

// material-ui
import { Alert, Link } from '@mui/material'

// third-party
import { Trans, useTranslation } from 'react-i18next'

// project imports
import UserCompanyContext from '../../../context/usercompany/UserCompanyContext'
import useCompanyId from '../../../hooks/company/useCompanyId'
import { UserCompanyRole } from '../../../types/UserCompanyRole'

// ========================|| INTEGRATION - ALERT ||======================== //

export default function IntegrationMercadoPagoNotEnabledAlert() {
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { userCompany } = useContext(UserCompanyContext)

  const handleClick = () => {
    window.open(`${process.env.REACT_APP_HOST}/companies/${companyId}/integrations`, '_self')
  }

  if (userCompany.role === UserCompanyRole.ADMIN) {
    return (
      <Alert severity='warning'>
        <Trans t={t}>
          Deposits will start working automatically once a Mercado Pago account is linked. Please contact the company owner to complete the linking in the integrations section.
        </Trans>
      </Alert>
    )
  }

  return (
    <Alert severity='warning'>
      <Trans t={t} i18nKey='integrationAlertMessage'>
        Deposits will start working automatically once you link a Mercado Pago account.
        <Link color='warning.dark' underline='hover' sx={{ cursor: 'pointer' }} onClick={handleClick}>
          Click here
        </Link>
        to configure the integration.
      </Trans>
    </Alert>
  )
}
