import React, { useContext } from 'react'

// material-ui
import { Grid, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserCompaniesContext from '../../../../context/usercompany/UserCompaniesContext'
import usePagination from '../../../../hooks/pagination/usePagination'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import CompanyStatusChip from '../../../../ui-components/extended/chip/CompanyStatusChip'
import UserCompanySeeButton from '../UserCompanySeeButton/UserCompanySeeButton'
import CompanyUserRoleChip from '../../../CompanyLayout/CompanyUsers/CompanyUserRoleChip/CompanyUserRoleChip'
import UserCompanyDisassociateButton from '../UserCompanyDisassociateButton/UserCompanyDisassociateButton'
import { UserCompanyRole } from '../../../../types/UserCompanyRole'
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'

// ========================|| USER COMPANY - TABLE ||======================== //

export default function UserCompanyTable() {
  // hooks
  const { t } = useTranslation()
  const { userCompanies } = useContext(UserCompaniesContext)
  const { rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(userCompanies)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Company')}</TableCell>
              <TableCell align='center'>{t('Status')}</TableCell>
              <TableCell align='center'>{t('Role')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(userCompany => (
              <TableRow key={userCompany.id}>
                <TableCell>
                  <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
                    <Grid item>
                      <CompanyAvatar active={userCompany.active} />
                    </Grid>
                    <Grid item>
                      <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
                        {userCompany.displayName}
                      </Typography>
                      <Typography variant='caption' align='left' whiteSpace='nowrap'>
                        @{userCompany.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align='center'>
                  <CompanyStatusChip active={userCompany.active} />
                </TableCell>
                <TableCell align='center'>
                  <CompanyUserRoleChip role={userCompany.role} />
                </TableCell>
                <TableCell align='center'>
                  <UserCompanySeeButton company={userCompany} />
                  {userCompany.role === UserCompanyRole.ADMIN && <UserCompanyDisassociateButton userCompany={userCompany} />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <TablePagination
        component='div'
        count={userCompanies.length}
        rowsPerPageOptions={[5, 10, 20]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        slotProps={{ select: { inputProps: { 'aria-label': 'rows per page' }, native: true } }}
        labelRowsPerPage={t('Rows per page:')}
      />
    </>
  )
}
