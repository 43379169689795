// project imports
import { axiosInstance } from '../../axios'
import { Booking } from '../../types/Booking'

// ========================|| API - BOOKING ||======================== //

export default class BookingApi {
  confirm(token: string, bookingId: string): Promise<Booking> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/bookings/${bookingId}/confirm`, {}, config)
  }

  cancel(token: string, bookingId: string): Promise<Booking> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/bookings/${bookingId}/cancel`, {}, config)
  }
}
