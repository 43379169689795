// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import AssistantBusinessHoursAdjustedForm from '../AssistantBusinessHoursAdjustedForm/AssistantBusinessHoursAdjustedForm'

// ========================|| ASSISTANT - BUSINESS HOURS ADJUSTED - CARD ||======================== //

export default function AssistantBusinessHoursAdjustedCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Business hours adjusted')} contentSX={{ overflowX: 'scroll' }}>
      <AssistantBusinessHoursAdjustedForm />
    </SubCard>
  )
}
