import { useContext } from 'react'

// material-ui
import { Autocomplete, Box, FormControl, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import CompanyContext from '../../../../../context/company/CompanyContext'
import useServices from '../../../../../hooks/service/useServices'
import { Service } from '../../../../../types/Service'

// ========================|| BOOKING FORM - SELECT SERVICE ||======================== //

export default function SelectServiceStep() {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()
  const { control, resetField } = useFormContext()
  const { company } = useContext(CompanyContext)

  // state
  const { loading, services } = useServices(company.id)

  return (
    <Controller
      name='service'
      control={control}
      rules={{ required: 'This field is required.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <Autocomplete
            options={services}
            value={value || null}
            loading={loading}
            loadingText={t('Loading...')}
            onChange={(e, service) => {
              resetField('assistant')
              resetField('date')
              resetField('time')
              return onChange(service)
            }}
            disableClearable
            isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
            getOptionLabel={service => service.name}
            renderOption={(props, service: Service) => (
              <Box component='li' {...props}>
                <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                    <Typography>{service.name}</Typography>
                    <Typography variant='caption'>
                      {service.duration} <Typography component='span'>{t('minutes')}</Typography>
                    </Typography>
                  </Box>
              </Box>
            )}
            renderInput={params =>
              matchesXS ? (
                <TextField {...params} placeholder={t('Service')} error={!!error} helperText={error ? t(error.message as string) : null} />
              ) : (
                <TextField {...params} label={t('Service')} error={!!error} helperText={error ? t(error.message as string) : null} />
              )
            }
          />
        </FormControl>
      )}
    />
  )
}
