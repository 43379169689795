// material-ui
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import { ServiceDetails } from '../../../../types/Service'

// ========================|| SERVICE - EDIT BUTTON ||======================== //

interface Props {
  service: ServiceDetails
}

export default function ServiceEditIconButton({ service }: Props) {
  // hooks
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${service.id}`)
  }

  return (
    <Tooltip title={t('Edit Service')}>
      <IconButton color='secondary' size='large' onClick={handleClick}>
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
