// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { DialogProps } from '@mui/material/Dialog'

// project imports
import useCompanyId from '../../../../hooks/company/useCompanyId'

// ========================|| BILLING - MODAL ||======================== //

export default function BillingInformationRequiredDialog(props: DialogProps) {
  const { t } = useTranslation()
  const companyId = useCompanyId()

  const handleClick = () => {
    window.open(`${process.env.REACT_APP_HOST}/companies/${companyId}/billing`, '_self')
  }

  return (
    <Dialog {...props} aria-labelledby='billing-required-dialog'>
      <DialogTitle id='billing-required-dialog'>{t('Update your billing information')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='billing-required-dialog-description'>
          {t('To proceed with your subscription, please update your billing information. This is required to process your invoices.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '0 20px 20px 20px' }}>
        <Button variant='contained' disableElevation fullWidth onClick={handleClick}>
          {t('Update billing information')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
