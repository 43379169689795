import { useContext } from 'react'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import SubscriptionStatusChip from '../SubscriptionStatusChip/SubscriptionStatusChip'
import SubscriptionUpdateForm from '../SubscriptionUpdateForm/SubscriptionUpdateForm'

// ========================|| SUBSCRIPTION - CARD ||======================== //

export default function SubscriptionUpdateCard() {
  const { t } = useTranslation()
  const { subscription } = useContext(SubscriptionContext)

  return (
    <SubCard
      title={t('Subscription')}
      secondary={<SubscriptionStatusChip status={subscription.status} />}
      headerSX={{ '& .MuiCardHeader-action': { mr: 0 } }}
    >
      <SubscriptionUpdateForm />
    </SubCard>
  )
}
