import { useContext, useState } from 'react'

// material-ui
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import BranchUserApi from '../../../../api/userbranch/BranchUserApi'
import BranchUsersContext from '../../../../context/userbranch/BranchUsersContext'
import useAuth from '../../../../context/auth/useAuth'
import useBranchId from '../../../../hooks/branch/useBranchId'
import SideDialog from '../../../../ui-components/extended/dialog/SideDialog'
import BranchUserCreateForm from '../BranchUserCreateForm/BranchUserCreateForm'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { BranchUser } from '../../../../types/BranchUser'
import { UserBranchRole } from '../../../../types/UserBranchRole'
import { ApiError } from '../../../../types/ApiError'
import { EXCEPTION_USER_BRANCH_ALREADY_EXIST, EXCEPTION_USER_NOT_FOUND } from '../../../../api/exceptions/exceptions'

// apis
const branchUserApi = new BranchUserApi()

// ========================|| BRANCH USER - CREATE DIALOG ||======================== //

interface FormValues {
  email: string
  role: UserBranchRole
}

interface Props extends DialogProps {
  onClose: () => void
}

export default function BranchUserCreateDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setBranchUsers } = useContext(BranchUsersContext)
  const branchId = useBranchId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      role:  UserBranchRole.ADMIN,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (branchUser: BranchUser) => {
    setBranchUsers(branchUsers => ({ ...branchUsers, content: branchUsers.content.concat(branchUser) }))

    dispatch(
      setSnackbar({
        message: t('User added successfully'),
        severity: 'success',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleError = (error: ApiError) => {
    console.log('error')
    if (error.message === EXCEPTION_USER_NOT_FOUND) {
      methods.setError('email', { message: EXCEPTION_USER_NOT_FOUND })
      return
    }

    if (error.message === EXCEPTION_USER_BRANCH_ALREADY_EXIST) {
      methods.setError('email', { message: 'The user is already associated with the branch.' })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while inviting user'),
        severity: 'error',
        open: true,
      }),
    )

    onClose()
    methods.reset()
  }

  const handleCancel = () => {
    onClose()
    methods.reset()
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      branchUserApi.create(token, branchId, {
        email: form.email,
        role: form.role,
      })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SideDialog {...dialogProps} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} display='contents'>
          <DialogTitle sx={{ p: '24px' }}>{t('Invite User')}</DialogTitle>
          <DialogContent>
            <BranchUserCreateForm />
          </DialogContent>
          <DialogActions sx={{ p: '24px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LoadingButton type='submit' variant='contained' size='large' loading={loading} fullWidth disableElevation>
                  {t('Invite')}
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant='outlined' size='large' fullWidth onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </FormProvider>
    </SideDialog>
  )
}
