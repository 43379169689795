import { ReactNode } from 'react'

// project imports
import BillingContext from './BillingContext'
import PaymentsContext from '../payment/PaymentsContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useBilling from '../../hooks/billing/useBilling'
import usePayments from '../../hooks/payment/usePayments'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - PAYMENTS ||======================== //

export default function BillingPaymentsProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { loading: loadingBilling, error: errorBilling, billing, setBilling } = useBilling(companyId)
  const { loading: loadingPayments, error: errorPayments, payments, pageable, setPageable } = usePayments(companyId)

  const loading = loadingBilling || loadingPayments

  if (loading) {
    return <Loader />
  }

  if (errorBilling && errorBilling.status !== 404) {
    return <ApiErrorAlert error={errorBilling} />
  }

  if (errorPayments) {
    return <ApiErrorAlert error={errorPayments} />
  }

  return (
    <BillingContext.Provider value={{ billing, setBilling }}>
      <PaymentsContext.Provider value={{ payments, pageable, setPageable }}>{children}</PaymentsContext.Provider>
    </BillingContext.Provider>
  )
}