import React, { useContext, useState } from 'react'

// material-ui
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import ServicesContext from '../../../../context/service/ServicesContext'
import CompanyStatsContext from '../../../../context/companystats/CompanyStatsContext'
import ServiceApi from '../../../../api/service/ServiceApi'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { ServiceDetails } from '../../../../types/Service'

// apis
const serviceApi = new ServiceApi()

// ========================|| SERVICE - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  service: ServiceDetails
  onClose: any
}

export default function ServiceDeleteDialog({ service, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { stats, setStats } = useContext(CompanyStatsContext)
  const { setServices } = useContext(ServicesContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeService = (serviceId: string) => {
    setServices(services => services.filter(service => service.id !== serviceId))
    setStats({ ...stats, services: stats.services - 1 })
  }

  const handleSuccess = () => {
    removeService(service.id)

    dispatch(
      setSnackbar({
        message: t('Service deleted successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while deleting service'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      serviceApi
        .delete(token, service.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          onClose()
          setLoading(false)
        })
    })
  }

  return (
    <Dialog aria-labelledby='delete-service-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-service-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to delete the service') + ' '}
          <strong>{service.name}</strong>?
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox id='bookings' checked color='error' size='small' disableRipple disableTouchRipple sx={{ cursor: 'default' }} />}
            label={t('All pending and confirmed bookings will be canceled')}
            sx={{ cursor: 'text' }}
          />
          <FormHelperText error>{t('This action cannot be undone.')}</FormHelperText>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
