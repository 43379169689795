import * as React from 'react'
import { useEffect, useState } from 'react'

// material-ui
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material'
import { AutocompleteValue } from '@mui/material/useAutocomplete/useAutocomplete'

// third-party
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { FieldError } from 'react-hook-form/dist/types'

// project imports
import useAuth from '../../../../context/auth/useAuth'
import UserApi from '../../../../api/user/UserApi'
import { User } from '../../../../types/User'

// ========================|| UI COMPONENTS - AUTOCOMPLETE - USER ||======================== //

const userApi = new UserApi()

interface Props {
  value: User | null
  error: FieldError | undefined
  onChange?: (
    event: React.SyntheticEvent,
    value: AutocompleteValue<User | null, false, false, false>,
  ) => void
}

export default function UserAutocomplete({ value, error, onChange }: Props) {
  const { t } = useTranslation()
  const { auth } = useAuth()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<readonly User[]>([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (inputValue && active) {
      fetchOptions(inputValue)
    } else {
      setOptions([])
    }
  }, [inputValue])

  useEffect(() => {
    if (value) {
      setOptions([value])
    }
  }, [value])

  const fetchOptions = debounce(async (query: string) => {
    if (query.length < 5) return

    setLoading(true)
    auth!.getIdToken().then(token => {
      userApi.findAll(token, query)
        .then(users => setOptions(users))
        .finally(() => setLoading(false))
    })

  }, 400)

  const handleInputChange = (event: any, newInputValue: string, reason: string) => {
    if (newInputValue === '' || reason === 'clear') {
      setOptions([])
    }

    if (reason === 'input') {
      setActive(true)
    } else {
      setActive(false)
    }

    setInputValue(newInputValue);
  }

  return (
    <Autocomplete
      fullWidth
      open={open}
      value={value}
      onChange={onChange}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      inputValue={inputValue}
      filterOptions={x => x}
      getOptionKey={option => option.id}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={t('No options')}
      onInputChange={handleInputChange}
      renderOption={(props, user: User) => (
        <li {...props}>
          <Box display='flex' flexDirection='column'>
            <Typography fontWeight='bold'>{user.name}</Typography>
            <Typography variant='caption'>{user.email}</Typography>
          </Box>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={t('User')}
          error={!!error}
          helperText={error ? t(error.message as string) : t("Search for the client by email and link their account so they can manage appointments and receive reminders. If they're not registered, you can link them later.")}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
