// material-ui
import { CardContent, Divider, useMediaQuery, useTheme } from '@mui/material'

// project imports
import useBreadcrumbs from '../../../../hooks/company/useBreadcrumbs'
import useTooltip from '../../../../hooks/company/useTooltip'
import MainCard from '../../../../ui-components/extended/card/MainCard'
import Breadcrumbs from '../../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import BookingTabs from '../BookingTabs/BookingTabs'
import BookingCardHeader from '../BookingCardHeader/BookingCardHeader'
import BookingTable from '../BookingTable/BookingTable'
import BookingList from '../BookingList/BookingList'

// ========================|| BOOKING - CARD ||======================== //

export default function BookingCard() {
  const theme = useTheme()
  const items = useBreadcrumbs()
  const tooltip = useTooltip()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} content={false} border={false}>
      <BookingTabs />
      <CardContent sx={{ p: 3 }}>
        <BookingCardHeader />
      </CardContent>
      <Divider />
      {matchesXS ? <BookingList /> : <BookingTable />}
    </MainCard>
  )
}
