import React, { Dispatch, SetStateAction } from 'react'

// material-ui
import TablePagination from '@mui/material/TablePagination'

// project imports
import { Pageable } from '../../../types/Page'
import { useTranslation } from 'react-i18next'

// ========================|| UI COMPONENTS - TABLE - SUB TABLE - PAGINATION ||======================== //

interface Props<T extends Pageable> {
  count: number
  pageable: T
  setPageable: Dispatch<SetStateAction<T>>
}

export default function SubTablePagination<T extends Pageable>({ count, pageable, setPageable }: Props<T>) {
  const { t } = useTranslation()

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPageable({ ...pageable, page })
  }

  const handleChangeSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const size = parseInt(event.target.value, 10)
    setPageable({ ...pageable, size, page: 0 })
  }

  return (
    <TablePagination
      component='div'
      count={count}
      rowsPerPageOptions={[5, 10, 20]}
      rowsPerPage={pageable.size}
      page={pageable.page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeSize}
      sx={{ '.MuiTablePagination-toolbar': { padding: '0 20px' } }}
      slotProps={{ select: { inputProps: { 'aria-label': 'rows per page' }, native: true } }}
      labelRowsPerPage={t('Rows per page:')}
    />
  )
}