// material-ui
import { Chip, ChipProps, useTheme } from '@mui/material'

// ========================|| UI COMPONENTS - CHIP - STATS ||======================== //

interface Props extends ChipProps {}

export default function StatsChip(props: Props) {
  const theme = useTheme()

  return <Chip {...props} size='small' sx={{ backgroundColor: theme.palette.primary.main, color: 'white', borderRadius: 2, width: '26px', height: '26px' }} />
}
