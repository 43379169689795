// material-ui
import { Chip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { Client } from '../../../../types/Client'

// ========================|| CLIENT - DEPOSIT CHIP ||======================== //

interface Props {
  client: Client
}

export default function ClientDepositChip({ client }: Props) {
  const { t } = useTranslation()

  if (client.depositEnabled) {
    return <Chip label={t('Enabled')} size='small' color='success' />
  } else {
    return <Chip label={t('Disabled')} size='small' color='error' />
  }
}