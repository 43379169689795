// ========================|| UTILS - TIMEZONE ||======================== //

export interface Timezone {
  id: string
  label: string
  country: string
}

export const findTimezoneLabel = (id: string) => timezone.find(timezone => timezone.id === id)?.label || ''

const timezone: Timezone[] = [
  {
    id: 'America/Argentina/Buenos_Aires',
    label: '(GMT-03:00) Buenos Aires, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Buenos_Aires',
    label: '(GMT-03:00) Buenos Aires, Argentina',
    country: 'AR',
  },
]
