import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserCompanyApi from '../../api/usercompany/UserCompanyApi'
import { UserCompany, UserCompanyParams } from '../../types/UserCompany'
import { ApiError } from '../../types/ApiError'

// apis
const userCompanyApi = new UserCompanyApi()

// ========================|| HOOK - USER COMPANIES ||======================== //

export default function useUserCompanies() {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [userCompanies, setUserCompanies] = useState<UserCompany[]>([])
  const [params, setParams] = useState<UserCompanyParams>({})

  const searchUserCompanies = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      userCompanyApi
        .findAll(token, params)
        .then(data => setUserCompanies(data))
        .catch(error => setError(error))
        .finally(() => {
          setLoading(false)
          setFirstLoading(false)
        })
    })
  }

  useEffect(searchUserCompanies, [params])

  return { firstLoading, loading, error, userCompanies, params, setUserCompanies, setParams }
}
