import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserBranchApi from '../../api/userbranch/UserBranchApi'
import { Page, DEFAULT_PAGE, DEFAULT_PARAMS } from '../../types/Page'
import { UserBranch, UserBranchParams } from '../../types/UserBranch'
import { ApiError } from '../../types/ApiError'

// apis
const userBranchApi = new UserBranchApi()

// ========================|| HOOK - USER BRANCHES ||======================== //

export default function useUserBranches() {
  const { auth } = useAuth()
  const [firstLoading, setFirstLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [userBranches, setUserBranches] = useState<Page<UserBranch>>(DEFAULT_PAGE)
  const [params, setParams] = useState<UserBranchParams>(DEFAULT_PARAMS)

  const searchUserBranches = () => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      userBranchApi
        .findAll(token, params)
        .then(data => setUserBranches(data))
        .catch(error => setError(error))
        .finally(() => {
          setLoading(false)
          setFirstLoading(false)
        })
    })
  }

  useEffect(searchUserBranches, [params])

  return { firstLoading, loading, error, userBranches, params, setUserBranches, setParams }
}