// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import SettingsForm from '../SettingsForm/SettingsForm'

// ========================|| SETTINGS - CARD ||======================== //

export default function SettingsCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Settings')}>
      <SettingsForm />
    </SubCard>
  )
}