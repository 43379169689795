import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Integration } from '../../types/Integration'

// ========================|| CONTEXT - INTEGRATION ||======================== //

interface IntegrationContextType {
  integration: Integration
  setIntegration: Dispatch<SetStateAction<Integration | undefined>>
}

const IntegrationContext = createContext<IntegrationContextType>({} as IntegrationContextType)

export default IntegrationContext
