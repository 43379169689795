import React, { useContext } from 'react'

// material-ui
import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// project imports
import BookingsContext from '../../../../context/booking/BookingsContext'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import MainTablePagination from '../../../../ui-components/extended/table/MainTablePagination'
import BookingStatusChip from '../../../../ui-components/extended/chip/BookingStatusChip'
import BookingCancelIconButton from '../BookingCancelIconButton/BookingCancelIconButton'
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'
import BookingDepositIconButton from '../BookingDepositIconButton/BookingDepositIconButton'
import { locale } from '../../../../utils/locale'
import { getDepositText, getNullablePriceString } from '../../../../utils/typography'

// ========================|| BOOKING TABLE ||======================== //

export default function BookingTable() {
  // hooks
  const { i18n, t } = useTranslation()
  const { bookings, params, setParams } = useContext(BookingsContext)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Company')}</TableCell>
              <TableCell>{t('Branch')}</TableCell>
              <TableCell>{t('Service')}</TableCell>
              <TableCell>{t('Assistant')}</TableCell>
              <TableCell>{t('Date')}</TableCell>
              <TableCell align='right'>{t('Price')}</TableCell>
              <TableCell align='right'>{t('Deposit')}</TableCell>
              <TableCell align='center'>{t('Status')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.content.map(booking => (
              <TableRow key={booking.id}>
                <TableCell>
                  <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
                    <Grid item>
                      <CompanyAvatar active={booking.company.active} />
                    </Grid>
                    <Grid item>
                      <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
                        {booking.company.displayName}
                      </Typography>
                      <Typography variant='caption' align='left' whiteSpace='nowrap'>
                        @{booking.company.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle1'>{booking.branch.name}</Typography>
                  <Typography variant='caption'>{booking.branch.address.primary}</Typography>
                </TableCell>
                <TableCell>{booking.service.name}</TableCell>
                <TableCell>{booking.assistant.name}</TableCell>
                <TableCell>{dayjs(booking.date).locale(locale(i18n.language)).format('DD MMM, HH:mm')}</TableCell>
                <TableCell align='right'>
                  {t(getNullablePriceString(booking.price, booking.currency, i18n.language))}
                </TableCell>
                <TableCell align='right'>
                  {t(getDepositText(booking.price, booking.currency, booking.depositAmount, i18n.language))}
                </TableCell>
                <TableCell align='center'>
                  <BookingStatusChip status={booking.status} size='small' />
                </TableCell>
                <TableCell align='center'>
                  <BookingDepositIconButton booking={booking} />
                  <BookingCancelIconButton booking={booking} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <MainTablePagination count={bookings.page.totalElements} pageable={params} setPageable={setParams} />
    </>
  )
}
