import React, { useContext } from 'react'

// material-ui
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import BranchUsersContext from '../../../../context/userbranch/BranchUsersContext'
import SubTable from '../../../../ui-components/extended/table/SubTable'
import SubTablePagination from '../../../../ui-components/extended/table/SubTablePagination'
import BranchUserDeleteButton from '../BranchUserDeleteButton/BranchUserDeleteButton'
import BranchUserRoleChip from '../BranchUserRoleChip/BranchUserRoleChip'

// ========================|| ASSISTANT - TABS - USERS TABLE ||======================== //

export default function BranchUserTable() {
  // hooks
  const { t } = useTranslation()
  const { branchUsers, params, setParams } = useContext(BranchUsersContext)

  return (
    <>
      <TableContainer>
        <SubTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('User')}</TableCell>
              <TableCell align='center'>{t('Role')}</TableCell>
              <TableCell align='center' width={140}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {branchUsers.content.map(branchUser => (
              <TableRow key={branchUser.id}>
                <TableCell>
                  <Typography variant='subtitle1'>{branchUser.name}</Typography>
                  <Typography variant='caption'>{branchUser.email}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <BranchUserRoleChip role={branchUser.role} />
                </TableCell>
                <TableCell align='center'>
                  <BranchUserDeleteButton branchUser={branchUser} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SubTable>
      </TableContainer>
      <SubTablePagination count={branchUsers.page.totalElements} pageable={params} setPageable={setParams} />
    </>
  )
}
