// ========================|| UTILS - MERCADO PAGO ||======================== //

/**
 * Redirects the user to Mercado Pago checkout page using the preapproval id.
 *
 * @param preapprovalId the preapproval id.
 */
export const redirectToMercadoPagoCheckout = (preapprovalId: string) => {
  const checkoutLink = `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_id=${preapprovalId}`
  window.open(checkoutLink, '_blank')
}