import { useMemo } from 'react'

// material-ui
import { FormHelperText, useFormControl } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  helperText: string | null
  duration: number
}

function DurationHelperText({ helperText, duration }: Props) {
  const { t } = useTranslation()
  const { focused } = useFormControl() || {}

  const durationHelperText = (duration: number) => {
    if (!duration) return ''

    const hours = Math.floor(duration / 60)
    const minutes = duration % 60

    if (duration < 0) return ''
    if (hours === 0 && minutes === 1) return `${minutes} ${t('minute')}`
    if (hours === 0) return `${minutes} ${t('minutes')}`

    if (hours === 1 && minutes === 0) return `1 ${t('hour')}`
    if (hours === 1 && minutes === 1) return `${hours} ${t('hour')} ${t('and')} ${minutes} ${t('minute')}`
    if (hours === 1 && minutes > 1) return `${hours} ${t('hour')} ${t('and')} ${minutes} ${t('minutes')}`

    if (minutes === 0) return `${hours} ${t('hours')}`
    if (minutes === 1) return `${hours} ${t('hours')} ${t('and')} ${minutes} ${t('minute')}`

    return `${hours} ${t('hours')} ${t('and')} ${minutes} ${t('minutes')}`
  }

  const text = useMemo(() => {
    const durationText = durationHelperText(duration)

    if (focused) {
      return durationText
    }

    return helperText || durationText
  }, [helperText, focused, duration])

  return <FormHelperText>{text}</FormHelperText>
}

export default DurationHelperText