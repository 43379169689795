import { ReactNode } from 'react'

// material-ui
import { List, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// third-party
import { useTranslation } from 'react-i18next'

// ==============================|| MENU - GROUP ||============================== //

interface Props {
  title: string
  caption?: string
  children: ReactNode
}

export default function MenuGroup({ title, caption, children }: Props) {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <List
      subheader={
        <Typography variant='caption' sx={{ ...theme.typography.menuCaption }} display='block' gutterBottom>
          {t(title)}
          {caption && (
            <Typography variant='caption' sx={{ ...theme.typography.subMenuCaption }} display='block' gutterBottom>
              {t(caption)}
            </Typography>
          )}
        </Typography>
      }
    >
      {children}
    </List>
  )
}