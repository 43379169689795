// project imports
import { axiosInstance } from '../../axios'
import { Subscription } from '../../types/Subscription'
import { SubscriptionCreateDto } from './dto/SubscriptionCreateDto'

// ========================|| API - COMPANY SUBSCRIPTION ||======================== //

export default class CompanySubscriptionApi {
  findByCompanyId(token: string, companyId: string): Promise<Subscription> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/subscriptions`, config)
  }

  create(token: string, companyId: string, subscription: SubscriptionCreateDto): Promise<Subscription> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/subscriptions`, subscription, config)
  }
}
