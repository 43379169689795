import { useState } from 'react'

// third-party
import { useTranslation } from 'react-i18next'

// material-ui
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/AddTwoTone'

// project imports
import ClientCreateDialog from '../ClientCreateDialog/ClientCreateDialog'

// ========================|| CLIENT - CREATE BUTTON ||======================== //

export default function ClientCreateButton() {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={t('Create client')}>
        <span>
          <Fab
            color='primary'
            aria-label='Create Client'
            sx={{
              width: '32px',
              height: '32px',
              minHeight: '32px',
              boxShadow: 'none',
            }}
            disabled={false}
            onClick={handleOpen}
          >
            <AddIcon fontSize='small' />
          </Fab>
        </span>
      </Tooltip>
      <ClientCreateDialog open={open} onClose={handleClose} />
    </>
  )
}
