import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import CompanySubscriptionApi from '../../api/subscription/CompanySubscriptionApi'
import { Subscription } from '../../types/Subscription'
import { ApiError } from '../../types/ApiError'

// apis
const subscriptionApi = new CompanySubscriptionApi()

// ========================|| HOOK - SUBSCRIPTION ||======================== //

const useSubscription = (companyId: string) => {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [subscription, setSubscription] = useState<Subscription>()

  const searchSubscription = () => {
    auth!.getIdToken().then(token => {
      subscriptionApi
        .findByCompanyId(token, companyId)
        .then(data => setSubscription(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchSubscription, [companyId])

  return { loading, error, subscription, setSubscription }
}

export default useSubscription