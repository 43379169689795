import { useEffect } from 'react'

// third-party
import { useDispatch } from 'react-redux'

// project imports
import ServiceProvider from '../../../context/service/ServiceProvider'
import useCompanyId from '../../../hooks/company/useCompanyId'
import ServiceCard from './ServiceCard/ServiceCard'
import { setIsOpen } from '../../../store/menu/reducer'

// ========================|| SERVICE ||======================== //

export default function Service() {
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsOpen(`/companies/${companyId}/services`))
  }, [])

  return (
    <ServiceProvider>
      <ServiceCard />
    </ServiceProvider>
  )
}
