import { ReactNode } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'

// project imports
import MainCard from './MainCard'

// ==============================|| AUTH CARD ||============================== //

interface Props {
  children?: ReactNode
}

const ResponsiveCard = ({ children }: Props) => {
  const theme = useTheme()

  return (
    <MainCard
      content={false}
      boxShadow={false}
      sx={{
        [theme.breakpoints.up('xs')]: { margin: '20px', maxWidth: '400px' },
        [theme.breakpoints.up('md')]: { margin: '24px', maxWidth: '400px' },
        [theme.breakpoints.up('lg')]: { margin: '24px', maxWidth: '475px' },
      }}
    >
      <Box
        sx={{
          [theme.breakpoints.up('xs')]: { padding: '16px' },
          [theme.breakpoints.up('sm')]: { padding: '24px' },
          [theme.breakpoints.up('xl')]: { padding: '40px' },
        }}
      >
        {children}
      </Box>
    </MainCard>
  )
}

export default ResponsiveCard
