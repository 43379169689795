// project imports
import { axiosInstance } from '../../axios'
import { ServiceAssistant, ServiceAssistantParams } from '../../types/ServiceAssistant'
import { ServiceAssistantUpdateDto } from './dto/ServiceAssistantUpdateDto'

// ========================|| API - SERVICE ASSISTANTS ||======================== //

export default class ServiceAssistantApi {
  findAll(token: string, serviceId: string, params: ServiceAssistantParams): Promise<ServiceAssistant[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/services/${serviceId}/assistants`, config)
  }

  update(token: string, serviceId: string, assistantId: string, serviceAssistant: ServiceAssistantUpdateDto): Promise<ServiceAssistant> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/services/${serviceId}/assistants/${assistantId}`, serviceAssistant, config)
  }

  delete(token: string, serviceId: string, assistantId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/services/${serviceId}/assistants/${assistantId}`, config)
  }
}
