// project imports
import { axiosInstance } from '../../axios'
import { BusinessHourAdjusted } from '../../types/BusinessHour'
import { BusinessHoursAdjustedUpdateDto } from './dto/BusinessHoursAdjustedUpdateDto'

// ========================|| API - BUSINESS HOURS ADJUSTED - BRANCH ||======================== //

export default class BranchBusinessHoursAdjustedApi {
  findByBranchId(token: string, branchId: string): Promise<BusinessHourAdjusted[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/branches/${branchId}/business-hours-adjusted`, config)
  }

  update(token: string, branchId: string, businessHoursAdjusted: BusinessHoursAdjustedUpdateDto): Promise<BusinessHourAdjusted[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/branches/${branchId}/business-hours-adjusted`, businessHoursAdjusted, config)
  }
}
