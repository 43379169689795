import { ReactNode, useRef } from 'react'

// ========================|| PROVIDER - GOOGLE PLACES ||======================== //

const GOOGLE_MAPS_API_KEY = `${process.env.REACT_APP_FIREBASE_API_KEY}`

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

export default function PlacesProvider({ children }: { children: ReactNode }): JSX.Element {
  const loaded = useRef(false)

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&loading=async`,
        document.querySelector('head'),
        'google-maps',
      )
    }

    loaded.current = true
  }

  return <>{children}</>
}
