import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserBranchApi from '../../api/userbranch/UserBranchApi'
import { UserBranch } from '../../types/UserBranch'
import { ApiError } from '../../types/ApiError'

// apis
const userBranchApi = new UserBranchApi()

// ========================|| HOOK - USER BRANCH ||======================== //

export default function useUserBranch(branchId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [userBranch, setUserBranch] = useState<UserBranch>()

  const searchUserBranch = () => {
    auth!.getIdToken().then(token => {
      userBranchApi
        .findById(token, branchId)
        .then(data => setUserBranch(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchUserBranch, [branchId])

  return { loading, error, userBranch, setUserBranch }
}
