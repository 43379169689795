// material-ui
import { CardContent, Divider, useMediaQuery, useTheme } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import MainCard from '../../../../ui-components/extended/card/MainCard'
import BookingTabs from '../../../CompanyLayout/Bookings/BookingTabs/BookingTabs'
import BookingCardHeader from '../../../CompanyLayout/Bookings/BookingCardHeader/BookingCardHeader'
import BookingTable from '../BookingTable/BookingTable'
import BookingList from '../BookingList/BookingList'

// ========================|| BOOKING - CARD ||======================== //

export default function BookingCard() {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <MainCard title={t('Bookings')} content={false} border={false}>
      <BookingTabs />
      <CardContent sx={{ p: 3 }}>
        <BookingCardHeader />
      </CardContent>
      <Divider />
      {matchesXS ? <BookingList /> : <BookingTable />}
    </MainCard>
  )
}
