// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { ClientWithUser } from '../../../../types/Client'

// ==============================|| CLIENT - WHATSAPP DIALOG ||============================== //

interface Props extends DialogProps {
  client: ClientWithUser
  onClose: any
}

export default function ClientWhatsAppDialog({ client, onClose, ...dialogProps}: Props) {
  const { t } = useTranslation()

  const handleClick = () => {
    window.open(`https://wa.me/${formatPhone(client.user.phone)}`)
    onClose()
  }

  const formatPhone = (phone: string) => {
    return phone.replace(/[^\d+]/g,'')
  }

  return (
    <Dialog aria-labelledby='whatsapp-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='whatsapp-dialog'>{t('You will be redirected')}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{ position: 'absolute', right: 12, top: 24 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText id='whatsapp-dialog-description'>{t('Start a WhatsApp conversation with {{name}}.', { name: client.name })}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 24px' }}>
        <Button
          variant='contained'
          disableElevation
          onClick={handleClick}
          sx={{
            width: '100%',
            backgroundColor: '#25d366',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#25d366',
              color: '#ffffff',
              opacity: 0.8,
            },
          }}
        >
          {t('Start conversation')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}