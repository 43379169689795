import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Service } from '../../types/Service'

// ========================|| CONTEXT - SERVICE ||======================== //

interface ServiceContextType {
  service: Service
  setService: Dispatch<SetStateAction<Service | undefined>>
}

const ServiceContext = createContext<ServiceContextType>({} as ServiceContextType)

export default ServiceContext