// material-ui
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

// third-party
import { Link, matchPath, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import { Tab } from './Tab'

// ========================|| UI COMPONENTS - TABS ||======================== //

interface Props {
  tabs: Tab[]
}

export default function Tabs({ tabs }: Props) {
  const { t } = useTranslation()
  const urls = tabs.filter(tab => tab.active).map(tab => tab.url)
  const routeMatch = useRouteMatch(urls)
  const currentTab = routeMatch?.pattern?.path

  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation()

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i]
      const possibleMatch = matchPath(pattern, pathname)
      if (possibleMatch !== null) {
        return possibleMatch
      }
    }

    return null
  }

  if (!currentTab || !urls.includes(currentTab)) {
    return null
  }

  return (
    <MuiTabs value={currentTab} variant='scrollable' sx={{ mb: 3 }}>
      {tabs
        .filter(tab => tab.active)
        .map(tab => (
          <MuiTab key={tab.url} icon={tab.icon} iconPosition='start' label={t(tab.title)} value={tab.url} to={tab.url} component={Link} />
        ))}
    </MuiTabs>
  )
}
