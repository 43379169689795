import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Chip, Divider, List, ListItemButton, ListItemIcon, ListItemText, Menu, Stack, Tooltip, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IconLogout, IconSettings } from '@tabler/icons-react'

// project imports
import UserContext from '../../context/user/UserContext'
import useAuth from '../../context/auth/useAuth'
import { setIsOpen } from '../../store/menu/reducer'
import { RootState } from '../../store/store'

// ========================|| PROFILE SECTION ||======================== //

export default function ProfileSection() {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  const { pathname } = useLocation()
  const { logout } = useAuth()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const isOpen = useSelector<RootState, string>(state => state.menu.isOpen)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (pathname === '/users/me') {
      dispatch(setIsOpen('/users/me'))
    }
  }, [])

  const handleListItemClick = (route: string) => {
    dispatch(setIsOpen(route))
    handleClose()
    navigate(route)
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    logout()
  }

  return (
    <>
      <Tooltip title={t('Account Menu')}>
        <Chip
          sx={{
            height: '48px',
            alignItems: 'center',
            borderRadius: '27px',
            transition: 'all .2s ease-in-out',
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.primary.main,
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.primary.light,
              '& svg': {
                color: theme.palette.primary.main,
                stroke: theme.palette.primary.light,
              },
            },
            '& .MuiChip-label': {
              lineHeight: 0,
            },
          }}
          label={<IconSettings stroke={1.5} size='1.5rem' color={theme.palette.primary.main} />}
          variant='outlined'
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleOpen}
          color='primary'
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          mt: 2,
          minWidth: 280,
          ul: { p: 0 },
          '.MuiMenu-paper': {
            boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px',
          },
        }}
      >
        <Box display='flex' flexDirection='column' p='16px 16px 0' sx={{ minWidth: '300px' }}>
          <Stack mb='16px' spacing={0.5}>
            <Stack direction='row' spacing={0.5} alignItems='center'>
              <Typography variant='h4'>{t('Good morning')},</Typography>
              <Typography component='span' variant='h4' fontWeight={400}>
                {user.name}
              </Typography>
            </Stack>
            <Typography component='span' variant='caption'>
              {user.email}
            </Typography>
          </Stack>
          <Divider />
        </Box>
        <Box display='flex' flexDirection='column' p='0 16px 16px'>
          <List sx={{ py: '8px' }} component='nav'>
            <ListItemButton
              sx={{ mt: 0.5, borderRadius: `${theme.shape.borderRadius}px` }}
              selected={isOpen === '/users/me'}
              onClick={() => handleListItemClick('/users/me')}
            >
              <ListItemIcon sx={{ minWidth: '36px', color: theme.palette.text.primary }}>
                <IconSettings width='20px' height='20px' strokeWidth='1.5' />
              </ListItemIcon>
              <ListItemText>
                <Typography variant='body2' color='inherit'>
                  {t('Account Settings')}
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ mt: 0.5, borderRadius: `${theme.shape.borderRadius}px` }} onClick={handleLogout}>
              <ListItemIcon sx={{ minWidth: '36px', color: theme.palette.text.primary }}>
                <IconLogout width='20px' height='20px' strokeWidth='1.5' />
              </ListItemIcon>
              <ListItemText>
                <Typography variant='body2' color='inherit'>
                  {t('Logout')}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Box>
      </Menu>
    </>
  )
}
