// material-ui
import { useMediaQuery, useTheme } from '@mui/material'

// project imports
import MainCard from '../../../../ui-components/extended/card/MainCard'
import CompanyTable from '../CompanyTable/CompanyTable'
import CompanyCardHeader from './CompanyCardHeader/CompanyCardHeader'
import CompanyList from '../CompanyList/CompanyList'

// ========================|| COMPANY - CARD ||======================== //

export default function CompanyCard() {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <MainCard title={<CompanyCardHeader />} content={false} border={false}>
      {matchesXS ? <CompanyList /> : <CompanyTable />}
    </MainCard>
  )
}
