// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import AssistantBusinessHoursForm from '../AssistantBusinessHoursForm/AssistantBusinessHoursForm'

// ========================|| ASSISTANT TABS - BUSINESS HOURS CARD ||======================== //

export default function AssistantBusinessHoursCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Business Hours')} contentSX={{ overflowX: 'scroll' }}>
      <AssistantBusinessHoursForm />
    </SubCard>
  )
}
