import React, { useContext, useEffect, useState } from 'react'

// material-ui
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel, MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup, Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import ServiceContext from '../../../../context/service/ServiceContext'
import IntegrationContext from '../../../../context/integration/IntegrationContext'
import ServiceApi from '../../../../api/service/ServiceApi'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useAuth from '../../../../context/auth/useAuth'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import useAssistants from '../../../../hooks/assistant/useAssistants'
import DurationHelperText from '../../Services/ServiceForm/DurationHelperText/DurationHelperText'
import IntegrationMercadoPagoNotEnabledAlert from '../../../../ui-components/extended/alert/IntegrationMercadoPagoNotEnabledAlert'
import { PriceType, Service, ServiveForm } from '../../../../types/Service'
import { ApiError } from '../../../../types/ApiError'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { EXCEPTION_SERVICE_NAME_ALREADY_EXIST } from '../../../../api/exceptions/exceptions'

// apis
const serviceApi = new ServiceApi()

// ========================|| TAB - SERVICE - FORM ||======================== //

export default function ServiceTab() {
  // hooks
  const companyId = useCompanyId()
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { service, setService } = useContext(ServiceContext)
  const { integration } = useContext(IntegrationContext)
  const { loading: loadingAssistants, assistants } = useAssistants(companyId)
  const dispatch = useDispatch()

  const formValues = (service: Service) => ({
    name: service.name,
    description: service.description,
    priceType: service.price !== null ? PriceType.FIXED : PriceType.TBD,
    price: service.price !== null ? service.price.toString() : '',
    currency: service.currency,
    customDepositPercentage: service.customDepositPercentage,
    depositPercentage: String(service.depositPercentage * 100),
    duration: service.duration.toString(),
    buffer: service.buffer.toString(),
    frequency: service.frequency.toString(),
    assistants: service.assistants,
  })

  // react-hook-form
  const { control, watch, formState, handleSubmit, reset, setValue, setError, clearErrors } = useForm<ServiveForm>({
    defaultValues: formValues(service),
  })

  // state
  const [loading, setLoading] = useState(false)
  const priceType: PriceType = watch('priceType')
  const customDepositPercentage: boolean = watch('customDepositPercentage')
  const depositPercentage: number = Number(watch('depositPercentage'))
  const duration = watch('duration')
  const buffer = watch('buffer')
  const [totalDuration, setTotalDuration] = useState(parseInt(duration) + parseInt(buffer))

  useEffect(() => setTotalDuration(parseInt(duration) + parseInt(buffer)), [duration, buffer])

  const handleSuccess = (service: Service) => {
    setService(service)

    reset(formValues(service))

    dispatch(
      setSnackbar({
        message: t('Service updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_SERVICE_NAME_ALREADY_EXIST) {
      setError('name', { message: EXCEPTION_SERVICE_NAME_ALREADY_EXIST })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating profile'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<ServiveForm> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      serviceApi
        .update(token, service.id, {
          name: form.name,
          description: form.description,
          price: form.priceType === PriceType.FIXED && form.price !== null ? parseFloat(form.price) : null,
          currency:  form.priceType === PriceType.FIXED && form.currency ? form.currency : null,
          customDepositPercentage: form.priceType === PriceType.FIXED ? form.customDepositPercentage : false,
          depositPercentage: form.priceType === PriceType.FIXED ? parseInt(form.depositPercentage) / 100 : 0,
          duration: parseInt(form.duration),
          buffer: parseInt(form.buffer),
          frequency: parseInt(form.frequency),
          assistantsIds: form.assistants.map(assistant => assistant.id),
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SubCard title={t('Details')}>
      <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'This field is required.',
                minLength: { value: 2, message: 'Name is too short.' },
                maxLength: { value: 100, message: 'Name is too long.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id='name'
                  label={t('Name')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message as string) : null}
                  fullWidth
                  InputProps={{ autoComplete: 'off' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{
                minLength: { value: 2, message: 'Description is too short.' },
                maxLength: { value: 255, message: 'Description is too long.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id='description'
                  label={t('Description')}
                  multiline
                  rows={4}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message as string) : null}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='priceType'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field: { onChange, value } }) => (
                <FormControl component='fieldset' variant='standard' fullWidth>
                  <FormLabel id='price-type-group-label' component='legend'>
                    <Typography variant='subtitle1'>{t('Price type')}</Typography>
                  </FormLabel>
                  <RadioGroup row aria-labelledby='price-type-group-label' name='price-type-group' value={value} onChange={event => {
                    if (service.price == null && event.target.value === PriceType.TBD) {
                      setValue('price', '', { shouldDirty: true })
                      setValue('currency', null, { shouldDirty: true })
                      setValue('customDepositPercentage', false, { shouldDirty: true })
                      setValue('depositPercentage', '0', { shouldDirty: true })
                      clearErrors(['price', 'currency'])
                    }

                    return onChange(event)
                  }}>
                    <FormControlLabel value={PriceType.FIXED} control={<Radio />} label={t('Fixed')} />
                    <FormControlLabel value={PriceType.TBD} control={<Radio />} label={t('To be defined')} />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          {PriceType.FIXED === priceType && (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name='price'
                  control={control}
                  rules={{
                    required: 'This field is required.',
                    min: { value: 0, message: 'Price must be greater than or equal to zero.' },
                    pattern: { value: /^\d*$/, message: 'Price must be an integer number.' },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      id='price'
                      label={t('Price')}
                      type='number'
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? t(error.message as string) : null}
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='currency'
                  control={control}
                  rules={{ required: 'This field is required.' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <InputLabel id='currency-select-label' htmlFor='currency-select'>{t('Currency')}</InputLabel>
                      <Select
                        labelId='currency-select-label'
                        label={t('Currency')}
                        value={value}
                        onChange={onChange}
                        inputProps={{ id: 'currency-select' }}
                      >
                        <MenuItem value='ARS'>{t('(ARS) Argentine Peso')}</MenuItem>
                      </Select>
                      {error && <FormHelperText error>{t(error.message as string)}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='customDepositPercentage'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl component='fieldset' variant='standard' fullWidth onChange={onChange}>
                      <FormControlLabel
                        value={value}
                        checked={value}
                        control={<Switch id='customDepositPercentage' />}
                        label={t('Custom deposit percentage')}
                      />
                      <FormHelperText>
                        {t('Activate it to use a custom deposit percentage or disable it to use the deposit percentage defined in the company settings.')}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {customDepositPercentage && (
                <>
                  {!integration.mercadoPago && depositPercentage > 0 && (
                    <Grid item xs={12}>
                      <IntegrationMercadoPagoNotEnabledAlert />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Controller
                      name='depositPercentage'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl component='fieldset' variant='standard' fullWidth>
                          <FormLabel component='legend'>
                            <Typography variant='subtitle1' gutterBottom>
                              {t('Deposit percentage')}
                            </Typography>
                          </FormLabel>
                          <Grid container spacing={1} display='flex' alignItems='center'>
                            <Grid item>
                              <Typography mr={2}>0%</Typography>
                            </Grid>
                            <Grid item xs={true} display='flex' alignItems='center'>
                              <Slider
                                aria-label='Deposit Percentage'
                                value={Number(value)}
                                onChange={(event, value) => onChange(value.toString())}
                                getAriaValueText={value => `${value * 100}%`}
                                valueLabelDisplay='auto'
                                shiftStep={5}
                                step={5}
                                min={0}
                                max={100}
                              />
                            </Grid>
                            <Grid item>
                              <Typography ml={2}>100%</Typography>
                            </Grid>
                          </Grid>
                          <FormHelperText>
                            {t('Determines the percentage of the service price that the client must pay when making a booking.')}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Controller
              name='duration'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 15, message: 'Duration must be greater than or equal to 15 minutes.' },
                max: { value: 720, message: 'Duration must be less than or equal to 12 hours.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel htmlFor='duration'>{t('Duration')}</InputLabel>
                  <OutlinedInput
                    id='duration'
                    label={t('Duration')}
                    type='number'
                    value={value}
                    onChange={event => {
                      const duration = event.target.value
                      const totalDuration = parseInt(duration) + parseInt(watch('buffer'))
                      setValue('frequency', totalDuration.toString(), { shouldDirty: true })
                      return onChange(duration)
                    }}
                    inputProps={{ type: 'number' }}
                  />
                  <DurationHelperText helperText={error ? t(error.message as string) : t('duration_helperText')} duration={parseInt(value)} />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='buffer'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 0, message: 'Buffer time must be positive.' },
                max: { value: 240, message: 'Buffer time must be less than or equal to 4 hours.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel htmlFor='buffer'>{t('Buffer time')}</InputLabel>
                  <OutlinedInput
                    id='buffer'
                    label={t('Buffer time')}
                    type='number'
                    value={value}
                    onChange={event => {
                      const buffer = event.target.value
                      const totalDuration = parseInt(duration) + parseInt(buffer)
                      setValue('frequency', totalDuration.toString(), { shouldDirty: true })
                      return onChange(buffer)
                    }}
                    inputProps={{ type: 'number' }}
                  />
                  <DurationHelperText helperText={error ? t(error.message as string) : t('buffer_helperText')} duration={parseInt(value)} />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='frequency'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 15, message: 'The frequency must be greater than or equal to 15 minutes.' },
                max: { value: totalDuration, message: 'Frequency must be less or equal than duration plus buffer.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel htmlFor='frequency'>{t('Frequency')}</InputLabel>
                  <OutlinedInput
                    id='frequency'
                    label={t('Frequency')}
                    type='number'
                    value={value}
                    onChange={onChange}
                    inputProps={{ type: 'number' }}
                  />
                  <DurationHelperText helperText={error ? t(error.message as string) : t('frequency_helperText')} duration={parseInt(value)} />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='assistants'
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    id='assistants'
                    options={assistants}
                    value={value || null}
                    loading={loadingAssistants}
                    multiple
                    limitTags={5}
                    disableCloseOnSelect
                    onChange={(e, assistant) => onChange(assistant)}
                    isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                    getOptionLabel={assistant => assistant.name}
                    renderOption={(props, assistant, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          id={assistant.name}
                          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                          checkedIcon={<CheckBoxIcon fontSize='small' />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <Box display='flex' flexDirection='column'>
                          <Typography variant='subtitle1'>{assistant.name}</Typography>
                          <Typography variant='caption'>{assistant.branch.name}</Typography>
                        </Box>
                      </li>
                    )}
                    renderInput={params => <TextField {...params} label={t('Assistants')} />}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton type='submit' variant='contained' disabled={!formState.isDirty} loading={loading}>
              {t('Save changes')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </SubCard>
  )
}
