// project imports
import { axiosInstance } from '../../axios'
import { Assistant } from '../../types/Assistant'
import { AssistantUpdateDto } from './dto/AssistantUpdateDto'

// ========================|| API - ASSISTANT ||======================== //

export default class AssistantApi {
  findById(token: string, assistantId: string): Promise<Assistant> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/assistants/${assistantId}`, config)
  }

  update(token: string, assistantId: string, assistant: AssistantUpdateDto): Promise<Assistant> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/assistants/${assistantId}`, assistant, config)
  }

  delete(token: string, assistantId: string) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/assistants/${assistantId}`, config)
  }
}
