// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// ========================|| HOOK - ASSISTANT TOOLTIP ||======================== //

const useTooltip = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  switch (pathname.split('/').at(-1)) {
    case 'business-hours': {
      return t('tooltip.branch.businesshours')
    }
    case 'users': {
      return t('tooltip.branch.users')
    }
    default: {
      return t('tooltip.branch')
    }
  }
}

export default useTooltip
