import { useContext } from 'react'
import UserCompanyContext from '../../context/usercompany/UserCompanyContext'
import { Navigate, Outlet } from 'react-router-dom'
import { UserCompanyRole } from '../../types/UserCompanyRole'

export default function OwnerGuard() {
  const { userCompany } = useContext(UserCompanyContext)

  if (userCompany.role !== UserCompanyRole.OWNER) {
    return <Navigate to={`/companies/${userCompany.id}/bookings`} replace />
  }

  return <Outlet />
}