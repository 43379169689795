// project imports
import { axiosInstance } from '../../axios'
import { Branch } from '../../types/Branch'
import { BranchUpdateDto } from './dto/BranchUpdateDto'

// ========================|| API - BRANCH ||======================== //

export default class BranchApi {
  findById(token: string, branchId: string): Promise<Branch> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/branches/${branchId}`, config)
  }

  update(token: string, branchId: string, branch: BranchUpdateDto): Promise<Branch> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/branches/${branchId}`, branch, config)
  }

  delete(token: string, branchId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/branches/${branchId}`, config)
  }
}
