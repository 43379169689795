// ========================|| UTILS - KEY GENERATOR ||======================== //

/**
 * Generates a random key.
 */
export const key = () => Math.random().toString(36).slice(2)

/**
 * Generates a default key.
 */
export const defaultKey = () => 'default'