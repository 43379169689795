import React from 'react'

// third-party
import { useDispatch, useSelector } from 'react-redux'

// material-ui
import { Alert, SnackbarProps } from '@mui/material'
import MuiSnackbar from '@mui/material/Snackbar'

// project imports
import { RootState } from '../../../store/store'
import { setSnackbar, SnackbarState } from '../../../store/snackbar/reducer'

// ==============================|| SNACKBAR ||============================== //

const Snackbar = (props: SnackbarProps) => {
  const snackbar = useSelector<RootState, SnackbarState>(state => state.snackbar)
  const dispatch = useDispatch()

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(setSnackbar({ ...snackbar, open: false }))
  }

  return (
    <MuiSnackbar
      {...props}
      open={snackbar.open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert severity={snackbar.severity} sx={{ width: '100%' }} onClose={handleClose}>
        {snackbar.message}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
