// third-party
import { Outlet } from 'react-router-dom'

// project imports
import MainCard from '../../../../ui-components/extended/card/MainCard'
import Breadcrumbs from '../../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import ServiceTabs from '../ServiceTabs/ServiceTabs'
import useBreadcrumbs from '../../../../hooks/service/useBreadcrumbs'
import useTooltip from '../../../../hooks/service/useTooltip'

// ========================|| SERVICE - CARD ||======================== //

export default function ServiceCard() {
  const items = useBreadcrumbs()
  const tooltip = useTooltip()

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} content border={false}>
      <ServiceTabs />
      <Outlet />
    </MainCard>
  )
}