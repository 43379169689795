// third-party
import { Outlet } from 'react-router-dom'

// project imports
import useBreadcrumbs from '../../../../hooks/branch/useBreadcrumbs'
import useTooltip from '../../../../hooks/branch/useTooltip'
import Breadcrumbs from '../../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import MainCard from '../../../../ui-components/extended/card/MainCard'
import BranchTabs from '../BranchTabs/BranchTabs'

// ========================|| BRANCH - CARD ||======================== //

export default function BranchCard() {
  const items = useBreadcrumbs()
  const tooltip = useTooltip()

  return (
    <MainCard title={<Breadcrumbs items={items} tooltip={tooltip} />} content border={false}>
      <BranchTabs />
      <Outlet />
    </MainCard>
  )
}