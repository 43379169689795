import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { CompanyStats } from '../../types/CompanyStats'

// ========================|| CONTEXT - COMPANY STATS ||======================== //

interface CompanyStatsContextType {
  stats: CompanyStats
  setStats: Dispatch<SetStateAction<CompanyStats | undefined>>
}

const CompanyStatsContext = createContext<CompanyStatsContextType>({} as CompanyStatsContextType)

export default CompanyStatsContext
