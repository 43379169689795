import { useState } from 'react'

// material-ui
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/EditTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ServiceAssistantEditDialog from '../ServiceAssistantEditDialog/ServiceAssistantEditDialog'
import { ServiceAssistant } from '../../../../types/ServiceAssistant'

// ========================|| ASSISTANT SERVICE - EDIT BUTTON ||======================== //

interface Props {
  serviceAssistant: ServiceAssistant
}

export default function ServiceAssistantEditButton({ serviceAssistant }: Props) {
  // hooks
  const { t } = useTranslation()

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={t('Customize')}>
        <IconButton color='secondary' size='large' onClick={handleOpen}>
          <EditIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <ServiceAssistantEditDialog open={open} serviceAssistant={serviceAssistant} onClose={handleClose} />
    </>
  )
}
