// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import BusinessHoursForm from '../BusinessHoursForm/BusinessHoursForm'

// ========================|| BUSINESS HOURS - CARD ||======================== //

export default function BusinessHoursCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Business Hours')} contentSX={{ overflowX: 'scroll' }}>
      <BusinessHoursForm />
    </SubCard>
  )
}
