import { useContext } from 'react'

// material-ui
import { Box, Typography } from '@mui/material'

// third-party
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'
import { Subscription, SubscriptionStatus } from '../../../../types/Subscription'
import { ReactComponent as MercadoPagoIcon } from '../../../../assets/images/mercadopago.svg'
import { locale } from '../../../../utils/locale'
import { FrequencyType } from '../../../../types/Plan'

// ========================|| PAYMENT - FORM ||======================== //

export default function PaymentMethodForm() {
  // hooks
  const { i18n, t } = useTranslation()
  const { subscription } = useContext(SubscriptionContext)

  const isActive = subscription.status === SubscriptionStatus.ACTIVE
  const isPending = subscription.status === SubscriptionStatus.PENDING
  const isPaused = subscription.status === SubscriptionStatus.PAUSED
  const hasNextPaymentDate = !!subscription.nextPaymentDate

  const nextPaymentDate = (subscription: Subscription) => {
    return dayjs(subscription.nextPaymentDate).locale(locale(i18n.language)).format('LL')
  }

  function subscriptionLabel() {
    const frequency = subscription.plan.frequency
    const frequencyType = subscription.plan.frequencyType
    let label = '';

    if (frequencyType === FrequencyType.MONTHS) {
      if (frequency === 1) {
        label = t('Monthly subscription')
      } else {
        label = t('Payments every {{frequency}} months', { frequency: frequency })
      }
    } else if (frequencyType === FrequencyType.DAYS) {
      if (frequency === 1) {
        label = t('Daily subscription')
      } else if (frequency === 7) {
        label = t('Weekly subscription')
      } else if (frequency === 14) {
        label = t('Bi-weekly subscription')
      } else if (frequency === 30) {
        label = t('Monthly subscription')
      } else {
        label = t('Payments every {{frequency}} days', { frequency: frequency })
      }
    }

    return label
  }

  return (
    <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
      <MercadoPagoIcon style={{ display: 'flex', minWidth: '50px' }} />
      <Box ml='8px'>
        <Typography variant='subtitle1'>{subscriptionLabel()}</Typography>
        {isActive && hasNextPaymentDate && (
          <Typography variant='subtitle2'>
            {t('Next payment on')} {nextPaymentDate(subscription)}.
          </Typography>
        )}
        {isActive && !hasNextPaymentDate && <Typography variant='subtitle2'>{t('No invoices scheduled yet.')}</Typography>}
        {isPending && <Typography variant='subtitle2'>{t('Waiting for payment method information.')}</Typography>}
        {isPaused && <Typography variant='subtitle2'>{t('Subscription was paused due to payment issues.')}</Typography>}
      </Box>
    </Box>
  )
}
