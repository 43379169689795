import { ReactNode, useContext, useEffect } from 'react'

// project imports
import IntegrationContext from '../../../context/integration/IntegrationContext'
import useCompanyId from '../../../hooks/company/useCompanyId'
import useIntegration from '../../../hooks/integration/useIntegration'
import NotFound from '../../Maintenance/NotFound/NotFound'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| INTEGRATION - PROVIDER ||======================== //

export default function IntegrationProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { setIntegration } = useContext(IntegrationContext)
  const { loading, error, integration } = useIntegration(companyId)

  useEffect(() => {
    if (integration) {
      setIntegration(integration)
    }
  }, [integration])

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!integration) {
    return <ApiErrorAlert />
  }

  return <>{children}</>
}