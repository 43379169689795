import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import UserApi from '../../api/user/UserApi'
import { User } from '../../types/User'
import { ApiError } from '../../types/ApiError'

// apis
const userApi = new UserApi()

// ========================|| HOOK - USER ||======================== //

const useUser = () => {
  const { auth } = useAuth()
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchUser = () => {
    auth!.getIdToken().then(token => {
      userApi.findMe(token)
        .then(data => setUser(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchUser, [auth])

  return { loading, error, user, setUser }
}

export default useUser
