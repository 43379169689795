// material-ui
import dayjs from 'dayjs'

// project imports
import { Plan } from '../types/Plan'
import { Subscription, SubscriptionStatus } from '../types/Subscription'

// ========================|| UTILS - SUBSCRIPTION ||======================== //

export const getSubTotal = (plan: Plan, assistants: number): number => {
  return Math.round(plan.price + plan.additional * (assistants - 1))
}

export const getTotal = (plan: Plan, assistants: number): number => {
  return Math.round(getSubTotal(plan, assistants) + getTaxes(plan, assistants))
}

export const getTaxes = (plan: Plan, assistants: number): number => {
  return Math.round(getSubTotal(plan, assistants) * plan.taxes)
}

export const isActive = (subscription: Subscription | undefined): boolean => {
  // There is no active subscription
  if (!subscription) return false;

  // Subscription is ACTIVE
  if (subscription.status === SubscriptionStatus.PAUSED) return false;

  // Subscription is ACTIVE
  if (subscription.status === SubscriptionStatus.ACTIVE) return true;

  // Subscription is FREE_TRIAL or PENDING but has no free trial
  if (!subscription.freeTrialEndDate) return false;

  // Subscription is FREE_TRIAL or PENDING and there are free trial days remaining
  return dayjs(subscription.freeTrialEndDate).isAfter(dayjs())
}

export const isPendingAndHasFreeTrialReminingDays = (subscription: Subscription): boolean => {
  return subscription.status === SubscriptionStatus.PENDING && dayjs(subscription.freeTrialEndDate).isAfter(dayjs());
}

export const isInactive = (subscription: Subscription | undefined): boolean => {
  return !isActive(subscription)
}

export const freeTrialRemainingDays = (subscription: Subscription): number => {
  if (!subscription.freeTrialEndDate) return 0

  if (dayjs(subscription.freeTrialEndDate).isBefore(new Date())) {
    return 0
  }

  return dayjs(subscription.freeTrialEndDate).diff(new Date(), 'days')
}