// project imports
import { axiosInstance } from '../../axios'
import { BusinessHour } from '../../types/BusinessHour'
import { BusinessHoursUpdateDto } from './dto/BusinessHoursUpdateDto'

// ========================|| API - BUSINESS HOURS - COMPANY ||======================== //

export default class CompanyBusinessHoursApi {
  findByCompanyId(token: string, companyId: string): Promise<BusinessHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/business-hours`, config)
  }

  update(token: string, companyId: string, businessHours: BusinessHoursUpdateDto): Promise<BusinessHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/companies/${companyId}/business-hours`, businessHours, config)
  }
}
