// project imports
import ServiceCard from './ServiceCard/ServiceCard'
import ServicesProvider from '../../../context/service/ServicesProvider'

// ========================|| SERVICES ||======================== //

export default function Services() {
  return (
    <ServicesProvider>
      <ServiceCard />
    </ServicesProvider>
  )
}
