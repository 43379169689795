// material-ui
import { ButtonProps, IconButton, Tooltip } from '@mui/material'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// ========================|| BUSINESS HOURS - ADD BUTTON ||======================== //

export default function BusinessHourAddButton(props: ButtonProps) {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('Add another period to this day')} placement='top' enterDelay={400} enterNextDelay={400}>
      <span>
        <IconButton {...props} aria-label='add' color='primary' size='medium'>
          <AddCircleTwoToneIcon fontSize='small' />
        </IconButton>
      </span>
    </Tooltip>
  )
}