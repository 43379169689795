import React, { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import AssistantUserApi from '../../../../api/userassistant/AssistantUserApi'
import AssistantUsersContext from '../../../../context/userassistant/AssistantUsersContext'
import useAuth from '../../../../context/auth/useAuth'
import useAssistantId from '../../../../hooks/assistant/useAssistantId'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { AssistantUser } from '../../../../types/AssistantUser'

// apis
const assistantUserApi = new AssistantUserApi()

// ========================|| ASSISTANT USER - DELETE DIALOG ||======================== //

interface Props extends DialogProps {
  assistantUser: AssistantUser
  onClose: any
}

export default function AssistantUserDeleteDialog({ assistantUser, onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setAssistantUsers } = useContext(AssistantUsersContext)
  const assistantId = useAssistantId()
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const removeUser = (removed: AssistantUser) => {
    setAssistantUsers(assistantUsers => ({
      ...assistantUsers,
      content: assistantUsers.content.filter(assistantUser => assistantUser !== removed)
    }))
  }

  const handleSuccess = () => {
    removeUser(assistantUser)

    dispatch(
      setSnackbar({
        message: t('User deleted successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while deleting user'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    onClose()
    setLoading(false)
  }

  const handleDelete = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      assistantUserApi.delete(token, assistantId, assistantUser.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Dialog aria-labelledby='delete-user-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-user-dialog'>{t('Are you sure?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' mb='8px'>
          {t('Do you want to delete the user') + ' '}<strong>{assistantUser.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={onClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleDelete}>
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
