import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { UserAssistant } from '../../types/UserAssistant'

// ========================|| CONTEXT - USER COMPANY ||======================== //

interface UserAssistantContextType {
  userAssistant: UserAssistant
  setUserAssistant: Dispatch<SetStateAction<UserAssistant | undefined>>
}

const UserAssistantContext = createContext<UserAssistantContextType>({} as UserAssistantContextType)

export default UserAssistantContext
