// project imports
import { axiosInstance } from '../../axios'
import { Page } from '../../types/Page'
import { UserBranch, UserBranchParams } from '../../types/UserBranch'

// ========================|| API - USER BRANCH ||======================== //

export default class UserBranchApi {
  findById(token: string, branchId: string): Promise<UserBranch> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/users/me/branches/${branchId}`, config)
  }

  findAll(token: string, params: UserBranchParams): Promise<Page<UserBranch>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get('/api/v1/users/me/branches', config)
  }

  delete(token: string, branchId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/users/me/branches/${branchId}`, config)
  }
}
