// material-ui
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'

// project imports
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useAssistantId from '../../../../hooks/assistant/useAssistantId'
import Tabs from '../../../../ui-components/extended/tabs/Tabs'
import { Tab } from '../../../../ui-components/extended/tabs/Tab'

// ========================|| ASSISTANT - TABS ||======================== //

export default function AssistantTabs() {
  const companyId = useCompanyId()
  const assistantId = useAssistantId()
  const URL_ASSISTANT = `/companies/${companyId}/assistants/${assistantId}`
  const URL_ASSISTANT_BUSINESS_HOURS = `/companies/${companyId}/assistants/${assistantId}/business-hours`
  const URL_ASSISTANT_USERS = `/companies/${companyId}/assistants/${assistantId}/users`

  const tabs: Tab[] = [
    {
      url: URL_ASSISTANT,
      title: 'Details',
      icon: <AccountCircleTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_ASSISTANT_BUSINESS_HOURS,
      title: 'Business Hours',
      icon: <AccessTimeTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_ASSISTANT_USERS,
      title: 'Users',
      icon: <PersonOutlineTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
  ]

  return <Tabs tabs={tabs} />
}
