import { createContext, Dispatch, SetStateAction } from 'react'
import { Page } from '../../types/Page'
import { ApiError } from '../../types/ApiError'
import { UserAssistant, UserAssistantParams } from '../../types/UserAssistant'

// ========================|| CONTEXT - USER ASSISTANTS ||======================== //

interface UserAssistantsContextType {
  loading: boolean
  userAssistants: Page<UserAssistant>
  error?: ApiError
  params: UserAssistantParams
  setUserAssistants: Dispatch<SetStateAction<Page<UserAssistant>>>
  setParams: Dispatch<SetStateAction<UserAssistantParams>>
}

const UserAssistantsContext = createContext<UserAssistantsContextType>({} as UserAssistantsContextType)

export default UserAssistantsContext
