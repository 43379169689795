export enum IdentifierType {
  DNI = 'DNI',
  CUIT = 'CUIT',
}

export interface BillingInformation {
  name: string
  identifierType: IdentifierType
  identifier: string
  address: string
}