// project imports
import { axiosInstance } from '../../axios'
import { UserCompany, UserCompanyParams } from '../../types/UserCompany'
import { CompanyUserCreateDto } from './dto/CompanyUserCreateDto'

// ========================|| API - USER COMPANY ||======================== //

export default class UserCompanyApi {
  findById(token: string, companyId: string): Promise<UserCompany> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/users/me/companies/${companyId}`, config)
  }

  findAll(token: string, params: UserCompanyParams): Promise<UserCompany[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get('/api/v1/users/me/companies', config)
  }

  create(token: string, company: CompanyUserCreateDto): Promise<UserCompany> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post('/api/v1/users/me/companies', company, config)
  }

  delete(token: string, companyId: string): Promise<void> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/users/me/companies/${companyId}`, config)
  }
}
