// material-ui
import { styled } from '@mui/material/styles'
import { Card } from '@mui/material'

// ==============================|| CARD - STYLES ||============================== //

export const YellowCard = styled(Card)(({ theme }) => ({
  background: theme.palette.warning.light,
  marginTop: '16px',
  marginBottom: '16px',
  overflow: 'hidden',
  position: 'relative',
  border: 'none',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '200px',
    height: '200px',
    border: '19px solid ',
    borderColor: theme.palette.warning.main,
    borderRadius: '50%',
    top: '45px',
    right: '-145px',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '200px',
    height: '200px',
    border: '3px solid ',
    borderColor: theme.palette.warning.main,
    borderRadius: '50%',
    top: '113px',
    right: '-65px',
  },
}))

export const BlueCard = styled(Card)(({ theme }) => ({
  background: theme.palette.primary.light,
  overflow: 'hidden',
  position: 'relative',
  border: 'none',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '157px',
    height: '157px',
    background: theme.palette.primary[200],
    borderRadius: '50%',
    top: '-105px',
    right: '-96px',
  },
}))

export const GreyCard = styled(Card)(({ theme }) => ({
  background: theme.palette.grey[50],
  border:` 1px solid ${theme.palette.grey[200]}`,
  boxShadow: 'none',
  padding: theme.spacing(2),
}))